@import "./daterangepicker";

reach-portal .createNewReportCriteria .divideView .divideView .formRow.divideView_items.state {
    margin-right: 8px !important;
}

.createNewReportCriteria {
	.formSection {
		& ~ .formSection {
			border: 0;
		}

		border-bottom: 3px solid $az_gray_1;
		padding-bottom: 0px;
		padding-top: 0px;
	}
	.borderLayout {
		.group {
			width: 100%;
			.groupItem_accWidth {
				padding-left: 15px;
				position: relative;
				&:before {
					content: "";
					position: absolute;
					right: 0;
					top: -5px;
					bottom: -5px;
					border-right: solid 1px $az_gray_3;
				}
				& + .groupItem {
					margin-left: 0;
					padding: 0 15px;
				}
			}
		}
		.filter {
			display: flex;
			align-items: center;
			input[type="text"] {
				border: none;
				padding: 0;
				margin-left: 15px;
			}
		}
		.checkList {
			display: flex;
			flex-wrap: wrap;
			height: 100px;
			overflow: hidden;
			margin: 0 -10px;
			&:hover {
				overflow-y: auto;
			}
			.checkItem {
				padding: 5px 10px;
				width: 33.333%;
				.input_label {
					max-width: 100%;
					.input_title {
						max-width: 100%;
						font-size: 12px;
						line-height: 1.25;
						display: block;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
		}
		.col.l4 {
			flex: 50%;
			max-width: 50%;
			.checkList {
				.checkItem {
					width: 33.33%;
				}
				.all {
					width: 100%;
				}
			}
		}
		.col.l8 {
			flex: 50%;
			max-width: 50%;
			.all {
				width: 100%;
			}
		}
		.col.l12 {
			.all {
				width: 100%;
			}
		}
	}
	.borderLayout {
		border: solid 1px $az_gray_3;
		border-radius: 5px;
		margin: 10px 0;
		.row {
			margin: 0;
			& ~ .row {
				border-top: solid 1px $az_gray_3;
			}
		}
		.required {
			border-left: solid $az_red 6px;
		}
		.col {
			padding: 5px 10px;
			& ~ .col {
				border-left: solid 1px $az_gray_3;
			}
		}
	}

	.add-list-button {
		color: $az_cyan;
	}

	.disabled {
		background: transparent;
	}

	.divideView.locations {
		justify-content: left !important;
	}

	.city {
		width: 70% !important;
	}

	.zipcode {
		width: 70% !important;
	}
	
	.state {
		width: 34% !important;
		.react-select-container {
			min-width: 0px !important;
		}
	}

	.location {
		margin-top: 5px;
		width: 70%;
	}

	.job_number {
		margin-top: 5px;
		width: 70%;
	}

	.required_field {
		&:after {
			content: "*";
			color: $az_maroon;
			font-size: 16px;
		}
	}

	.button-group {
		margin-right: 15px;
	}

	.fly_toggle {
		// height: 30px;
		height: 22px;
		align-items: center;
		display: inline-flex;
		justify-content: center;
		border: 1px solid $az_gray_3;
		border-radius: 25px;
		padding: 0 10px;
		position: relative;
		bottom: -5px;
		left: 5px;
		&.sq {
			padding: 0;
			width: 22px;
			// width: 30px;
		}
		svg {
			height: 25px;
			width: 25px;
		}
		.arrow {
			height: 20px;
		}
	}

	.checkWrapper {
		.label {
			margin: 10px 0;
		}
		.input_icon {
			margin-right: 10px;
		}
		.input_title {
			font-size: 12px;
		}
	}

	.descSection {
		margin-top: 10px;
		.row {
			align-items: flex-end;
		}
		.required {
			border-left: solid $az_red 6px;
			padding-left: 5px;
		}
		.l4 {
			flex: 0 0 50%;
		}
		.l8 {
			flex: 0 0 50%;
		}
	}

	.collapsible_items {
		margin-bottom: 15px;
	}

	.button_secondary {
		min-width: 111px;
	}

	.exportLoader {
		min-width: 147px;
	}

	.rotating_azuga_loader {
		height: 200px;
	}

	.formRow_key {
		flex-grow: 0 !important;
	}

	.ellipses_font {
		font-size: 14px;
	}
}

.visible {
	visibility: visible;
}
.hidden {
	visibility: hidden;
}

.disableUserAction {
	height: 100%;
	background: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
}
