.emailAction {
  .rotating_azuga_loader {
    height: 200px;
  }

  .required_field {
    &:after {
      content: "*";
      color: $az_maroon;
      font-size: 16px;
    }
  }

  .borderLayout {
    .required {
      border-left: solid $az_red 6px;
    }
    .filter {
      display: flex;
      align-items: center;
      input[type="text"] {
        border: none;
        padding: 0;
        margin-left: 15px;
      }
    }
    .checkList {
      display: flex;
      flex-wrap: wrap;
      height: 100px;
      overflow: hidden;
      margin: 0 -10px;
      &:hover {
        overflow-y: auto;
      }
      .checkItem {
        padding: 5px 10px;
        width: 33.333%;
        // .input_icon {
        //   //min-width: 25px;
        // }
        .input_label {
          max-width: 100%;
          .input_title {
            max-width: 100%;
            font-size: 12px;
            line-height: 1.25;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    .col.l4 {
      flex: 50%;
      max-width: 50%;
      .checkList {
        .checkItem {
          width: 33.33%;
        }
      }
    }
    .col.l8 {
      flex: 50%;
      max-width: 50%;
    }
  }

  .dialogFooter {
    border-top: none !important;
    margin-top: 0px !important;
    padding-top: 0px !important;
    .checkItem {
      margin-top: 5px;
      margin-right: 20px;
      text-align: left;
      .input_title {
        margin-left: 0px;
      }
      .input_icon {
        margin-right: 0px;
      }
    }

    .button_secondary {
      margin-left: 0px;
      width: 100px;
    }
  }

  .descSection {
    margin-bottom: 5px;
    .l8 {
      flex: 0 0 50%;
    }
    .l4 {
      display: flex;
      align-items: center;
      flex: 0 0 50%;
    }

    .emailWindow {
      margin-top: 10px;
    }
  }

  .list {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    font-size: 14px;
  }

  .fly_toggle {
    // height: 30px;
    height: 22px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    border: 1px solid $az_gray_3;
    border-radius: 25px;
    padding: 0 10px;
    position: relative;
    bottom: -5px;
    left: 5px;
    &.sq {
      padding: 0;
      width: 22px;
      // width: 30px;
    }
    svg {
      height: 25px;
      width: 25px;
    }
    .arrow {
      height: 20px;
    }
  }

  .azTitle {
    display: inline-block;
  }
  .no-data-available {
    position: absolute;
    left: 45%;
  }
}
