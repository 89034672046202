.accordion {
  background: #fff;
  text-align: left;
  .accordionLabel {
    background: $az_gray_1;
    padding: 15px;
    border: solid 1px $az_gray_3;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    span {
      cursor: pointer;
      flex: 1 0 0;
    }
    svg {
      margin-left: auto;
      margin-right: 0;
      height: 25px;
      width: 25px;
      color: $az_cyan;
      transition: all 0.25s, overflow 0.25s 0.25s;
    }
    &.left {
      flex-direction: row-reverse;
      svg {
        margin-right: 10px;
        margin-left: 0;
      }
    }
  }
  .accordionSection {
    padding: 0 15px;
    transition: all 0.25s;
    margin-bottom: 10px;
    line-height: 1.5;
    box-sizing: content-box;
  }
  &.closed {
    .accordionSection {
      height: 0;
      overflow: hidden;
      &.putHeight {
        max-height: 0;
      }
    }
  }
  &.opened {
    .accordionSection {
      border: solid 1px $az_gray_3;
      border-top: 0;
      padding: 15px;
      margin-bottom: 25px;
      overflow: visible;
      &.putHeight {
        max-height: 1000px;
      }
    }
    .accordionLabel {
      svg {
        transform: rotate(-180deg);
      }
    }
  }
}
