#groupMapping {
	.groupMappping.settingsWrapper {
		padding: 0;
		.table {
			.tr {
				.td,
				.th {
					.cell_in {
						line-height: 1.5rem;
					}
				}
			}
			&:not(.flat) {
				.tr {
					.td,
					.th {
						flex: 0 0 20%;
						&:nth-child(3) {
							flex: 0 0 35%;
						}
						&:nth-child(5) {
							flex: 0 0 5%;
						}
					}
				}
			}
			&.flat {
				.tr {
					.td,
					.th {
						flex: 0 0 25%;
						&:nth-child(2) {
							flex: 0 0 45%;
						}
						&:nth-child(5) {
							flex: 0 0 5%;
						}
					}
				}
			}
		}
	}
}
.dialogOverlay_addGroup {
	.formSection {
		& ~ .formSection {
			border: 0;
		}
	}
	.borderLayout {
		.filter {
			display: flex;
			align-items: center;
			input[type="text"] {
				border: none;
				padding: 0;
				margin-left: 15px;
			}
		}
		.checkList {
			display: flex;
			flex-wrap: wrap;
			height: 100px;
			overflow: hidden;
			margin: 0 -10px;
			&:hover {
				overflow-y: auto;
			}
			.checkItem {
				padding: 5px 10px;
				width: 33.333%;
				.input_label {
					max-width: 100%;
					.input_title {
						max-width: 100%;
						font-size: 13px;
						line-height: 1.25;
						display: block;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
		}
		.col.l4 {
			flex: 50%;
			max-width: 50%;
			.checkList {
				.checkItem {
					width: 33.33%;
				}
			}
		}
		.col.l8 {
			flex: 50%;
			max-width: 50%;
		}
	}
	.descSection {
		.l4 {
			flex: 0 0 50%;
		}
		.l8 {
			flex: 0 0 50%;
		}
	}
}
.borderLayout {
	border: solid 1px $az_gray_3;
	border-radius: 5px;
	margin: 10px 0;
	.row {
		margin: 0;
		& ~ .row {
			border-top: solid 1px $az_gray_3;
		}
	}
	.col {
		padding: 5px 10px;
		& ~ .col {
			border-left: solid 1px $az_gray_3;
		}
	}
}

.groupAlert {
	button.button.button_withicon.button_withicon--right {
		svg {
			margin-left: 5px;
			width: 12px;
			height: 12px;
		}
	}
}

#settingsWraper {
	.azTitle.azTitle_secondary.azTitle--no-margin-top {
		margin-bottom: 10px;
	}
	.divideView.divideView--imLayout {
		&.generalSetting_st1 {
			border-bottom: solid 3px $az_gray_1;
			margin-bottom: 15px;
			padding-bottom: 15px;
			.formRow_key {
				margin-right: 15px;
			}
			.formRow_key,
			.formRow_val {
				width: auto;
				flex: unset;
			}
		}
	}
}
