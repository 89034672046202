.reports-table {
	table-layout: auto;
	width: 100%;
	transition: 1s all ease-in;
	color: $az_rpt_table_text;
	&.reports-table-details {
		tbody {
			display: block;
			// height: 200px;
			overflow-y: auto;
			&::-webkit-scrollbar {
				width: 4px;
			}

			&::-webkit-scrollbar-track {
				box-shadow: inset 0 0 5px grey;
				border-radius: 10px;
			}

			&::-webkit-scrollbar-thumb {
				background: gray;
				border-radius: 10px;
			}
		}
		thead,
		tbody tr {
			display: table;
			width: 100%;
			table-layout: fixed;
		}
		th {
			height: 35px;
		}
		td {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.remove-elipses {
			white-space: inherit;
			overflow: visible;
			text-overflow: inherit;
		}
		.add-elipsis {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	.reports-table-head {
		.header-row th {
			color: $az_white;
			background-color: $az_gray_6;
			vertical-align: middle;
		}
		.reports-table-head-cell {
			line-height: normal;
			.caret-icon {
				vertical-align: middle;
				margin-left: 3px;
				width: 12px;
				height: 12px;
				cursor: pointer;
			}
		}
	}
	.no-data-available {
		position: absolute;
		width: 90%;
		text-align: center;
		top: 40%;
	}
	&.table-no-margin {
		margin-top: unset;
	}
	.add-hover-underline {
		&:hover {
			text-decoration: underline;
		}
	}
}

.reports-summary-customisation {
	margin-top: -40px;
	.summary-table-row {
		height: 30px;
	}
}

.payroll-summary-table {
	margin-top: unset;
	.summary-table-row {
		height: 35px;
		.reports-summary-table-data {
			height: 35px;
			.data-conatiner {
				.left-value {
					.value {
						vertical-align: middle;
						background-color: white;
					}
				}
			}
		}
	}
}

.reports-summary-table-data {
	position: relative;
	width: inherit;
	height: 30px;
	.data-conatiner {
		position: absolute;
		height: inherit;
		width: 100%;
		z-index: 1000;
		.left-value {
			// display:flex;
			transition: 0.3s all ease-in;
			border-left: 1px dashed $az_white;
			.value {
				width: 100%;
				// margin-left: 45px;
				background-color: white;
				vertical-align: -webkit-baseline-middle;
			}
		}
	}
	.half-border-container {
		position: absolute;
		height: 50%;
		width: 50%;
		bottom: 0;
		z-index: 1;
		border: 1px dashed $az_white;
		border-top-left-radius: 3px;
		&.clicked-half-border {
			top: 0;
		}
		&.right {
			right: 0;
		}
	}
}
.clicked-value-container {
	visibility: hidden;
	transition: 1s all ease-in;
	font-weight: 600;
	font-size: 16px;
	line-height: 17px;
	color: $az_rpt_black;
}
.report-detail-table-wrapper {
	border-left: 2px solid $az_gray_2;
	display: flex;
	overflow-x: scroll;
	position: relative;
	.report-detail-table-right-chevron-container {
		position: sticky;
		right: 0;
		text-align: center;
		background: linear-gradient(
			270deg,
			rgba(128, 124, 124, 0.4) 0%,
			rgba(79, 79, 79, 0) 68.75%
		);
		margin-right: 1px;
		border-bottom-right-radius: 3px;
		.black-color {
			height: 35px;
			background-color: $az_gray_6;
			margin-left: -3.5px;
			width: 24px;
		}
		.right-arrow {
			position: absolute;
    		top: 50%;
			margin-left: -10px;
			cursor: pointer;
		}
	}
	.report-detail-table-left-chevron-container {
		position: sticky;
		left: 0;
		text-align: center;
		background: linear-gradient(
			90deg,
			rgba(128, 124, 124, 0.4) 0%,
			rgba(79, 79, 79, 0) 68.75%
		);
		border-bottom-right-radius: 3px;
		.black-color {
			height: 35px;
			background-color: $az_gray_6;
			margin-left: -3.5px;
			width: 24px;
		}
		.left-arrow {
			// margin-left: 10px;
			cursor: pointer;
			position: absolute;
    		top: 50%;
		}
	}
}
.with-bottom-border {
	border-bottom: 2px solid $az_gray_2;
}
.report-detail-table-bottom-chevron-container {
	margin-top: -7px;
	background: linear-gradient(
		0deg,
		rgba(128, 124, 124, 0.4) 0%,
		rgba(79, 79, 79, 0) 68.75%
	);
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	text-align: center;
	padding-bottom: 10px;
}

.tabView {
	border-radius: 5px;
    padding: 10px;
}
.drill-down-details-popup {
	position: fixed;
	background-color: $az_white;
	border-left: 2px solid $az_gray_2;
	// border-right: 2px solid $az_gray_2;
	border-bottom: 2px solid $az_gray_2;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	transition: 0.5s visibility ease-out;
	z-index: 1;
	.activity-log-table {
		&.table-no-margin {
			margin-top: unset;
		}

		&.reports-table-details {
			tbody {
				display: block;
				height: 200px;
				overflow-y: auto;

				&::-webkit-scrollbar {
					width: 2px;
				}

				&::-webkit-scrollbar-track {
					box-shadow: inset 0 0 5px grey;
					border-radius: 10px;
				}

				&::-webkit-scrollbar-thumb {
					background: gray;
					border-radius: 10px;
				}
			}
			thead,
			tbody tr {
				display: table;
				width: 100%;
				table-layout: fixed;
			}
			th {
				height: 35px;
			}
			td {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.remove-elipses {
				white-space: inherit;
				overflow: visible;
				text-overflow: inherit;
			}
			.add-elipsis {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		.activity-log-table-head {
			color: $az_white;
			background-color: $az_gray_6;
			.header-row {
				.header-cell {
					width: 100px;
				}
			}
		}
	}
	.module_list {
		display: flex;
		padding-top: 10px;
    	padding-bottom: 10px;
	}
	.module {
		margin-left: 30px;
		font-size: 15px;
		font-weight: 500;
		line-height: 16px;
		padding-bottom: 10px;
		margin-right: 30px;
		&:hover {
			cursor: pointer;
		}
	}
	.list_item {
		display: flex;
	}
	.active {
		border-bottom: 3px solid #343741;
	}
	.first {
		margin-left: 20px;
	}
	.disabled {
		color: $az_gray_4;
	}
}

.reports-tooltip {
	position: relative;
	display: inline-block;
	white-space: unset;
	overflow: unset;
	text-overflow: unset;
	.reports-tooltiptext {
		display: none;
		// width: 120px;
		background-color: $az_white;
		color: $az_rpt_table_text;
		text-align: center;
		border-radius: 6px;
		padding: 5px 16px;
		position: absolute;
		z-index: 1;
		top: 80%;
		left: 50%;
		margin-left: -60px;
		@include opacity(0);
		transition: opacity 0.3s;
		@include box-shadow;
	}
	.reports-tooltiptext::after {
		content: "";
		position: absolute;
		bottom: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: transparent transparent $az_white transparent;
	}
	&.reports-tooltip:hover {
		.reports-tooltiptext {
			display: block;
			@include opacity(1);
		}
	}

	.report-media-file-download {
		color: $az_cyan;
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}
}
