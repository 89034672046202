.integration-activity-log{
    color: $az_rpt_black;
    width: 90%;
    .log-list{
        max-height: 50vh;
        overflow-y: scroll;
    }
    .log-item{
        display: flex;
    }
    .log-left{
        text-align: right;
        margin-right: 16px;
    }
    .log-right{
        padding-left: 16px;
        border-left:1px dashed $az_gray_3 ;
        position: relative;
        width: 80%;
    }
    .circle{
        position:absolute;
        height: 9px;
        width: 9px;
        border-radius: 9px;
        background: $az_gray_6;
        margin-top: 20px;
        left: -5px;
    }
    .activity-content-container{
        position: relative;
        margin-top: 12px;
        padding: 16px;
        margin-bottom: 8px;
        margin-right: 4px;
    }
    .activity-content-container::after{
        content: "";
        position: absolute;
        top: 16px;
        right: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent white transparent transparent;
    }
    .activity-content{
        display: flex;
        flex-direction: column;
        padding: 0px;
        .sub-text{
            font-size: 12px;
            font-weight: 400;
            color: $az_gray_5;
        }

    }
}