.settingsWrapper {
	.row {
		.col {
			padding: 0;
			&:first-child {
				padding-right: 15px;
			}

			&:last-child {
				padding-left: 15px !important;
			}
			.formSection,
			.status_list_wrapper {
				.statusList,
				.status_list {
					padding: 10px 20px;
					margin: 0;
					max-height: 50vh;
					overflow: auto;
				}

				// .status_list {
				//   max-height: 50vh;
				// }
			}
		}
	}
}
.wfms {
	.settingsWrapper {
		padding: 0 !important;
		.row {
			.col {
				border-right: 3px solid $az_gray_1;
				.statusList {
					.statusItem {
						padding-left: 0;
					}
				}
			}
		}
	}
	.search-group {
		position: relative;
		.search_icon {
			color: rgba($az_black, 0.5);
			position: absolute;
			height: 15px;
			width: 15px;
			top: 0;
			bottom: 0;
			left: 10px;
			margin: auto;
		}
		input[type="text"] {
			transition: all 0.25s;
			@include input(
				$bg_color: $az_gray_2,
				$border_color: rgba($az_black, 0.5)
			);
			@include input-placeholder-font(rgba($az_black, 0.4));
			color: $az_black;
			padding-left: 40px;
			border: 0;
			&:focus {
				padding-left: 15px;
				& + .search_icon {
					@include opacity(0);
				}
			}
		}
	}
	.tabs {
		.tabList {
			padding-top: 15px;
			width: 100px;
			margin-right: 25px !important;
		}
		.tabPanels {
			padding: 15px;
			border-left: 3px solid $az_gray_1;
			flex: unset;
			width: calc(100% - 125px);
			.formSection {
				padding-top: 0;

				& ~ .formSection {
					padding-top: 25px;
				}
			}
		}
	}
	.formRow_actions {
		margin-top: 50px;
	}
	.settingsHeader {
		.strong {
			white-space: nowrap;
		}
	}
	.statusList {
		.statusItem {
			cursor: pointer;
			> span {
				display: block;
				max-width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			&.active {
				position: relative;
				&:after {
					// content: "";
					position: absolute;
					height: 50%;
					width: 4px;
					background: $az_cyan;
					top: 0;
					bottom: 0;
					left: 0;
					margin: auto;
				}
			}
		}
	}
	.hright .formRow.mailingCheck.divideView_items {
		#settingsWrapper & {
			margin: 0;
			justify-content: flex-end;
		}
	}
}

.flexLay {
	.flexLay_row {
		display: flex;
		&:not(:last-of-type) {
			.flexLay_cell {
				border-bottom: 2px solid $az_gray_1;
			}
		}
		.flexLay_cell {
			flex: 0.5 0 0;
			padding: 15px 30px;
			&.flexLay_cell--twice {
				flex: 1 0 0;
			}
			&.flexLay_cell--empty {
				border-bottom: transparent;
			}
		}
	}
}

.rolesUserList {
	padding: 0 15px;
	> .title {
		padding: 0 20px;
		margin-bottom: 25px;
	}

	.roleUserTable {
		table {
			tbody,
			thead {
				tr {
					th,
					td {
						.cell_in {
							.actionsHover {
								left: -10px;
								right: auto;
								transform: none;
								& + .item {
									transition: all 0.25s;
								}
								svg {
									width: 15px;
									height: 15px;
									transform: translateY(3px);
								}
							}
						}
					}
					&:hover {
						th,
						td {
							.cell_in {
								.actionsHover {
									transform: none;
									left: 15px;
									right: auto;
									& + .item {
										padding-left: 25px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.dialogOverlay_addRole {
	.searchList {
		padding: 2px 17px;
		margin-top: 20px;
		overflow: auto;
		margin: 20px -15px 0;
		ul {
			margin: 0;
		}
		.listItem {
			@include az_hover;
		}
	}
	.search-group {
		width: 300px;
		margin: 0;
		position: relative;
		.search_icon {
			color: rgba($az_black, 0.5);
			position: absolute;
			height: 15px;
			width: 15px;
			top: 0;
			bottom: 0;
			left: 10px;
			margin: auto;
		}
		input[type="text"] {
			transition: all 0.25s;
			@include input(
				$bg_color: $az_gray_2,
				$border_color: rgba($az_black, 0.5)
			);
			@include input-placeholder-font(rgba($az_black, 0.4));
			color: $az_black;
			padding-left: 40px;
			border: 0;
			&:focus {
				padding-left: 15px;
				& + .search_icon {
					@include opacity(0);
				}
			}
		}
	}
}

#userPermissions {
	.permissionList {
		max-height: 60vh;
		overflow: auto;
		position: relative;
	}
	.collapsible {
		background-color: #777;
		color: white;
		cursor: pointer;
		padding: 18px;
		width: 100%;
		border: none;
		text-align: left;
		outline: none;
		font-size: 15px;
	}

	.active,
	.collapsible:hover {
		background-color: #555;
	}

	.content {
		padding: 0 18px;
		display: none;
		overflow: hidden;
		background-color: #f1f1f1;
	}
}

#user-passwords {
	.inputDup {
		height: 30px;
		display: flex;
		background: #eee;
		text-align: left;
		padding: 5px 10px;
		border-radius: 3px;
	}
	.iconInput_val {
		.iconInput {
			position: relative;
			input {
				padding-right: 30px;
				font-family: monospace;
			}
			svg {
				position: absolute;
				right: 10px;
				top: 0;
				height: 30px;
			}
			& + p {
				margin-top: 5px;
			}
		}
	}

	.strength {
		font-size: 14px;
		line-height: 19px;
		letter-spacing: 0.1px;
		color: #8a8a8a;
		width: 100%;
		margin-top: 5px;
	}
	.reset-password {
		color: #0069b1;
		cursor: pointer;
	}
}

.acTitle {
	display: flex;
	align-items: center;
	span {
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.35s;
		svg {
			height: 12px;
			width: 12px;
		}
	}

	&.opened {
		span {
			transform: rotate(180deg);
		}
	}
}
