#toplink {
	position: relative;
	left: 0;
	right: 0;
	top: 0;
	z-index: 999;
	background: #040000;
	.toplink_container {
		margin: 0;
		color: #ffffff;
		list-style: none;
		display: flex;
		justify-content: flex-end;
		height: 56px;
		align-items: center;
		> li {
			padding: 5px 18px;
			&.logo {
				> img {
					width: 117px;
				}
				> span {
					font-weight: 500 !important;
					margin: 0px 16px !important;
					font-size: 15px;
				}
				margin-right: auto;
				display: flex;
				align-items: center;
				button.switch {
					display: flex;
					align-items: center;
					font-size: 12px;
					position: relative;
					margin-left: 30px;
					height: 25px;
					line-height: 25px;
					border-radius: 30px;
					&:before {
						content: "";
						position: absolute;
						left: -15px;
						top: 0;
						bottom: 0;
						margin: auto;
						width: 1px;
						background: #fff;
					}
					span {
						height: 15px;
						width: 15px;
						margin-left: 5px;
						border-radius: 15px;
						border: solid 1px #fff;
						display: flex;
						align-items: center;
						justify-content: center;
						svg {
							height: 11px;
							width: 11px;
						}
					}
				}
			}

			a {
				text-transform: uppercase;
				transition: all 0.25s;
				&:hover {
					@include opacity(0.5);
				}
			}
			.avatar {
				vertical-align: middle;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				margin-right: 10px;
				&.prof_icon.avatar {
					font-size: 20px;
					font-weight: 500;
					background: #0069b1;
					color: #fff;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
		.caret-icon {
			vertical-align: middle;
			position: relative;
			margin-left: 3px;
			bottom: 1.5px;
			width: 13px;
			height: 13px;
			cursor: pointer;
		}
	}
	.user-selection-popup {
		position: absolute;
		// min-width: 305px;
		// height: 82px;
		padding: 10px;
		background-color: white;
		left: 145px;
		top: 45px;
		border-radius: 5px;
		z-index: 1000;
		@include box-shadow;
		.user-selection-popup-element {
			margin-top: 8px;
			display: flex;
			font-size: 14px;
		}
		.error {
			color: #ff0d0d;
		}
		.customer_name {
			margin-top: 0px;
			font-size: 16px;
			font-weight: bold;
		}
		.switch_btn {
			background-color: #343741;
			height: 30px;
			margin-left: 10px;
			line-height: 16px;
		}

		.disabled {
			background-color: #d8d8d8 !important;
			color: #979797;
		}

		.search-group {
			width: 200px;
			margin: 0;
			position: relative;
			.search_icon {
				color: rgba($az_black, 0.5);
				position: absolute;
				height: 15px;
				width: 15px;
				top: 0;
				bottom: 0;
				left: 10px;
				margin: auto;
			}
			input[type="text"] {
				transition: all 0.25s;
				padding-left: 40px;
				&:focus {
					padding-left: 15px;
					& + .search_icon {
						@include opacity(0);
					}
				}
			}
			@include respondToBelow(mid-size) {
				flex-basis: 100%;
			}
		}
	}
}
