.payments-settings {
	&__info {
		font-weight: 500;
		text-align: center;
	}

	&__icons {
		margin: 50px 0 40px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	&__fatt-logo {
		height: 45px;
	}

	&__arrows {
		width: 85px;
		margin: 18px 25px 0;
	}

	&__azuga-logo {
		width: 126px;
		align-self: flex-end;
	}

	&__link_container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	&__link {
		margin: 10px 6px 0;
	}

	&__keys-container {
		margin: 20px 0 10px;
		padding-top: 15px;
		border-top: 2px solid rgb(246, 246, 246);
	}

	&__tenant-keys {
		display: flex;
	}

	&__key-row {
		display: flex;
		align-items: center;

		& ~ & {
			margin-top: 10px;
		}
	}

	&__key-label {
		color: $az_gray_4;
		min-width: 150px;
		margin-right: 25px;
		font-size: 13px;
	}

	&__key {
		min-width: 200px;
		margin-right: 25px;
	}

	&__actions {
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 55px !important;

		&--animated {
			opacity: 0;
			color: $az_cyan;
			transition: all 0.25s;
			transform: translateX(10px);
		}
	}

	&__icon {
		cursor: pointer;

		& ~ & {
			margin-left: 15px;
		}
	}

	&__tenant-keys:hover &__actions--animated,
	&__row--hover:hover &__actions--animated {
		opacity: 1;
		transform: translateX(0px);
	}

	&__input {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
		max-width: 500px;
		margin-bottom: 15px;

		.formRow_key {
			min-width: 140px;
		}

		.formRow_val {
			flex: 1;
			min-width: 150px;
			margin-right: 25px;
		}
	}

	&__btn {
		display: flex !important;
		margin-left: auto;
	}

	&__obs {
		font-size: 13px;
		color: $az_gray_5;
	}

	&__table {
		overflow: auto;
		max-height: 60vh;
		border: 1px solid $az_gray_3;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
	}

	&__row {
		display: flex;
		min-width: fit-content;

		gap: 25px;
		margin: 3px 0;

		font-size: 13px;
		padding: 15px 30px;

		&--header {
			margin: 0;
			color: $az_white;
			font-weight: bold;
			padding: 15px 40px;
			background: $az_gray_6;
		}

		&--form {
			padding: 9px 30px;

			.groupName {
				margin-top: 5px;
			}
		}

		&--hover {
			margin: 3px 10px;
			border-bottom: 2px solid $az_gray_1;

			&:hover {
				background: transparent;
				position: relative;
				border-radius: 8px;
				box-shadow: 0 0 0 1.5px #00a3e0, 0 4px 4px 0 rgba(0, 0, 0, 0.14),
					0 7px 10px -5px rgba(0, 0, 0, 0.25);
			}
		}

		.formRow {
			margin-bottom: 0;
		}

		> div {
			flex: 1;
			min-width: 250px;
		}
	}

	&__scroll {
		&::-webkit-scrollbar {
			-webkit-appearance: none;
		}

		&::-webkit-scrollbar:vertical {
			width: 11px;
		}

		&::-webkit-scrollbar:horizontal {
			height: 11px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 8px;
			border: 2px solid $az_white;
			background-color: rgba(0, 0, 0, 0.5);
		}

		&::-webkit-scrollbar-track {
			border-radius: 8px;
			background-color: $az_white;
		}
	}
}
