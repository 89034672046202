.invoice-form {
	@include estimate-form;

	&__fields {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__tags {
		padding-bottom: 15px;
		border-bottom: 1px solid $az_gray_3;
	}
}

.invoice-items {
	@include estimate-items;
}

.invoice-total {
	@include estimate-total;
}
