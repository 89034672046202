.payment-method {
	&__block {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__address-header {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin: 10px 0 20px;
	}

	&__address-title {
		width: 24%;
		font-size: 15px;
		font-weight: 500;
		min-width: 150px;

		@media only screen and (max-width: $screen-xs-min) {
			margin-bottom: 10px;
		}
	}

	&__switch {
		display: flex;
		align-items: center;
	}

	&__switch-title {
		margin-left: 15px;
		font-weight: 500;
		color: $az_gray_4;
	}

	&__field {
		width: calc(50% - 30px / 2);
		margin-right: 0 !important;

		@media only screen and (max-width: $screen-sm-jobs) {
			flex-basis: 100%;
		}

		&--align {
			align-items: center;

			.formRow_key {
				margin-top: 0 !important;
			}
		}
	}

	&__fatt {
		width: 100%;
		height: 28px;
	}

	&__input {
		display: flex;
		border-radius: 3px;
		border: 1px solid rgba(4, 0, 0, 0.3);
		align-items: center;
		background: $az_white;

		&--invalid {
			border-left: 6px solid $az_gold;
		}
	}

	&__icon {
		color: $az_cyan;
		margin-top: 3px;
		margin-right: 10px;
	}
}
