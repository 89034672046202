#report-modal-worker-availability {
    width: 85vw;
	.worker-availability-report-modal {
        color: $az_rpt_black;
    }
    #worker-availability-report-dialog-body{
        transition:1s all ease-in;
        .report-detail-table-container{
            transition:1s all ease-in;
            // height: 300px;
            // overflow-y:auto;            
        }
        .center-summary-block{
            display: flex;
            justify-content: space-around;
        }
        svg{
            width: unset;
            height: unset;
        }
    }
}