.dateTime {
	position: relative;
	.react-datepicker-wrapper {
		display: block;
	}
	input {
		padding-right: 25px;
		font-family: inherit;
		// font-size: 12px;
	}

	.dateIco,
	.react-datepicker__close-icon {
		position: absolute;
		color: $az_cyan;
		height: 16px;
		width: 16px;
		top: 8px;
		right: 8px;
		pointer-events: none;
	}
	button {
		min-width: 0;
	}
	.react-datepicker__close-icon {
		right: 30px;
		padding: 0;
		pointer-events: auto;
		cursor: pointer;
		&::after {
			cursor: pointer;
			background-color: #216ba5;
			border-radius: 50%;
			height: 16px;
			width: 16px;
			line-height: 1;
			text-align: center;
			display: table-cell;
			vertical-align: middle;
			content: "\D7";
			color: #bbbbbb;
			background: transparent;
			padding: 0;
			font-size: 20px;
			transform: translateY(-3px);
		}
	}
	.react-datepicker-popper {
		.react-datepicker {
			display: flex;
			button {
				top: 15px;
				&.react-datepicker__navigation--next {
					border-left-color: $az_cyan;
					border-width: 0.4em;
				}
				&.react-datepicker__navigation--previous {
					border-right-color: $az_cyan;
					border-width: 0.4em;
				}
			}
			.react-datepicker__header {
				background: $az_white;
				.react-datepicker__current-month {
					padding: 5px 0 10px;
				}
			}
		}
		&[data-placement="bottom-start"] {
			.react-datepicker {
				.react-datepicker__triangle {
					border-bottom-color: $az_white;
				}
			}
		}
		&[data-placement="top-start"] {
			.react-datepicker {
				.react-datepicker__triangle {
					border-top-color: $az_white;
				}
			}
		}
	}
}

.react-datepicker-popper {
	&[data-placement^="bottom"] .react-datepicker__triangle,
	&[data-placement^="top"] .react-datepicker__triangle {
		margin-left: -8px;
		position: absolute;
	}
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
	margin-left: -8px;
	position: absolute;
}

.react-datepicker-popper {
	&[data-placement^="bottom"] .react-datepicker__triangle,
	&[data-placement^="top"] .react-datepicker__triangle {
		box-sizing: content-box;
		position: absolute;
		border: 8px solid transparent;
		height: 0;
		width: 1px;
	}
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
	box-sizing: content-box;
	position: absolute;
	border: 8px solid transparent;
	height: 0;
	width: 1px;
}

.react-datepicker-popper {
	&[data-placement^="bottom"] .react-datepicker__triangle::before,
	&[data-placement^="top"] .react-datepicker__triangle::before {
		box-sizing: content-box;
		position: absolute;
		border: 8px solid transparent;
		height: 0;
		width: 1px;
	}
}

.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
	box-sizing: content-box;
	position: absolute;
	border: 8px solid transparent;
	height: 0;
	width: 1px;
}

.react-datepicker-popper {
	&[data-placement^="bottom"] .react-datepicker__triangle::before,
	&[data-placement^="top"] .react-datepicker__triangle::before {
		content: "";
		z-index: -1;
		border-width: 8px;
		left: -8px;
		border-bottom-color: #aeaeae;
	}
}

.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
	content: "";
	z-index: -1;
	border-width: 8px;
	left: -8px;
	border-bottom-color: #aeaeae;
}

.react-datepicker-popper {
	&[data-placement^="bottom"] .react-datepicker__triangle {
		top: 0;
		margin-top: -8px;
		border-top: none;
		border-bottom-color: #f0f0f0;

		&::before {
			border-top: none;
			border-bottom-color: #f0f0f0;
			top: -1px;
			border-bottom-color: #aeaeae;
		}
	}

	&[data-placement^="top"] .react-datepicker__triangle {
		bottom: 0;
		margin-bottom: -8px;
	}
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
	bottom: 0;
	margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^="top"]
	.react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
	border-bottom: none;
	border-top-color: #fff;
}

.react-datepicker-popper[data-placement^="top"]
	.react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
	bottom: -1px;
	border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
	display: inline-block;
	padding: 0;
	border: 0;
}

.react-datepicker {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 0.8rem;
	background-color: #fff;
	color: #000;
	border: 1px solid #aeaeae;
	border-radius: 0.3rem;
	display: inline-block;
	position: relative;
}

.react-datepicker--time-only {
	.react-datepicker__triangle {
		left: 35px;
	}

	.react-datepicker__time-container {
		border-left: 0;
	}

	.react-datepicker__time,
	.react-datepicker__time-box {
		border-radius: 0.3rem;
	}
}

.react-datepicker__triangle {
	position: absolute;
	left: 50px;
}

.react-datepicker-popper {
	z-index: 99999;

	&[data-placement^="bottom"] {
		margin-top: 10px;
	}

	&[data-placement="bottom-end"] .react-datepicker__triangle,
	&[data-placement="top-end"] .react-datepicker__triangle {
		left: auto;
		right: 50px;
	}

	&[data-placement^="top"] {
		margin-bottom: 10px;
	}

	&[data-placement^="right"] {
		margin-left: 8px;

		.react-datepicker__triangle {
			left: auto;
			right: 42px;
		}
	}

	&[data-placement^="left"] {
		margin-right: 8px;

		.react-datepicker__triangle {
			left: 42px;
			right: auto;
		}
	}
}

.react-datepicker__header {
	text-align: center;
	background-color: #f0f0f0;
	border-bottom: 1px solid #aeaeae;
	border-top-left-radius: 0.3rem;
	border-top-right-radius: 0.3rem;
	padding-top: 8px;
	position: relative;
}

.react-datepicker__header--time {
	padding-bottom: 8px;
	padding-left: 5px;
	padding-right: 5px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
	display: inline-block;
	margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
	margin-top: 0;
	color: #000;
	font-weight: bold;
	font-size: 0.944rem;
}

.react-datepicker-time__header {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.react-datepicker__navigation {
	background: none;
	line-height: 1.7rem;
	text-align: center;
	cursor: pointer;
	position: absolute;
	top: 10px;
	width: 0;
	padding: 0;
	border: 0.45rem solid transparent;
	z-index: 1;
	height: 10px;
	width: 10px;
	min-width: 0;
	text-indent: -999em;
	overflow: hidden;
}

.react-datepicker__navigation--previous {
	left: 10px;
	border-right-color: #ccc;

	&:hover {
		border-right-color: #b3b3b3;
	}
}

.react-datepicker__navigation--previous--disabled {
	border-right-color: #e6e6e6;
	cursor: default;

	&:hover {
		border-right-color: #e6e6e6;
		cursor: default;
	}
}

.react-datepicker__navigation--next {
	right: 10px;
	border-left-color: #ccc;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
	right: 80px;
}

.react-datepicker__navigation--next:hover {
	border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled {
	border-left-color: #e6e6e6;
	cursor: default;

	&:hover {
		border-left-color: #e6e6e6;
		cursor: default;
	}
}

.react-datepicker__navigation--years {
	position: relative;
	top: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.react-datepicker__navigation--years-previous {
	top: 4px;
	border-top-color: #ccc;

	&:hover {
		border-top-color: #b3b3b3;
	}
}

.react-datepicker__navigation--years-upcoming {
	top: -4px;
	border-bottom-color: #ccc;

	&:hover {
		border-bottom-color: #b3b3b3;
	}
}

.react-datepicker__month-container {
	float: left;
}

.react-datepicker__month {
	margin: 0.4rem;
	text-align: center;

	.react-datepicker__month-text,
	.react-datepicker__quarter-text {
		display: inline-block;
		width: 4rem;
		margin: 2px;
	}
}

.react-datepicker__input-time-container {
	clear: both;
	width: 100%;
	float: left;
	margin: 5px 0 10px 15px;
	text-align: left;

	.react-datepicker-time__caption {
		display: inline-block;
	}

	.react-datepicker-time__input-container {
		display: inline-block;

		.react-datepicker-time__input {
			display: inline-block;
			margin-left: 10px;

			input {
				width: 85px;

				&[type="time"] {
					&::-webkit-inner-spin-button,
					&::-webkit-outer-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}

					-moz-appearance: textfield;
				}
			}
		}

		.react-datepicker-time__delimiter {
			margin-left: 5px;
			display: inline-block;
		}
	}
}

.react-datepicker__time-container {
	float: right;
	border-left: 1px solid #aeaeae;
	width: 85px;
}

.react-datepicker__time-container--with-today-button {
	display: inline;
	border: 1px solid #aeaeae;
	border-radius: 0.3rem;
	position: absolute;
	right: -72px;
	top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
	position: relative;
	background: white;

	.react-datepicker__time-box {
		width: 85px;
		overflow-x: hidden;
		margin: 0 auto;
		text-align: center;

		ul.react-datepicker__time-list {
			list-style: none;
			margin: 0;
			height: calc(195px + (1.7rem / 2));
			overflow-y: scroll;
			padding-right: 0px;
			padding-left: 0px;
			width: 100%;
			box-sizing: content-box;

			li {
				&.react-datepicker__time-list-item {
					height: 30px;
					padding: 5px 10px;
					white-space: nowrap;

					&:hover {
						cursor: pointer;
						background-color: #f0f0f0;
					}
				}

				&.react-datepicker__time-list-item--selected {
					background-color: #216ba5;
					color: white;
					font-weight: bold;

					&:hover {
						background-color: #216ba5;
					}
				}

				&.react-datepicker__time-list-item--disabled {
					color: #ccc;

					&:hover {
						cursor: default;
						background-color: transparent;
					}
				}
			}
		}
	}
}

.react-datepicker__week-number {
	color: #ccc;
	display: inline-block;
	width: 1.7rem;
	line-height: 1.7rem;
	text-align: center;
	margin: 0.166rem;

	&.react-datepicker__week-number--clickable {
		cursor: pointer;

		&:hover {
			border-radius: 0.3rem;
			background-color: #f0f0f0;
		}
	}
}

.react-datepicker__day-names,
.react-datepicker__week {
	white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
	color: #000;
	display: inline-block;
	width: 1.7rem;
	line-height: 1.7rem;
	text-align: center;
	margin: 0.166rem;
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
	border-radius: 0.3rem;
	background-color: #216ba5;
	color: #fff;
}

.react-datepicker__month--selected:hover,
.react-datepicker__month--in-selecting-range:hover,
.react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
	background-color: #1d5d90;
}

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
	color: #ccc;
	pointer-events: none;
}

.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
	cursor: default;
	background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text {
	cursor: pointer;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
	border-radius: 0.3rem;
	background-color: #f0f0f0;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today {
	font-weight: bold;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted {
	border-radius: 0.3rem;
	background-color: #3dcc4a;
	color: #fff;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover {
	background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1 {
	color: magenta;
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2 {
	color: green;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
	border-radius: 0.3rem;
	background-color: #216ba5;
	color: #fff;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover {
	background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
	border-radius: 0.3rem;
	background-color: #2a87d0;
	color: #fff;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover {
	background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range {
	background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range {
	.react-datepicker__day--in-range,
	.react-datepicker__month-text--in-range,
	.react-datepicker__quarter-text--in-range {
		background-color: #f0f0f0;
		color: #000;
	}
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled {
	cursor: default;
	color: #ccc;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover {
	background-color: transparent;
}

.react-datepicker__month-text {
	&.react-datepicker__month--selected:hover,
	&.react-datepicker__month--in-range:hover,
	&.react-datepicker__quarter--selected:hover,
	&.react-datepicker__quarter--in-range:hover {
		background-color: #216ba5;
	}
}

.react-datepicker__quarter-text {
	&.react-datepicker__month--selected:hover,
	&.react-datepicker__month--in-range:hover,
	&.react-datepicker__quarter--selected:hover,
	&.react-datepicker__quarter--in-range:hover {
		background-color: #216ba5;
	}
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
	background-color: #f0f0f0;
}

.react-datepicker__input-container {
	position: relative;
	display: inline-block;
	width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
	border: 1px solid transparent;
	border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
	cursor: pointer;
}

.react-datepicker__year-read-view:hover {
	.react-datepicker__year-read-view--down-arrow,
	.react-datepicker__month-read-view--down-arrow {
		border-top-color: #b3b3b3;
	}
}

.react-datepicker__month-read-view:hover {
	.react-datepicker__year-read-view--down-arrow,
	.react-datepicker__month-read-view--down-arrow {
		border-top-color: #b3b3b3;
	}
}

.react-datepicker__month-year-read-view:hover {
	.react-datepicker__year-read-view--down-arrow,
	.react-datepicker__month-read-view--down-arrow {
		border-top-color: #b3b3b3;
	}
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
	border-top-color: #ccc;
	float: right;
	margin-left: 20px;
	top: 8px;
	position: relative;
	border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
	background-color: #f0f0f0;
	position: absolute;
	width: 50%;
	left: 25%;
	top: 30px;
	z-index: 1;
	text-align: center;
	border-radius: 0.3rem;
	border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
	cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
	height: 150px;
	overflow-y: auto;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
	line-height: 20px;
	width: 100%;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
	border-top-left-radius: 0.3rem;
	border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border-bottom-left-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
	background-color: #ccc;
}

.react-datepicker__year-option:hover
	.react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover
	.react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover
	.react-datepicker__navigation--years-upcoming {
	border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover
	.react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover
	.react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover
	.react-datepicker__navigation--years-previous {
	border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
	position: absolute;
	left: 15px;
}

.react-datepicker__close-icon {
	cursor: pointer;
	background-color: transparent;
	border: 0;
	outline: 0;
	padding: 0px 6px 0px 0px;
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	display: table-cell;
	vertical-align: middle;

	&::after {
		cursor: pointer;
		background-color: #216ba5;
		color: #fff;
		border-radius: 50%;
		height: 16px;
		width: 16px;
		padding: 2px;
		font-size: 12px;
		line-height: 1;
		text-align: center;
		display: table-cell;
		vertical-align: middle;
		content: "\00d7";
	}
}

.react-datepicker__today-button {
	background: #f0f0f0;
	border-top: 1px solid #aeaeae;
	cursor: pointer;
	text-align: center;
	font-weight: bold;
	padding: 5px 0;
	clear: left;
}

.react-datepicker__portal {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.8);
	left: 0;
	top: 0;
	justify-content: center;
	align-items: center;
	display: flex;
	z-index: 2147483647;

	.react-datepicker__day-name,
	.react-datepicker__day,
	.react-datepicker__time-name {
		width: 3rem;
		line-height: 3rem;
	}

	.react-datepicker__current-month,
	.react-datepicker-time__header {
		font-size: 1.44rem;
	}

	.react-datepicker__navigation {
		border: 0.81rem solid transparent;
	}

	.react-datepicker__navigation--previous {
		border-right-color: #ccc;

		&:hover {
			border-right-color: #b3b3b3;
		}
	}

	.react-datepicker__navigation--previous--disabled {
		border-right-color: #e6e6e6;
		cursor: default;

		&:hover {
			border-right-color: #e6e6e6;
			cursor: default;
		}
	}

	.react-datepicker__navigation--next {
		border-left-color: #ccc;

		&:hover {
			border-left-color: #b3b3b3;
		}
	}

	.react-datepicker__navigation--next--disabled {
		border-left-color: #e6e6e6;
		cursor: default;

		&:hover {
			border-left-color: #e6e6e6;
			cursor: default;
		}
	}
}

@media (max-width: 400px), (max-height: 550px) {
	.react-datepicker__portal {
		.react-datepicker__day-name,
		.react-datepicker__day,
		.react-datepicker__time-name {
			width: 2rem;
			line-height: 2rem;
		}
	}
}
