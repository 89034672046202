#item-landing {
	margin: 10px -20px;
	width: 100%;
	min-height: 600px;

	.flex-container {
		display: flex;
		flex-wrap: nowrap;
		margin-bottom: 10px;
	}

	.flex-container > div {
		width: 186px;
		margin: 5px;
		text-align: center;
		line-height: 75px;
		border-radius: 5px;
	}
	.out-of-stock {
		background-color: #ff6565 !important;
	}
	.low-inventory {
		background-color: #ecd446 !important;
	}
	.threshold-info {
		height: 68px;
		img {
			position: relative;
			float: left;
			margin: 16px 4px;
			width: 38px;
		}
		.number {
			font-size: 25px;
			font-weight: 600;
			margin-top: 8px;
			margin-right: 50%;
		}
		.status {
			margin-right: 10%;
			font-size: 15px;
			font-weight: 500;
		}
	}
	.item-info {
		height: 270px !important;
		box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
		border-radius: 4px;

		.item-info-section {
			cursor: pointer;
		}
		.main-img {
			width: 60px;
			margin-top: 40px;
		}
		.tile-info {
			margin: 30px 15px;
			text-align: left;
			font-size: 18px;
			font-weight: 500;
		}
		.notification {
			margin: -10px 10px;
			text-align: left;
			cursor: pointer;
			span {
				margin-left: 5px;
				color: #0667b7;
				font-weight: 500;
			}
		}
		&.disabledInventory {
			height: 220px  !important;
		}
	}
}
