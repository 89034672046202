.team-modal{
    .team-modal-content{
        color: $az_rpt_black;
        .form-container{
            display: grid;
            grid-template-columns: auto auto;
            .key-value-container{
                display: grid;
                grid-template-columns: 20% 60%;
                .key{
                    display: flex;
                    align-items: center;
                    .add-asterisk::after{
                        content: '*';
                        color:$az_maroon;
                    }
                }
            }
        }

        .rotating_azuga_loader {
            height: 200px;
          }
        
          .required_field {
            &:after {
              content: "*";
              color: $az_maroon;
              font-size: 16px;
            }
          }
        
          .borderLayout {
            .required {
              border-left: solid $az_red 6px;
            }
            .filter {
              display: flex;
              align-items: center;
              input[type="text"] {
                border: none;
                padding: 0;
                margin-left: 15px;
              }
            }
            .checkList {
              display: flex;
              flex-wrap: wrap;
              height: 100px;
              overflow: hidden;
              margin: 0 -10px;
              &:hover {
                overflow-y: auto;
              }
              .checkItem {
                padding: 5px 10px;
                width: 33.333%;
                // .input_icon {
                //   //min-width: 25px;
                // }
                .input_label {
                  max-width: 100%;
                  .input_title {
                    max-width: 100%;
                    font-size: 12px;
                    line-height: 1.25;
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }
            }
            .col.l4 {
              flex: 50%;
              max-width: 50%;
              .checkList {
                .checkItem {
                  width: 33.333%;
                }
              }
            }
            .col.l8 {
              flex: 50%;
              max-width: 50%;
            }
          }
        
          .dialogFooter {
            border-top: none !important;
          }
        
          .descSection {
            margin-bottom: 5px;
            .l4 {
              display: flex;
              align-items: center;
              flex: 0 0 50%;
            }
            .l8 {
              flex: 0 0 50%;
            }       
            .emailWindow {
              margin-top: 10px;
            }
          } 
        
          .list {
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 0px;
            font-size: 14px;
          }
        
          .fly_toggle {
            display: none;
          }
        
          .azTitle {
            display: inline-block;
          }
    }
}
#disableUserAction{
    height: 100%;
    background-color:transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}
