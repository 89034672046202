.sync-status{
    position: relative;
    min-height: 20px;
    .cloud{
        position: absolute;
    }
    .sync-arrow{
        position: absolute;
        left:5px;
    }
    .sync-arrow-animation{
        animation: infinite 2s rotate ease-in-out;
    }
    @keyframes rotate {
        from{
            transform: rotate(0deg);
        }
        to{
            transform: rotate(360deg);
        }
        
    }
}