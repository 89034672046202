.tagMapping {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	.title {
		margin-bottom: 0px;
		display: flex;
		border-bottom: 3px solid #f6f6f6;
		align-items: center;
	}
	.module_list {
		display: flex;
	}
	.tag_module {
		margin-left: 30px;
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;
		padding-bottom: 15px;
		margin-right: 30px;
		&:hover {
			cursor: pointer;
		}
	}
	.list_item {
		display: flex;
	}
	.active {
		border-bottom: 3px solid #343741;
	}
	.first {
		margin-left: 20px;
	}
	.tags-header {
		display: flex;
		padding: 8px 0px;
		min-width: 48%;
		align-items: center;
		transition: all 0.35 ease;
		background-color: #343741;
		padding-top: 10px;
		padding-left: 25px;
		height: 37px;

		.tags-header-item {
			padding: 8px 0px;
			color: $az_gray_5;
			font-size: 13px;
			font-weight: bold;
			width: 100%;
			color: #ffffff;
		}
	}

	.tag {
		display: flex;
		padding: 4px 0px;
		min-width: 48%;
		align-items: center;
		transition: all 0.35 ease;
		padding-left: 25px;
		border-bottom: 3px solid #f6f6f6;
		margin: 2px;
		max-height: 47px;
		&:hover {
			cursor: pointer;
			box-shadow: none;
			background: transparent;
			position: relative;
			border-radius: 8px;
			box-shadow: 0 0 0 1.5px $az_blue, 0 4px 4px 0 rgba(0, 0, 0, 0.14),
				0 7px 10px -5px rgba(0, 0, 0, 0.25);
		}
		.actionsHover {
			display: flex;
			align-items: center;
			justify-content: center;
			@include opacity(0);
			transition: all 0.25s;
			transform: translateX(10px);
			color: $az_cyan;
			margin-right: 20px;
			min-width: 90px;
			.disabled {
				color: $az_gray_4;
			}
			svg {
				& ~ svg {
					margin-left: 15px;
				}
			}
		}
		&:hover {
			.actionsHover {
				transform: translateX(0);
				@include opacity(1);
			}
		}
		.tag-item {
			padding: 8px 0px;
			font-size: 13px;
			width: 100%;
			background-color: #ffffff;
			position: relative;
		}
		.tagName {
			min-width: 40%;
		}
		.inactive {
			color: #888888;
			font-style: italic;
		}
		.input-field {
			padding-top: 0px;
			padding-bottom: 0px;
			.formRow {
				margin-bottom: 0px;
			}
		}
		.addEdit {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 20px;
			min-width: 90px;
			.cancel {
				margin-left: 10px;
				height: 30px;
			}
		}
	}
	.error_container {
		max-height: 90px;
	}
	.tags_header_container {
		display: flex;
		margin-top: 10px;
		position: relative;
		justify-content: space-between;
		&::before {
			content: "";
			height: 40px;
			top: 0;
			transform: translateX(-65%);
			left: 50%;
			position: absolute;
			margin: 0px;
			background: #eeeeee;
			width: 3px;
			border-radius: 3px 3px 0 0;
		}
	}
	.tags_list_container {
		display: flex;
		margin-top: 2px;
		flex-wrap: wrap;
		justify-content: space-between;
		min-height: 50vh;
		max-height: 50vh;
		overflow-y: auto;
		align-content: flex-start;
	}
	.tagAdd {
		display: flex;
		align-items: flex-start;
		.switch {
			margin-right: 30px;
			font-weight: 500;
			.switchBool {
				align-items: flex-start;
				min-width: 55px;
			}
			.input_icon {
				bottom: 5px;
			}
		}
		.input_title {
			margin-top: 0px !important;
		}
		.tag_add {
			position: relative;
			bottom: 7px;
			.disabled {
				background-color: $az_gray_4 !important;
			}
		}
	}
	.color {
		margin-left: 20%;
	}
	.color-header {
		margin-left: calc(40% - 110px);
	}
	.color-header-right {
		margin-left: calc(40% - 126px);
	}
	.color-picker {
		position: absolute;
		z-index: 10000;
	}
	.bottom-align {
		top: 32px;
	}
	.left-align {
		right: 100%;
	}
	.right-align {
		left: 20px;
	}
	.top-align {
		bottom: 100%;
	}
	.no-data-available {
		position: absolute;
		left: 45%;
		text-align: center;
		top: 50%;
	}
	.ref-container {
		min-width: calc(48% + 4px);
		margin-right: 9px;
	}
	.disabled {
		color: $az_gray_4;
	}
}
