.customeFields {
	padding: 20px 15px !important;
	&.groupCustomFieldsSelection {
		> .formSection {
			position: relative;
			z-index: 9;
		}
	}
	& ~ div {
		padding: 0 !important;
	}
	.formSection {
		padding: 0 !important;
	}
	.table_wrap {
		padding: 0 !important;
		margin-right: 1%;
		overflow: visible;
		border: solid 1px $az_gray_3;
		.thead,
		.tbody {
			padding: 0 10px;
			.tr {
				cursor: default !important;
				.td,
				.th {
					padding: 15px;
					flex: 0 0 15%;
					.azAlert:not([hidden]) {
						max-width: 150px;
						overflow: hidden;
						display: inline-block;
						text-overflow: ellipsis;
					}
					&:first-child {
						flex: 0 0 20%;
						.cell_in {
							margin-left: -15px;
							transition: all 0.25s;
						}
					}
					&:nth-child(2) {
						flex: 0 0 15%;
					}
					&:nth-child(5) {
						flex: 0 0 15%;
					}
					&:last-child {
						flex: 0 0 5%;
					}
					&:nth-child(6) {
						flex: 0 0 15%;
					}
					.react-select-container {
						min-width: 0;
					}
					.formRow {
						margin-bottom: 0;
					}
					.flexIt {
						input {
							width: auto;
						}
						> * {
							& ~ * {
								margin-left: 10px;
							}
						}
					}
				}

				&:not(.addField) {
					&:hover {
						> .td {
							&:first-child {
								.cell_in {
									margin-left: 0;
								}
							}
						}
					}
				}
			}
			form.tr {
				.td,
				.th {
					&:nth-child(6),
					&:last-child {
						flex: 0 0 10%;
					}
					p {
						width: 100%;
						input {
							min-width: 100%;
						}
					}
				}
			}
			.tr .tr {
				border: none;
				z-index: 3;

				&:before {
					box-shadow: none;
				}
			}
			form {
				.td,
				td {
					.cell_in {
						overflow: visible !important;
						.divideView_items {
							width: 100% !important;
						}
					}
				}
			}
		}
		.thead {
			.tr {
				padding-right: 1%;
				border: 0 !important;
			}
			background-color: $az_gray_6;
			.th {
				padding: 15px !important;
				p {
					color: $az_white !important;
				}
			}
		}
		.tbody {
			max-height: 350px !important;

			> div:nth-child(3) ~ div:not(.tr) {
				.addField {
					.react-select-container {
						.react-select__menu {
							bottom: 100%;
							top: auto;
						}
					}
				}
			}
			.addField {
				.react-select-container {
					.react-select__menu {
						.react-select__menu-list {
							max-height: 150px;
						}
					}
				}
			}
			.tr {
				border-bottom: 2px solid $az_gray_1;
			}
		}
		.table {
			.tr {
				position: relative;
			}
		}
	}
	&.groupCustomFields {
		// margin-top: 50px;
		.table_wrap,
		.group_header {
			margin-bottom: 25px;
		}
	}
}
body .customeFields .table_wrap:not(.noscroll) .tbody {
	max-height: 275px;
	&::-webkit-scrollbar {
		-webkit-appearance: none;
	}

	&::-webkit-scrollbar:vertical {
		width: 11px;
	}

	&::-webkit-scrollbar:horizontal {
		height: 11px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 8px;
		border: 2px solid white;
		background-color: rgba(0, 0, 0, 0.5);
	}

	&::-webkit-scrollbar-track {
		background-color: #fff;
		border-radius: 8px;
	}
}

[hidden] {
	display: none;
}
