.tabs {
  .tabList {
    position: relative;
    display: flex;
    .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 15px;
      background: $az_faded_blue;
      color: #fff;
      cursor: pointer;
      font-weight: bold;
      &:first-child {
        border-radius: 3px 0 0 0;
      }
      &:last-child {
        border-radius: 0 3px 0 0;
      }

      &.active {
        color: $az_cyan;
        background: $az_gray_1;
      }
    }
    .underline {
      position: absolute;
      :not(.leftTabs) &,
      :not(.rightTabs) & {
        top: auto !important;
        height: 3px !important;
      }
    }
  }

  &.stickyTabs {
    &:not(.leftTabs),
    &:not(.rightTabs) {
      height: 400px;
      .tabList {
        height: 40px;
        .tab {
          padding: 0 15px;
        }
      }
      .tabPanels {
        height: 360px;
        overflow: auto;
      }
    }
  }
  &.leftTabs,
  &.rightTabs {
    display: flex;
    .tabList {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      margin-right: 50px;
      .tab {
        background: transparent;
        color: inherit;
        font-weight: normal;
        text-align: left;
        position: relative;
        &:before {
          content: "";
          width: 0;
          top: 0;
          bottom: 0;
          left: 0;
          transform: scaleY(0);
          background: $az_cyan;
          position: absolute;
          width: 4px;
          margin: auto;
          height: 80%;
        }
        &.active {
          &:before {
            transform: scaleY(1);
          }
        }
        .underLine {
          position: absolute;
          left: auto !important;
          width: 3px !important;
        }
      }
    }
    .tabPanels {
      flex-grow: 1;
    }
  }
}

.straight-tabs {

  .tabs .tabList {
    border-bottom: 3px solid $az_gray_2;

    .tab {
      color: $az_black;
      background: $az_white;
      min-width: 100px;
      
      &.active {
        color: $az_black;
        background: $az_white;
        border-bottom: 3px solid $az_faded_blue;
      }
    }

  }

  &.slot-tabs {
    width: 99%;
    position: relative;

    .tabs .tabList {
      border-bottom: 1px solid #eeeeee;
      overflow: auto;

      &.scrollable {
        width: 91%;
        margin: auto;
      }
      
      .tab {
        background: $az_white;
        font-weight: 500;
        margin-top: 10px;
        margin-right: 10px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        color: #0069B1;
        
        &.active {
          color: $az_white;
          background: #0069B1;
          border-bottom: 1px solid $az_faded_blue;
        }
      }

    }

    .tabs .tabPanels {
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
      border-radius: 0 0 4px 4px;
      padding: 15px;
      height: auto;
    }
  }

  .scrollBtn {
    min-width: 30px;
    position: absolute;
    margin-top: 10px;
    padding: 5px;
    z-index: 5;
    border-radius: 3px 3px 0 0;
    background: $az_white;
    color: #0069b1;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);

    svg {
      height: 20px;
      width: 20px;
    }
    
    &.left {
      left: 0;
    }
    
    &.right {
      right: 0;
      top: 0;
      box-shadow: -10px 0 6px 0 rgba(0, 0, 0, 0.25);
    }
  }
}