body {
	.table_wrap {
		// overflow: auto;
		position: relative;
		color: $az_black;
		background: $az_white;
		border-radius: 5px;
		padding: 25px 5px !important;
		margin: 0 -30px;
		width: calc(100% + 60px);
		max-width: calc(100% + 60px);
		&:not(.noscroll) {
			thead,
			.thead {
				// overflow-y: scroll;
				// overflow-x: auto;
				display: block;
				overflow: auto;
			}
			tbody,
			.tbody {
				max-height: 375px;
				min-height: 150px;
				overflow: auto;
				display: block;
			}
		}
		table,
		.table {
			width: 100%;
			margin: 0;
			tr,
			.tr {
				display: flex;
				flex: 1 0 auto;
				min-width: 0px;
				td,
				.td,
				th,
				.th {
					min-width: 0px;
					width: auto !important;
					flex: 1 0 auto;
					.cell_in {
						font-size: 13px;
						margin-left: 0;
					}
					p,
					span {
						line-height: 1.5;
					}
				}
			}
			thead,
			.thead {
				padding: 0 30px;
				position: relative;
				display: block;
				tr,
				.tr {
					border-bottom: 3px solid $az_gray_1;
					th,
					.th {
						padding-bottom: 20px;
						width: auto !important;
						font-weight: 700;
						.cell_in {
							display: flex;
							align-items: center;

							font-size: 13px;
							.sort_icon {
								width: 15px;
								line-height: 1;
								margin-left: 10px;
								svg {
									height: 12px;
									width: 12px;
								}
								&.sortable {
									display: flex;
									justify-content: center;
									flex-direction: column;
									svg {
										~ svg {
											margin-top: -7px;
										}
									}
								}
							}
						}
					}
				}
			}
			tbody,
			.tbody {
				position: relative;
				display: block;
				padding: 5px 30px;
				tr,
				.tr {
					position: relative;
					cursor: pointer;
					> * {
						position: relative;
						z-index: 2;
					}
					&:before {
						content: "";
						z-index: 0;
						pointer-events: none;
						position: absolute;
						left: -20px;
						right: -20px;
						top: 0;
						bottom: 0;
						border-radius: 8px;
						box-shadow: 0 0 0 1.5px $az_blue, 0 4px 4px 0 rgba(0, 0, 0, 0.14),
							0 7px 10px -5px rgba(0, 0, 0, 0.25);
						transform: scale(1) translateZ(0);
						@include opacity(0);
					}
					&:hover {
						&:before {
							@include opacity(1);
						}
						.drag {
							.drag_icon {
								transform: translateX(-100%) scale(1) !important;
							}
						}
					}
					& + .loading {
						.skell {
							height: 50px !important;
						}
					}
					&.loading {
						display: flex;
						flex: 1 0 auto;
						min-width: 0px;
						text-align: center;
						cursor: auto;
						&::before {
							content: none;
						}
						td,
						.td {
							text-align: center;
							font-size: 16px;
							padding: 25px;
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							.cell_in {
								&:after {
									content: "";
									animation: dots 1s infinite;
								}
							}
						}
					}
					&.error {
						td,
						.td {
							.cell_in {
								color: $az_red;
								font-size: 18px;
								&::after {
									content: none;
									animation: none;
								}
							}
						}
						& + .loading {
							display: none !important;
						}
					}
				}
			}
			tr,
			.tr {
				td,
				.td,
				th,
				.th {
					text-align: left;
					color: $az_black;
					border: 0;
					padding: 10px 15px;
					user-select: none;
					font-size: 13px;
					.cell_in {
						display: block;
						line-height: 1.25;
						display: block;
						max-width: 100%;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;

						&.drag {
							position: relative;
							overflow: visible;
							> span:not(.drag_icon) {
								display: block;
								line-height: 1.25;
								display: block;
								max-width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
							.drag_icon {
								cursor: grab;
								position: absolute;
								left: -7px;
								transform: translateX(-100%) scale(0);
								top: -2px;
								bottom: 0;
								margin: auto;
								transition: all 0.25s;
							}
						}
					}
				}
				.th,
				th {
					.cell_in {
						color: $az_gray_5;
					}
				}
				.actionsHover {
					position: absolute;
					right: 0;
					top: 0;
					bottom: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					@include opacity(0);
					transition: all 0.25s;
					transform: translateX(10px);
					svg {
						& ~ svg {
							margin-left: 15px;
						}
					}
					.button {
						margin-left: -15px;
					}
				}
				&:hover {
					.actionsHover {
						transform: translateX(0);
						@include opacity(1);
						right: 10px;
					}
				}
			}
		}
		// tbody {
		//   tr {
		//     th,
		//     td {
		//       min-width: 0px;
		//       width: auto !important;
		//       flex: 1 0 auto;
		//     }
		//   }
		// }
	}
	.ReactTable {
		border: none;
		.rt-tr-group {
			padding: 0 5px;
		}
		.rt-thead {
			border-bottom: 3px solid $az_gray_1;
			box-shadow: none !important;
			.rt-tr {
				padding: 0 5px;
			}
		}
		.rt-tbody {
			padding: 5px 0;
		}
		.rt-tbody,
		.rt-thead {
			.rt-tr.-odd {
				background: #fff;
			}
			.rt-td,
			.rt-th {
				color: $az_black;
				border: 0;
				padding: 12px;
				font-size: 13px;
			}
			.rt-th {
				font-weight: 500;
				color: $az_gray_5;
				text-align: left;

				&.-cursor-pointer {
					padding-right: 30px;
					.rt-resizable-header-content {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						&:after {
							content: "";
							display: inline-block;
							height: 30px;
							width: 10px;
							margin: 2px 0 0 20px;
							background: url(/icons/downarrow_black.svg) center
								no-repeat;
							background-size: 10px;
						}
					}
					&.-sort-asc,
					&.-sort-desc {
						border: none;
						background: $az_gray_1;
						box-shadow: none;
						&:after {
							content: "";
							height: 3px;
							position: absolute;
							right: 0;
							top: 0;
							left: 0;
							background: $az_cyan;
						}
						&:before {
							background-image: url(/icons/downarrow_blue.svg);
						}
					}
					&.-sort-desc {
						&:before {
							transform: rotate(180deg);
						}
					}
				}
			}
		}
		// &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
		//   cursor: pointer;
		//   box-shadow: none;
		//   background: transparent;
		//   position: relative;
		//   border-radius: 8px;
		//   box-shadow: 0 0 0 1.5px $az_blue, 0 4px 4px 0 rgba(0, 0, 0, 0.14),
		//     0 7px 10px -5px rgba(0, 0, 0, 0.25);
		// }
	}
	.Table__pagination {
		display: flex;
		margin: 10px;
		justify-content: center;
		align-content: center;
		button {
			background: transparent;
			color: $az_cyan;
			padding: 0;
			min-width: 0;
			width: 35px;
			display: flex;
			justify-content: center;
			align-content: center;
		}
	}
	.Table__visiblePagesWrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		button {
			margin: 0 10px;
			border: solid 1px #ddd;
			color: #333;
			padding: 0;
			width: 35px;
			display: flex;
			justify-content: center;
			align-content: center;
			&.Table__pageButton--active {
				background: $az_cyan;
				color: #fff;
				border-color: $az_cyan;
			}
		}
	}

	.ReactTable {
		.rt-table {
			display: table;
			.rt-thead {
				display: table-header-group;
			}
			.rt-tbody {
				display: table-row-group;
				.rt-tr-group {
					display: contents;
					z-index: 0;
					position: relative;
					&:hover {
						z-index: 1;
					}
				}
				.rt-tr {
					background: transparent;
					&:hover {
						cursor: pointer;
						box-shadow: none;
						background: transparent;
						position: relative;
						border-radius: 8px;
						box-shadow: 0 0 0 1.5px $az_blue, 0 4px 4px 0 rgba(0, 0, 0, 0.14),
							0 7px 10px -5px rgba(0, 0, 0, 0.25);
					}
				}
			}
			.rt-tr {
				display: table-row;
				z-index: 0;
				position: relative;
				&:hover {
					z-index: 1;
				}
				.rt-th,
				.rt-td {
					padding: 10px;
					min-width: 0 !important;
					width: auto !important;
					max-width: none !important;
					display: table-cell;
				}
			}
		}
	}
}

@keyframes dots {
	0% {
		content: ".";
	}
	40% {
		content: "..";
	}
	75% {
		content: "...";
	}
	100% {
		content: "";
	}
}

.ellipsis {
	display: block;
	line-height: 1.25;
	display: block;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
