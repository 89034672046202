#filters {
  .filter_group {
    margin-bottom: 40px;
    .filter_group_title {
      font-weight: bold;
      margin-bottom: 15px;
      font-size: 17px;
    }
  }
  .filter_item {
    margin-top: 10px;
    label {
      font-size: 13px;
      color: $az_cyan;
    }
  }
}
