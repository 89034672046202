.dialogOverlay_addJobs {
	.dialogHeader {
		.flexIt {
			width: 70%;
		}
		.formRow .formRow_key {
			font-size: 28px;
			font-weight: bold;
			line-height: 1.5;
			display: block;
			color: $az_black;
			margin-top: 0;
			width: auto;
			flex: unset;
		}
	}

	.dialogHeader .formRow {
		/* margin: 20px 0; */
		align-items: center;
		width: 100%;
	}

	.dialogHeader .formRow .formRow_val {
		width: auto !important;
	}

	.dialogHeader .formRow .formRow_val input {
		width: 250px;
		font-size: 20px;
		line-height: 1.5;
		display: block;
		height: 34px;
		@include input-placeholder-font(rgba($az_black, 0.3), $fontSize: 18px);
	}
	.hrs {
		width: 45px;
	}

	.mins {
		width: 45px;
	}

	.groupItem_gap {
		@include respondToBelow(s) {
			margin-left: 5px !important ;
		}
	}

	.slick-slider {
		@include respondToBelow(sm) {
			max-width: initial;
		}
	}
	.invisibleSection {
		display: none;
	}
	.back_button {
		margin-right: auto;
		svg {
			margin-right: 0;
		}
	}
	.divideView_items {
		.group {
			input {
				min-width: 50px;
				border-radius: 3px;
			}
		}
	}
	.step_2 {
		.dialogFooter.checkLine {
			.selectionControl.checkbox {
				min-height: 30px;
			}
		}
		.formRow.formRow--flex_row .formRow_key {
			width: 30%;
		}

		.multiDayTabData {
			.formRow.formRow--flex_row .formRow_key {
				width: 10%;
			}

			.react-datepicker-wrapper input[type="text"] {
				border-radius: 5px;
			}
		}

		.repeat,
		.ends {
			.group {
				.groupItem {
					flex: 1 0 0;
					&:first-child:not(:only-child) {
						flex: 0 0 35%;
					}
				}
			}
		}
		.estDuration {
			.group {
				label.groupItem {
					flex: 0 0 35%;
				}
			}
		}
		> .dialogFooter {
			.formRow_actions {
				justify-content: flex-start;
				.selectionControl {
					width: calc(50% - 30px / 2);
					margin-left: auto;
				}
			}
		}
		.group {
			.groupItem {
				input {
					width: 100%;
				}
				.formRow {
					width: 100%;
					.formRow_val {
						width: 100%;
						.react-select-container {
							width: 100%;
							min-width: auto;
						}
					}
				}
				&.groupItem_gap {
					margin-left: 10px;
				}
			}
			&.group_1 > .groupItem {
				max-width: 50%;
				input {
					min-width: 35px;
				}
			}
		}

		.radioFormRow {
			> .formRow_val {
				@include respondToAbove(md) {
					width: calc(60% + 35px) !important;
					margin-left: -35px;
				}
				.group {
					> .groupItem {
						margin: 0;
						&:nth-child(1) {
							width: 35px;
						}
						&:nth-child(2) {
							// width: calc(50% - 45px / 2);
							& ~ .groupItem {
								margin-left: 10px;
							}
						}
						&:last-child {
							width: calc(50% - 45px / 2);
						}
					}
				}
				.radio .input_label .input_icon {
					margin: 0;
				}
			}
			// & ~ .radioFormRow {
			//   > .formRow_val {
			//     .group {
			//       > .groupItem {
			//         margin: 0;
			//         &:nth-child(1) {
			//           width: 35px;
			//         }
			//         &:nth-child(2) {
			//           width: calc(25% - 45px / 3);
			//         }
			//         &:nth-child(3) {
			//           width: calc(25% - 45px / 3);
			//         }
			//         &:nth-child(4) {
			//           width: calc(50% - 45px / 3);
			//         }
			//       }
			//     }
			//   }
			// }
		}
		.formRow.divideView_items_full.servicedays {
			@include respondToAbove(md) {
				.formRow_key {
					width: 19%;
				}
				.formRow_val {
					width: 81%;
				}
			}
		}
	}
	.dialogFooter.checkLine {
		border: none;
		width: 100%;
		margin: 0;
		padding: 0;
	}
	.dialogFooter.isJobsNewUI {
		position: sticky;
		bottom: -20px;
		background: $az_white;
		padding: 5px 40px;
	}
	.slotList .slot-tabs .tabPanel .divideView {
		box-shadow: none;
		margin-top: 0;

		.t-Right {
			text-align: right;
		}
	}
	.slotList {
		.divideView {
			margin-top: 10px;
			margin-left: -10px;
			margin-right: -10px;
			white-space: nowrap;
			padding: 10px;
			border-radius: 3px;
			box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
			> .group {
				> .groupItem {
					&,
					.formRow_key,
					.date {
						font-size: 13px;
					}
					.formRow_val {
						width: 60%;
					}
					.date {
						color: $az_gray_5;
						letter-spacing: 0;
					}
					&:nth-child(1),
					&:nth-child(3) {
						width: 35%;
					}
				}
				&.third {
					> div {
						width: 65% !important;
						flex: unset;
						.formRow_val {
							min-width: 0;
							margin-left: 15px;
						}
						~ div {
							margin-left: 25px;
						}
						&:nth-child(1) {
							width: 15% !important;
						}
						&:nth-child(2) {
							width: 20% !important;
						}
					}
				}
			}
		}
		.formRow {
			flex-wrap: nowrap;
			input {
				min-width: none;
			}
		}
	}
	.recurringSchedule {
		margin-top: 15px;
		.divideView {
			flex-wrap: nowrap !important;
			> div {
				&:first-child {
					white-space: nowrap;
					margin-top: 5px;
					width: auto !important;
				}
				&.divideView_items_full {
					margin-left: 15px !important;
					flex-wrap: wrap;
					.groupItem {
						margin-bottom: 5px;
						margin-right: 5px;
						margin-left: 0 !important;
					}
				}
			}
		}
		.tag {
			align-items: center;
			background-color: #e5e5e5;
			border-radius: 4px;
			color: #000000;
			display: inline-flex;
			font-size: 0.75rem;
			height: 2em;
			justify-content: center;
			line-height: 1.5;
			padding-left: 0.75em;
			padding-right: 0.75em;
			white-space: nowrap;
			.is-small.delete {
				height: 16px;
				max-height: 16px;
				max-width: 16px;
				min-height: 16px;
				min-width: 16px;
				width: 16px;
			}
			.delete {
				-moz-appearance: none;
				-webkit-appearance: none;
				background-color: transparent;
				border: none;
				border-radius: 290486px;
				cursor: pointer;
				pointer-events: auto;
				display: inline-block;
				flex-grow: 0;
				flex-shrink: 0;
				font-size: 20px;
				height: 20px;
				max-height: 20px;
				max-width: 20px;
				min-height: 20px;
				min-width: 20px;
				outline: 0;
				position: relative;
				vertical-align: top;
				width: 20px;
			}
			.delete::before {
				height: 2px;
				width: 50%;
			}
			.delete::after {
				height: 55%;
				width: 2px;
			}

			.delete::after,
			.delete::before {
				background-color: #000000;
				content: "";
				display: block;
				left: 50%;
				position: absolute;
				top: 50%;
				transform: translateX(-50%) translateY(-50%) rotate(45deg);
				transform-origin: center center;
			}
		}
	}
}

.multiday {
	.serviceDates {
		margin-top: -30px;
	}
	.divideView_items.divideView_items_full.team_worker {
		.formRow_key {
			width: 19%;
			max-width: 19%;
		}
		.formRow_val {
			.group.group_hsplit {
				justify-content: flex-start;
			}
			.lbl {
				font-size: 11px;
			}
		}
	}
	.slotList {
		margin-bottom: -30px;
		~ .scrollBar {
			box-shadow: 0 -6px 15px -3px rgba(0, 0, 0, 0.15);
			display: block;
			height: 30px;
			left: -30px;
			right: -30px;
			margin-bottom: 0;
			position: absolute;
			bottom: -50px;
		}
		max-height: 30vh;
		overflow: auto;
		width: calc(100% + 40px);
		padding: 0 30px 30px;
		margin-left: -20px;
		margin-right: -20px;
		&.upper {
			padding: 0;
			margin: 0;
			margin-bottom: 10px;
			max-height: none;
			overflow: unset;
			width: 100%;
		}

		> div {
			> .group {
				> .groupItem {
					& ~ .groupItem {
						margin-left: 25px;
					}
				}
			}
		}
	}
}

.step_2.multiday {
	.slotList {
		> .divideView {
			margin-top: 5px;
			& ~ .divideView {
				margin-top: 15px;
			}
			&.false {
				padding-bottom: 0;
			}
			.workerString {
				display: inline-block;
				line-height: 1.5;
				white-space: pre-wrap;
				font-size: 13px;
				letter-spacing: 0px;
			}
			&.disabled {
				background: #eeeeee;
				.file-custom[type="text"][disabled],
				.file-custom[type="password"][disabled],
				.file-custom[type="number"][disabled],
				.file-custom[type="email"][disabled],
				.file-custom[type="date"][disabled],
				.file-custom[type="url"][disabled],
				.file-custom[type="time"][disabled],
				.file-custom.input[disabled],
				input[type="text"][disabled],
				input[type="password"][disabled],
				input[type="number"][disabled],
				input[type="email"][disabled],
				input[type="date"][disabled],
				input[type="url"][disabled],
				input[type="time"][disabled],
				input.input[disabled] {
					background: #fff;
				}

				.workerString.disabled {
					padding: 5px 10px;
					background: $az_white;
					border-radius: 3px;
				}
			}
			position: relative;
			.divideView_items {
				&.gapped {
					padding-right: 100px;
				}
				.lbl {
					font-size: 13px;
					letter-spacing: 0;
					white-space: pre-wrap;
				}
				.img {
					min-width: 16px;
				}
				.react-select-container {
					min-width: 100px;
				}
				&.floatAir {
					position: absolute;
					right: 10px;
					top: 15px;
					width: 20px !important;
					z-index: 9;
					&.deleteBin {
						right: 35px;
					}
					&.toggleArrow {
						.actions {
							height: 20px;
							width: 20px;
							display: flex;
							align-items: center;
							justify-content: center;
							background: $az_cyan;
							color: #fff;
							border-radius: 10px;
							transition: all 0.25s;
							&.up {
								transform: rotate(180deg);
							}
						}
					}
				}
			}
		}
		.add-worker-container {
			margin-top: -15px;
			.formRow {
				align-items: flex-start;
				margin: 0;
				.formRow_key {
					align-items: center;
					margin-top: 12px;
					max-width: 17%;
				}
				.formRow_val_label {
					white-space: pre-wrap;
					line-height: 1.3;
				}
			}
			.addingSection {
				&.tagListItem {
					padding: 0;
					height: 25px;
					width: 25px;
					display: flex;
					align-items: center;
					justify-content: center;
					button {
						color: $az_cyan;
						height: 18px;
						width: 18px;
					}
				}
			}
		}
	}
}
