.WFMConfig {
	padding: 0 !important;
	margin: 0 -25px;
}
.worker-availability {
	.listTable {
		.deviderComp--vertical {
			margin: 0 10px !important;
		}

		.listTableHead {
			border: 0;
			.grid-item-header {
				color: $az_white;
				& ~ .grid-item-header {
					padding: 8px;
				}
			}
			background-color: $az_gray_6;
		}
	}
	.work-calculation-method {
		display: flex;
		align-items: baseline;
		margin-bottom: 0;
		padding-left: 25px;
	}

	#workforce_key {
		color: $az_black;
	}

	#workforce_value {
		width: 250px;
	}

	.sub-note {
		font-size: 12px;
		// width: 40%;
		margin: 0;
		padding-left: 25px;
		padding-right: 25px;
	}

	.lead-run-time {
		display: flex;
		padding-left: 25px;
		padding-top: 25px;
		align-items: center;
	}
	.utilize_rates_with_skills {
		display: flex;
		padding-left: 35px;
		align-items: center;
		position: relative;
		padding-top: 5px;
		animation: reveal 0.25s;
		transform-origin: top center;
		&::before {
			content: "";
			border: dashed 1px $az_blue;
			border-width: 0 0 1px 1px;
			border-width: 0 0 1px 1px;
			position: absolute;
			width: 25px;
			height: 20px;
			left: 30px;
			bottom: 10px;
		}
	}

	.heading-addButton {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 25px;
		padding-right: 25px;
	}

	.workforce-add-button {
		line-height: 0;
		margin: 0;
	}

	.grid-container {
		display: flex;
		padding: 8px 0px;
		width: 100%;
		align-items: center;
		transition: all 0.35 ease;
		&.draggable:hover {
			box-shadow: 0 0 0 2px $az_cyan;
			border-radius: 6px;
			padding-right: 24px;
			position: relative;
		}

		.grid-item-header {
			padding: 8px 0px;
			color: $az_gray_5;
			font-size: 13px;
			font-weight: 600;
			width: 100%;
		}
		.grid-item {
			padding: 8px;
			font-size: 12px;
			width: 100%;
		}

		.workforce-availability-actions {
			visibility: hidden;
			transition: all 0.35s ease;
			@include opacity(0);
		}
		&.draggable:hover .workforce-availability-actions {
			display: block;
			cursor: pointer;
			padding: 0px 8px;
			font-size: small;
			visibility: visible;
			@include opacity(1);
		}
		&.draggable:active .workforce-availability-actions {
			display: block;
			cursor: pointer;
			padding: 0px 8px;
			font-size: small;
			visibility: visible;
			@include opacity(1);
		}
		.drag_icon {
			visibility: hidden;
			transition: all 0.35s ease;
			@include opacity(0);
		}

		&.draggable:hover .drag_icon {
			display: block;
			cursor: grab;
			padding: 0px 8px;
			font-size: small;
			visibility: visible;
			@include opacity(1);
			margin-left: 24px;
		}

		&.draggable:active .drag_icon {
			display: block;
			cursor: pointer;
			padding: 0px 8px;
			font-size: small;
			visibility: visible;
			@include opacity(1);
			margin-left: 24px;
		}
		&.advanced {
			padding: 8px 18px;
		}
		&.advanced:hover {
			@include box-shadow;
			border: solid $az_cyan 2px;
			border-radius: 6px;
			// padding: 8px 24px;
		}
		&.advanced:hover .workforce-availability-actions {
			display: block;
			cursor: pointer;
			padding: 0px 8px;
			font-size: small;
			visibility: visible;
			@include opacity(1);
		}
	}
}

@keyframes reveal {
	from {
		opacity: 0;
		transform: scaleY(0);
	}
	to {
		opacity: 1;
		transform: scaleY(1);
	}
}
