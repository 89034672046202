.schedule-grid-view-month{
    overflow-x: scroll;
    max-height: 65vh;
    background-color: $az_white;
    scroll-behavior: smooth;
    .grid-table {
		position: relative;
        border-collapse: collapse;
		margin-top:unset;
        table-layout: fixed;
        width:100%;
        thead th {
			position: -webkit-sticky; /* for Safari */
            position: sticky;
			top: 0;
		}
		// thead th:first-child {
		// 	left: 0;
		// 	z-index: 11009;
		// }
		tbody th {
			position: -webkit-sticky; /* for Safari */
			position: sticky;
			left: 0;
        }
        .grid-header-cell {
            color:$az_white;
			background-color: $az_gray_6;
            z-index: 11007;
            border-bottom: 2px solid $az_gray_2;
            border-right: 2px solid $az_gray_2;
			.header-container{
				min-height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
			}
        }
        .grid-cell{
            min-height: 150px;
            border-right: 2px solid $az_gray_2;
            cursor: pointer;
            .grid-data-container{
                border-radius: 5px;
                border: 2px solid $az_white;
                min-height: 150px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .add-button{
                    position: absolute;
                    color:$az_cyan;
                    height: auto;
                    width: auto;
                    right:8px;
                    top:17px;
                }
            }
            .highlight-today{
                width: 27px;
                height: 27px;
                background-color: $az_gray_4;
                border-radius: 15px;
                align-self: center;
            }
        }


    }

}