.emailPill {
	body {
		background-color: #eaeaea;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;
	}

	.wrapper {
		background-color: white;
		width: 400px;
		padding: 2rem;
		box-shadow: 0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.1);
		border-radius: 0.3rem;
	}

	.input {
		width: 100%;
		height: 35px;
		padding: 0 1rem;
		//margin-top: 1rem;
		box-sizing: border-box;
		font: inherit;
		//border-radius: 0.2rem;
		//border: 2px solid #d4d5d6;
		border: 0px;
		color: #565656;
		-webkit-appearance: none;
		border-radius: 0;
	}

	.bin-input {
		background: transparent;
	}

	.emailList {
		border: 2px solid #d4d5d6;
		border-radius: 5px;
		//border: 2px solid #979797;
		display: flex;
		flex-wrap: wrap;
		max-height: 100px;
		overflow: auto;
		// &:hover {
		//   overflow-y: auto;
		// }
	}

	.required {
		border-left: 6px solid $az_red;
	}

	.input:focus {
		border-color: rgb(39, 66, 117);
		outline: none;
	}

	.error {
		margin: 0;
		font-size: 95%;
		color: #982323;
	}

	.tag-item {
		display: inline-block;
		font-size: 14px;
		border-radius: 30px;
		height: 30px;
		padding: 0 6px 0 1rem;
		display: inline-flex;
		align-items: center;
		margin: 0 0.3rem 0.3rem 0;
		border: 1px;
		border-style: solid;
		border-color: #d4d5d6;
		margin-top: 2px;
		margin-left: 2px;
		.close {
			margin-left: 8px;
			svg {
				width: 13px;
				height: 13px;
			}
		}
	}
	.bin-tag-item {
		margin: 2px !important;
	}
	.normal-radius {
		border-radius: 3px !important;
		background: #eeeeee;
		padding: 4px 7px;
	}

	.tag-item > .button {
		background-color: white;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		border: none;
		cursor: pointer;
		font: inherit;
		margin-left: 10px;
		font-weight: bold;
		padding: 0;
		line-height: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.tagListItems {
		display: flex;
		flex-wrap: wrap;
	}

	.emailInput {
		display: flex;
		align-items: flex-start;
		width: 100%;
	}
	.binInput {
		width: 20% !important;
	}
}
