.invoice-payment {
	max-width: 920px;

	&--customer {
		width: 95%;
		margin: 45px auto;
		background: $az_white;
		padding: 15px;
		border-radius: 6px;
		box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
			0 10px 10px -5px rgba(0, 0, 0, 0.04);
	}

	&__header {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;

		& > .azTitle {
			margin-bottom: 0;
		}
	}

	&__title {
		font-size: 28px;
		font-weight: 900;
		line-height: 1.5;
	}

	&__logo {
		width: 120px;
	}

	&__flex {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__info {
		display: flex;

		@media only screen and (max-width: $screen-sm-min) {
			display: block;
		}
	}

	&__column {
		flex: 1;
		margin-right: 15px;
	}

	&__field {
		display: flex;
		margin-bottom: 3px;
	}

	&__field-label {
		width: 40%;
		min-width: 120px;
		font-weight: 500;
		line-height: 1.5;
	}

	&__field-value {
		width: 100%;
		line-height: 1.5;
	}

	&__select-method {
		width: calc(50% - 30px / 2);
		margin-right: 0 !important;

		@media only screen and (max-width: $screen-sm-jobs) {
			flex-basis: 100%;
		}
	}

	&__table {
		width: 100%;

		.first-column {
			width: 35%;
		}

		th {
			text-align: left;
			font-weight: 600;
			color: $az_gray_6;
			border-bottom: 1px solid $az_gray_2;
		}

		td,
		th {
			width: 13%;
		}
	}

	&__divider {
		height: 1px;
		background: $az_gray_2;
		margin: 10px 0 15px;
	}

	&__subtitle {
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 15px;
	}

	&__action {
		margin-left: auto;
	}

	&__result {
		display: flex;
		padding-top: 5px;

		.col {
			width: 13%;

			line-height: 1;
			font-size: 14px;
			color: $az_gray_4;

			&--space {
				margin-left: auto;
			}

			&--total {
				font-weight: 600;
				color: $az_black;
			}
		}

		.strong {
			font-weight: 500;
		}

		&--line {
			padding-top: 10px;
			border-top: 1px solid $az_gray_2;
		}
	}

	.formRow .formRow_val {
		min-width: 195px !important;
	}

	@media only screen and (max-width: $screen-mid-size) {
		&__table {
			display: none;
		}

		&__result {
			.col {
				min-width: 100px;
				text-align: right;
			}

			&--line {
				margin-top: 24px;
			}
		}
	}

	&__invalid {
		margin-bottom: 10px !important;
	}

	&__error {
		color: $az_maroon;
	}

	&__redirect {
		color: $az_cyan;
		cursor: pointer;
		font-size: 14px;
		align-self: center;
	}

	.button#{&}__btn-back {
		display: flex;
		align-items: center;
		padding-left: 5px;
		padding-right: 10px;
	}

	&__back-icon {
		margin-right: 5px;
	}

	&__alert {
		display: flex;
		color: $az_red;
		margin: 10px 0;
		align-items: center;
		justify-content: center;

		&--success {
			color: $az_green;
		}
	}

	&__alert-text {
		font-size: 17px;
		font-weight: 500;
		margin-left: 10px;
	}
}
