.schedule-live-maps{
    .schedule-google-maps{
        button{
            min-width: unset;
        }
    }
    .animate{
      animation-delay: 2s;
      animation: animate-switch 1s ease-in-out;
    }
    .control-container{
      display: flex;
      align-items: center;
      justify-content: space-between;
      #heat-map-text{
        font-weight: 600;
        font-size: 15px;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color:$az_white;
      }
      .heat-map-checkbox{
        margin-left: 8px;
        .heat-map-check-box{

          &::before{
            border: 1px solid $az_cyan;
          }
        }
      }
    }
}
.place-search-box{
    display: flex;
    align-items: center;
    background: $az_white;
    width: 255px;
    height: 42px;
    padding:8px 12px; 
    border-radius: 8px;
    margin: 16px;
    @include box-shadow;
    opacity: 0.7;
    input{
        width: 100%;
        border:unset;
        font-size: 16px;
        transition: 0.4s all ease-in-out ;
    }
    &:focus-within{
        opacity: 1;
    }

    &.animate{
        animation-delay: 2s;
        animation: animate-search 1s ease-in-out;
    }
    @keyframes animate-search {
        0% {
          opacity: 0;
        }
    
        20% {
          opacity: 0;
        }
    
        40% {
          opacity: 0;
        }
        60% {
          opacity: 0;
        }
    
        100% {
          opacity: 0.7;
        }
        
      }

}