#report-modal-payroll{
    width:55vw;
    min-width: 50vw;
    .payroll-report-modal{
        color: $az_rpt_black;
        transition: 1s all ease-in-out;
        #payroll-report-dialog-body{
        transition: 1s all ease-in-out;

            svg{
                width: unset;
                height: unset;
            }

        }
    }
}