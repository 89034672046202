.rows-bins {
	.add-icon {
		display: flex;
		height: 30px;
		width: 30px;
		border-radius: 20px;
		background: #0069b1;
		align-items: center;
		justify-content: center;

		svg {
			color: white;
		}
	}
	.disabled {
		color: gray !important;
		cursor: none !important;
	}
	.main_component {
		border: 1px solid #c4c4c4;
		border-radius: 3px;
		.header {
			background: #343741;
			color: white;
			height: 35px;
			padding: 11px 25px;
			.required_symbol {
				color: #ff0000;
			}
		}
		.body {
			min-height: 200px;
			padding: 0px 14px;
			.single-row {
				padding: 15px 9px;
				border-bottom: 2px solid #eeeeee;
				.bin-container {
					border: 1px solid grey;
					padding: 4px;
					min-height: 34px;
					border-radius: 5px;
					background: #eee;
					.single-bin {
						border: 0.5px solid grey;
						padding: 3px 8px;
						border-radius: 3px;
						margin: 0px 5px;
						width: fit-content;
						display: inline-block;
						margin-bottom: 5px;
					}
				}
			}
			.single-row:hover {
				.actions {
					display: initial;
					.check {
						svg {
							width: 21px;
							height: 21px;
						}
					}
					.close {
						color: red;
						svg {
							width: 21px;
							height: 21px;
						}
					}
				}
			}
			input {
				border-radius: 3px;
			}
			.view_mode {
				padding: 5px;
			}
			.actions {
				margin-left: 5px;
				display: none;
			}
		}
	}
}
.disabled-bin {
	background: #eee !important;
}




.tax-options-box {
	padding: 5px;
	margin-top:  4px;
	width: 100%;
    max-height: 140px;
    overflow-y: auto;
	background: $az_white;
	border: 1px solid rgba(0, 0, 0, 0.04);
	box-sizing: border-box;
	box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
	border-radius: 3px;
	.taxOption {
		margin: 6px 0px;
		.input_title {
			margin: 0px  !important;
		}
	}
}



.taxRateTable {
	
	padding: "0px 25px !important";
	& ~ div {
		padding: 0 !important;
	}
	.formSection {
		padding: 0 !important;
	}
	.table_wrap {
		padding: 0 !important;
		margin-right: 1%;
		overflow: visible;
		width: 100% !important;
    	margin-left: 0px !important;
		border: solid 1px $az_gray_3;
		.thead,
		.tbody {
			padding: 0 10px;
			.tr {
				cursor: default !important;
				.td,
				.th {
					padding: 15px;
					flex: 0 0 15%;
					.azAlert:not([hidden]) {
						max-width: 150px;
						overflow: hidden;
						display: inline-block;
						text-overflow: ellipsis;
					}
					&:first-child {
						flex: 0 0 20%;
						// .cell_in {
						// 	margin-left: -15px;
						// 	transition: all 0.25s;
						// }
					}
					&:nth-child(2) {
						flex: 0 0 20%;
					}
					&:nth-child(3) {
						flex: 0 0 30%;
					}
					&:last-child {
						flex: 0 0 5%;
					}
					
					.formRow {
						margin-bottom: 0;
					}
					.flexIt {
						input {
							width: auto;
						}
						> * {
							& ~ * {
								margin-left: 10px;
							}
						}
					}
				}

			}
			form.tr {
				.td,
				.th {
					p {
						width: 100%;
						input {
							min-width: 100%;
						}
					}
				}
			}
			.tr .tr {
				border: none;
				z-index: 3;
				
				&:before {
					box-shadow: none;
				}
			}
			form {
				.td,
				td {
					.cell_in {
						overflow: visible !important;
						.divideView_items {
							width: 100% !important;
						}
					}
				}
			}
		}
		.thead {
			.tr {
				padding-right: 1%;
				border: 0 !important;
			}
			background-color: $az_gray_6;
			.th {
				padding: 15px !important;
				p {
					color: $az_white !important;
				}
			}
		}
		.tbody {
			max-height: 350px !important;
			.tr {
				border-bottom: 2px solid $az_gray_1;
				
				&:first-child {
					flex: 0 0 20%;
					// .cell_in {
					// 	margin-left: -15px;
					// 	transition: all 0.25s;
					// }
				}
				&:nth-child(2) {
					flex: 0 0 20%;
				}
				&:nth-child(3) {
					flex: 0 0 30%;
				}
				&:last-child {
					flex: 0 0 5%;
				}
				&.addField {
					.azAlert--error{
						max-width: 100% !important;
					}
					.taxOptionsContainer {
						display: flex;
						align-content: center;
						justify-content: center;
						align-items: center;
						.taxRateField {
							padding: 5px;
							border: 1px solid $az_gray_4;
							width: 100%;
							border-top-left-radius: 5px;
							border-bottom-left-radius: 5px;
							color: $az_gray_4;
						}
						.taxRateData {
							padding: 5px;
							border: 1px solid $az_gray_4;
							width: 100%;
							border-top-left-radius: 5px;
							border-bottom-left-radius: 5px;
						}
						.taxRateCaret {
							padding: 3px;
							border: 1px solid $az_gray_4;
							border-top-right-radius: 13%;
							border-bottom-right-radius: 17%;
							color: $az_cyan;
						}
					}
					
				}
			}
		}
		.table {
			.tr {
				position: relative;
				
			}
		}
	}
	
}


body .taxRateTable .table_wrap:not(.noscroll) .tbody {
	max-height: 275px;
	&::-webkit-scrollbar {
		-webkit-appearance: none;
	}

	&::-webkit-scrollbar:vertical {
		width: 11px;
	}

	&::-webkit-scrollbar:horizontal {
		height: 11px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 8px;
		border: 2px solid white;
		background-color: rgba(0, 0, 0, 0.5);
	}

	&::-webkit-scrollbar-track {
		background-color: #fff;
		border-radius: 8px;
	}
}

[hidden] {
	display: none;
}
