.popOver {
	position: relative;
	&.autoWidth .popOverMessage {
		min-width: 0;
	}
	.popOverTrigger {
		cursor: pointer;
	}
	.popOverMessage {
		position: absolute;
		left: 0;
		top: 100%;
		min-width: 250px;
		background: #fff;
		padding: 10px;
		border-radius: 5px;
		line-height: 1.5;
		transform-origin: 0 0;
		transform: scale(0);
		@include box-shadow();
		text-transform: capitalize;
		z-index: 999;
		&.show {
			animation: inn 0.35s;
			transform: scale(1);
		}
		top: auto;
		-webkit-transform-origin-y: 100%;
		&.bottom {
			bottom: 100%;
		}
		&.left {
			-webkit-transform-origin-x: 0;
			right: auto;
			left: 0;
		}
		&.right {
			-webkit-transform-origin-x: 100%;
			right: 0;
			left: auto;
		}
		&.top {
			-webkit-transform-origin-y: 0;
			bottom: auto;
			top: 100%;
		}

		.top_left & {
			top: 100%;
			bottom: auto;
			left: 0;
			right: auto;
		}

		.bottom_left & {
			bottom: 100%;
			top: auto;
			left: 0;
			right: auto;
		}

		.bottom_right & {
			bottom: 100%;
			top: auto;
			left: auto;
			right: 0;
		}
		.top_right & {
			top: 100%;
			bottom: auto;
			left: auto;
			right: 0;
		}

		&.exit {
			// animation: outt 0.35s;
		}
	}
	&.center .popOverMessage.left {
		transform: translateX(-50%);
	}
	&.center .popOverMessage.right {
		transform: translateX(50%);
	}
}

div.tooltip-container {
	min-width: 150px;
	background: #fff;
	padding: 10px;
	border-radius: 5px;
	line-height: 1.5;
	transform-origin: 0 0;
	z-index: 9999999999;
	@include box-shadow();
	border: 0;
	display: block;
	&:not(.autoWidth) {
		max-width: 250px;
	}
}

div.no-minimun-width-tooltip {
	min-width: 0px;
	line-height: 1;
}

[data-reach-tooltip] {
	position: absolute;
	left: 0;
	top: 100%;
	background: #fff;
	padding: 10px;
	border-radius: 5px;
	transform-origin: 0 0;
	@include box-shadow();
	z-index: 999;
	animation: inn 0.35s;
	line-height: 1.5;
	min-width: 150px;
	text-transform: capitalize;
	word-break: break-all;
	font-size: 13px;
}

@keyframes inn {
	from {
		@include opacity(0);
		pointer-events: none;
	}

	to {
		pointer-events: auto;
		@include opacity(1);
	}
}
@keyframes outt {
	from {
		pointer-events: auto;
		@include opacity(1);
	}

	to {
		pointer-events: none;
		@include opacity(0);
	}
}
