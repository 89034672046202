.schedule-date-picker{
    height: 290px;
    width:290px;
    background: $az_white;
    color: $az_rpt_black;
    border-radius: 3px;
    padding: 26px;
    transition: all 0.3s ease-in-out;
    @include box-shadow;
    .date-picker-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .chevron-container{
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 25px;
            background: $az_gray_2;
            width: 30px;
            height: 30px;
            cursor: pointer;
            .arrow{
              color:$az_cyan;
            }
          }

    }
    .date-container{
        .date-table{
            border-collapse: collapse;
            table-layout: fixed;
            width:100%;
            thead th {
                position: -webkit-sticky; /* for Safari */
                position: sticky;
                top: 0;
            }
            tbody:before {
                content: "-";
                display: block;
                line-height: 12px;
                color: transparent;
            }
            .date-header-cell{
                color:#B0B0B0;
                z-index: 11007;
            }
            .date-cell{
                min-height: 32px;
                cursor: pointer;
                .grid-data-container{
                    min-height: 32px;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: 30ms all ease-in-out;
                    &.first-date{
                        border-top-left-radius: 50%;
                        border-bottom-left-radius: 50%;
                    }
                    &.end-date{
                        border-top-right-radius: 50%;
                        border-bottom-right-radius: 50%;
                    }

                }
                .background-div{
                    min-height: inherit;
                    width: 100%;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    &.first-date{
                        background: $az_cyan;
                    }
                    &.end-date{
                        background: $az_cyan;
                    }
                }
                .selected{
                    background: transparentize($color: $az_cyan, $amount: 0.6);
                    color: $az_white;
                }
                .hovered{
                    background: transparentize($color: $az_cyan, $amount: 0.8); 
                    &.first-date{
                        border-top-left-radius: 0%;
                        border-bottom-left-radius: 0%;
                    }
                    &.end-date{
                        border-top-right-radius: 0%;
                        border-bottom-right-radius: 0%;
                    }
                    &.hovered-first-date{
                        border-top-left-radius: 50%;
                        border-bottom-left-radius: 50%;
                    }
                    &.hovered-end-date{
                        border-top-right-radius: 50%;
                        border-bottom-right-radius: 50%;
                    }
                }
            }
        }
    }
    .month-grid{
        display: grid;
        grid-template-columns: auto auto auto;
        .month-container{
            display: flex;
            align-items: center;
            justify-content: center;
            min-height:36px;
            border-radius:25px;
            cursor: pointer;
            transition: 30ms all ease-in-out;
            &:hover{
                background: transparentize($color: $az_cyan, $amount: 0.6);
                color: $az_white
            }
            &.selected-month{
                background: $az_cyan;
                color: $az_white;
            }
        }
    }
}
.schedule-date-picker-container{
    position:absolute;
    background: $az_white;
    color: $az_rpt_black;
    border-radius: 3px;
    z-index:100000;
}