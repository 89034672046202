.slick-slider {
	&.multiple {
		margin: 0 -15px;
	}
	position: relative;
	max-width: 700px;
	margin: auto;
	.slick-list {
		overflow: hidden;
		padding: 0;
		background: #fff;
		border-radius: 5px;
		.multiple & {
			.slick-slide {
				padding: 15px;
				> div {
					@include box-shadow;
					padding: 15px;
				}
			}
		}
		.slick-slide {
			padding: 1px;
			float: left;
			transition: all 0.25s;
		}
	}

	.slick-arrow {
		padding: 0 !important;
		height: 25px;
		width: 25px;
		font-size: 0;
		background: transparent !important;
		box-shadow: none;
		position: absolute;
		right: 0;
		bottom: 5px;
		transform: translateY(50%);
		z-index: 999;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		svg {
			color: $az_gray_6;
			height: 100%;
			width: 100%;
			transform: scaleX(2) scaleY(1.5);
		}

		&.slick-disabled {
			svg {
				color: $az_gray_3;
			}
		}

		&.slick-prev {
			right: auto;
			left: 0;
		}
	}

	.slick-dots {
		display: flex !important;
		align-items: center;
		justify-content: center;
		list-style-type: none;
		padding: 0;
		margin-bottom: 0;
		margin-top: 25px;

		li {
			margin: 0 3.5px;
			.btn {
				display: block;
				padding: 0;
				line-height: initial;
				font-size: 0;
				height: 10px;
				width: 10px;
				border-radius: 50%;
				display: block;
				cursor: pointer;
				background: $az_gray_3;
			}

			&.slick-active .btn {
				background: $az_gray_6;
			}
		}
	}
}

.locationCarousel {
	.slick_carousel {
		.slick-slider {
			.slick-list[style="height: 0px;"] {
				height: auto !important;
			}
		}
	}
}
