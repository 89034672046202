@mixin estimate-detail {
	&__container {
		display: flex;
		flex-wrap: wrap;
		position: relative;

		@extend .job_split_wrapper;

		& .activityLog .logList {
			max-height: 200px !important;
		}
	}

	&__header-title {
		flex: 2;
		flex-grow: 2 !important;
		margin-right: 15px !important;
	}

	&__flex {
		display: flex !important;
		align-items: center;
		justify-content: space-between;

		&--f1 {
			flex: 1;
		}
	}

	&__btn {
		align-items: center;
		display: flex !important;
	}

	&__mail-icon {
		margin-right: 8px;
	}

	&__column {
		flex: 1;
		margin-bottom: 15px;
	}

	&__column--large {
		flex: 2;
		margin-right: 15px;
	}

	&__flex-description {
		flex: 1 0 0;
	}

	&__title {
		display: flex;
		align-items: center;
		justify-content: space-between;

		font-size: 18px;
		line-height: 1.5;
		font-weight: 600;
		margin-bottom: 15px;
	}

	&__customer {
		height: 100%;
	}

	&__mb-10 {
		margin-bottom: 10px !important;
	}

	&__bold {
		font-weight: 600;
	}

	&__money {
		margin-left: 12px;
		font-weight: 600;
	}

	&__status-block {
		display: flex;
		align-items: center;

		font-size: 17px;
		font-weight: 500;
		margin-right: 15px;
	}

	&__status {
		padding: 5px 15px;
		margin-left: 15px;
		border-radius: 3px;
		white-space: nowrap;
		background: $az_gray_4;

		&--approved {
			background: $az_green;
		}

		&--rejected {
			background: $az_red;
		}

		&--in-review {
			background: $az_yellow;
		}
	}

	@media only screen and (max-width: $screen-md-min) {
		&__container {
			display: block;
		}

		&__column--large {
			margin-right: 0;
		}
	}
}

@mixin estimate-activity {
	width: 650px;
	display: grid;
	gap: 1rem;
	grid-gap: 1rem;
	grid-template-columns: repeat(2, minmax(0, 1fr));

	&__title {
		color: $az_white;
		font-size: 16px !important;
	}

	&__column {
		flex: 1;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.formSection#{&}__section {
		display: block;
		height: 100%;
		padding: 10px !important;
		margin-top: 5px;
		border-radius: 10px;
		background: $az_white;

		overflow-y: auto;
		max-height: 60vh;

		.formRow {
			margin-bottom: 0px;

			~ .formRow {
				margin: 8px 0px 0px;
			}
		}

		.formRow_key,
		.small {
			margin: 0;
			font-size: 11px !important;
		}

		.formRow_key {
			flex-grow: 0;
		}
	}

	&__item-log {
		width: 100%;
		margin-top: 8px;
		padding: 3px 15px;
		border-radius: 6px;
		background: $az_gray_1;

		&--deleted {
			background: #ffebee !important;
		}

		&--created {
			background: #e8f5e9 !important;
		}
	}

	@media only screen and (max-width: $screen-md-min) {
		width: 220px;
		display: block;

		&__column ~ &__column {
			margin: 10px 0px 0px;
		}

		&__section {
			height: 30vh;
		}
	}
}

.estimate-print {
	width: 98%;
	max-width: 70rem;

	margin: 20px auto;
	border-radius: 6px;

	overflow: hidden;
	background: $az_white;
	box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
		0 10px 10px -5px rgba(0, 0, 0, 0.04);

	&__container {
		padding: 15px 15px 0;
	}

	&__title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
	}

	&__logo {
		padding: 8px 24px;
		margin-bottom: 5px;
		background: $az_black;

		img {
			width: 73px;
		}
	}

	&__table {
		width: 100%;

		td {
			padding-right: 3px;
		}
	}

	&__line-bottom {
		border-bottom: 1px solid $az_gray_2;
	}

	&__th {
		width: 10%;
		text-align: left;
		font-weight: 600;
		color: $az_gray_6;

		&--first {
			width: 35%;
		}
	}

	&__result {
		.col {
			line-height: 1;
			padding-top: 5px;

			&--first {
				padding-top: 10px;
			}
		}

		.strong {
			font-weight: 500;
		}

		&--line {
			border-top: 1px solid $az_gray_2;
		}
	}

	&__info {
		margin-top: 12px;
		padding: 35px 15px;

		color: $az_white;
		background-color: $az_gray_6;
	}

	&__grid {
		display: grid;
		gap: 1rem;
		grid-gap: 1rem;
		grid-template-columns: repeat(4, minmax(0, 1fr));

		margin-left: 1rem;
		margin-right: 1rem;

		.uppercase {
			text-transform: uppercase;
		}
	}

	&__grid-column {
		text-align: center;
		grid-column: span 1 / span 1;

		&--first {
			text-align: left;
			grid-column: span 2 / span 2;
		}
	}

	&__divider {
		width: 100%;
		margin: 8px 0;
		border-top: 1px solid $az_white;
	}

	&__total {
		font-size: 18px;
		font-weight: 600;
	}

	&__advice {
		color: $az_gray_5;
		padding: 12px 15px;

		.strong {
			font-weight: 500;
		}
	}
}

.estimate-review {
	max-width: 940px;
	width: calc(100% - 30px);

	padding: 25px;
	margin: 35px auto;

	background: $az_white;

	&__header {
		display: flex;
		padding: 0 0 5px;
		align-items: center;
		border-bottom: 1px solid $az_gray_3;
	}

	&__logo {
		padding: 8px 24px;
		margin-bottom: 5px;
		background: $az_black;

		img {
			width: 73px;
		}
	}

	&__approved,
	&__rejected {
		display: flex;
		margin-top: 30px;
		align-items: center;
		justify-content: center;
	}

	&__approved {
		color: $az_green;
	}

	&__rejected {
		color: $az_red;
	}

	&__text {
		font-size: 17px;
		font-weight: 500;
		margin-left: 10px;
	}
}

.estimate-detail {
	@include estimate-detail;
}

.estimate-activity {
	@include estimate-activity;
}
