.azTitle {
  font-size: 28px;
  font-weight: bold;
  margin: 20px 0;
  line-height: 1.5;
  display: block;
  &.azTitle--no-margin-top {
    margin-top: 0;
  }
  &.azTitle--no-margin-bottom {
    margin-bottom: 0;
  }
  &.azTitle--no-margin {
    margin: 0;
  }
  &.azTitle_secondary {
    font-size: 24px;
  }
  &.azTitle_tertiary {
    font-size: 18px;
    font-weight: 500;
  }
  &.azTitle_small {
    font-weight: 500;
    font-size: 16px;
  }
  &.azTitle_XSmall {
    font-size: 13px;
  }
  &.azTitle_grey {
    color: $az_gray_4;
  }
  &.strong {
    font-weight: 600;
  }
  &.stronger {
    font-weight: 900;
  }
}
