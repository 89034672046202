//Media Mixins for respondTo
@mixin respondTo($media) {
	@if $media== "xs" {
		@media only screen and (max-width: $screen-xs-max) {
			@content;
		}
	} @else if $media== "sm" {
		@media only screen and (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
			@content;
		}
	} @else if $media== "md" {
		@media only screen and (max-width: $screen-md-max) and (min-width: $screen-md-min) {
			@content;
		}
	} @else if $media== "lg" {
		@media only screen and (max-width: $screen-lg-max) and (min-width: $screen-lg-min) {
			@content;
		}
	} @else if $media== "xlg" {
		@media only screen and (min-width: $screen-xlg-min) {
			@content;
		}
	}
}

//Media Mixins for respondToAbove
@mixin respondToAbove($media) {
	@if $media== "xs" {
		@media only screen and (min-width: $screen-xs-min) {
			@content;
		}
	} @else if $media== "sm" {
		@media only screen and (min-width: $screen-sm-min) {
			@content;
		}
	} @else if $media== "md" {
		@media only screen and (min-width: $screen-md-min) {
			@content;
		}
	} @else if $media== "lg" {
		@media only screen and (min-width: $screen-lg-min) {
			@content;
		}
	} @else if $media== "xlg" {
		@media only screen and (min-width: $screen-xlg-min) {
			@content;
		}
	}
}

//Media Mixins for respondToBelow
@mixin respondToBelow($media) {
	@if $media== "xs" {
		@media only screen and (max-width: $screen-xs-max) {
			@content;
		}
	} @else if $media== "s" {
		@media only screen and (max-width: $screen-s-min) {
			@content;
		}
	} @else if $media== "sm" {
		@media only screen and (max-width: $screen-sm-max) {
			@content;
		}
	} @else if $media== "mid-size" {
		@media only screen and (max-width: $screen-mid-size) {
			@content;
		}
	} @else if $media== "md" {
		@media only screen and (max-width: $screen-md-max) {
			@content;
		}
	} @else if $media== "lg" {
		@media only screen {
			@content;
		}
	} @else if $media== "jobs" {
		@media only screen and (max-width: $screen-sm-jobs) {
			@content;
		}
	} @else if $media== "xxs" {
		@media only screen and (max-width: $screen-xxs-max) {
			@content;
		}
	}
}

//Media Mixins for Tablets
@mixin respondToTablet {
	@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
		@content;
	}
}

//Mixin for Clearfix to solve Float Problem
@mixin clearfix {
	&:before {
		content: "";
		display: table;
	}
	&:after {
		content: ".";
		display: block;
		height: 0;
		clear: both;
		visibility: hidden;
	}
}

//Mixin for Rounded Corners
@mixin rounded_corners($radius: 5px) {
	-moz-border-radius: $radius;
	-webkit-border-radius: $radius;
	border-radius: $radius;
}

//Mixin for input element
@mixin input(
	$border_color: rgba(#000, 0.1),
	$disabled_color: #999999,
	$bg_color: #fff,
	$text_color: inherit,
	$border_radius: 25px
) {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	padding: 5px 10px;
	border: solid 1px $border_color;
	height: 35px;
	display: block;
	color: $text_color;
	width: 100%;
	max-width: 100%;
	background-color: $bg_color;
	box-sizing: border-box;
	font-size: 14px;
	border-radius: $border_radius;
	@include respondTo(xs) {
		width: 100%;
		max-width: 100%;
	}
	outline: none;
	&:focus {
		outline: none;
	}
	&[disabled] {
		pointer-events: none;
		background: #eee;
		box-shadow: none;
		color: $disabled_color;
		border-color: #eee;
		cursor: default;
	}
	@include respondToBelow(xs) {
		height: 40px;
	}
	&.small,
	.small & {
		border-radius: 3px;
		height: 30px;
		@include input-placeholder-font(#999, 13px);
	}
}

//Mixin which Provides a cross-browser method to implement `display: inline-block;`
@mixin inline-block($alignment: middle) {
	display: -moz-inline-stack;
	display: inline-block;
	@if $alignment and $alignment !=none {
		vertical-align: $alignment;
	}
	*vertical-align: auto;
	zoom: 1;
	*display: inline;
}

//Mixin to impliment Gradients for background
@mixin background_gradient(
	$min_color,
	$max_color,
	$color_stop1: 0%,
	$color_stop2: 100%
) {
	background: $min_color;
	background: -moz-linear-gradient(
		top,
		$min_color $color_stop1,
		$max_color $color_stop2
	);
	background: -o-linear-gradient(
		top,
		$min_color $color_stop1,
		$max_color $color_stop2
	);
	background: -webkit-linear-gradient(
		top,
		$min_color $color_stop1,
		$max_color $color_stop2
	);
	background: -ms-linear-gradient(
		top,
		$min_color $color_stop1,
		$max_color $color_stop2
	);
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop($color_stop1/100%, $min_color),
		color-stop($color_stop2/100%, $max_color)
	);
	background: linear-gradient(
		to bottom,
		$min_color $color_stop1,
		$max_color $color_stop2
	);
	zoom: 1; // fix for ie7 issue
	-ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorStr=#{$min_color}, endColorStr=#{$max_color});
	filter: progid:DXImageTransform.Microsoft.gradient(startColorStr=#{$min_color}, endColorStr=#{$max_color});
}

//Mixin for Box Shadow
@mixin box-shadow(
	$shade: #000,
	$shade_amount: 0.25,
	$shadowX: 0,
	$shadowY: 1px,
	$shadowBlur: 5px,
	$shadowScale: 0
) {
	box-shadow: $shadowX $shadowY $shadowBlur $shadowScale rgba(0, 0, 0, 0.14),
		0 7px 10px -5px rgba($shade, $shade_amount);
}

//Mixin for Box Font Size
@mixin font-size($sizeValue) {
	font-size: ($sizeValue * 10) + px;
	font-size: $sizeValue + rem;
}

//Mixin for input placeholder
@mixin input-placeholder-font($color: #999999, $fontSize: 14px) {
	&::-webkit-input-placeholder {
		color: $color;
		font-size: $fontSize;
	}
	&::-moz-placeholder {
		color: $color;
		font-size: $fontSize;
	}
	&::-moz-placeholder {
		color: $color;
		font-size: $fontSize;
	}
	&::-ms-input-placeholder {
		color: $color;
		font-size: $fontSize;
	}
}

//Mixin for right to left content
@mixin rtl {
	html[lang="ar"] & {
		@content;
	}
}

//Mixin for Close Icon
@mixin close-icon($color: #000) {
	font-size: 0 !important;
	position: absolute;
	height: 40px;
	width: 40px;
	right: 20px;
	top: 20px;
	font-size: 0px;
	cursor: pointer;
	z-index: 9;
	border: 0;
	background: transparent;
	&:before,
	&:after {
		content: "";
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		margin: auto;
		position: absolute;
		height: 2px;
		background: $color;
		width: 50%;
		transition: all 0.25s;
		@include opacity(0);
	}
	&:before {
		transform: rotate(45deg);
		@include opacity(1);
	}
	&:after {
		transform: rotate(-45deg);
		@include opacity(1);
	}
}

// Mixin for columns

@mixin column-width($columnLength: 2, $columnGap: 50px) {
	width: calc(
		(100% / #{$columnLength}) - (#{$columnGap} * (#{$columnLength} - 1)) / #{$columnLength}
	);
	margin-right: $columnGap;
	&:nth-child(#{$columnLength}n) {
		margin-right: 0;
	}
}

//az_hover
@mixin az_hover {
	position: relative;

	&:before {
		content: "";
		position: absolute;
		left: -15px;
		right: -15px;
		top: 0;
		bottom: 0;
		box-shadow: 0 0 0 2px $az_cyan;
		border-radius: 7px;
		@include opacity(0);
		pointer-events: none;
	}
	&:hover {
		&:before {
			@include opacity(1);
		}
	}
}

@mixin opacity($amount) {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$amount*100})";
	filter: alpha(opacity=#{$amount * 100});
	-moz-opacity: $amount;
	-khtml-opacity: $amount;
	opacity: $amount;
}
