#rates {
	.add_icon {
		display: flex;
		height: 30px;
		width: 30px;
		border-radius: 20px;
		background: #0069b1;
		align-items: center;
		justify-content: center;
		color: aliceblue;
		svg {
			color: #fff;
		}
	}
	.listing_table_wrapper {
		table {
			th {
				flex: 0 0 25% !important;
			}
			tbody {
				tr:not(.no-data),
				.tr:not(.no-data) {
					th,
					td,
					.td {
						flex: 0 0 25% !important;
					}
					.fullWidth {
						flex: 1 1 100% !important;
					}
				}
				tr ~ .tr {
					border-bottom: none;
					&:before {
						left: 43px;
						width: calc(100% - 47px);
						z-index: 9;
					}
					.td {
						position: relative;
						&:before {
							z-index: 0;
							content: "";
							position: absolute;
							left: 0;
							right: 0;
							bottom: 0;
							border-bottom: solid 2px $az_gray_1;
						}
						&.viewRatesStyle {
							&:before {
								left: 45px;
							}
						}
					}
				}
				tr {
					&:before {
						z-index: 9;
					}
				}
			}
		}
	}
	.disabled {
		background-color: $az_gray_3 !important;
	}
}

.user_rates_listing {
	box-shadow: none;
	margin-top: 0px;
	padding-left: 5px;
	padding-right: 5px;
	#rateType {
		padding-top: 0px;
	}
	.listing_header {
		padding-top: 0px;
		padding-left: 10px !important;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-end;
	}
	.listing_table_wrapper {
		margin-top: 0px;
	}
	.group_select {
		margin-right: 0px;
	}
	.actions {
		margin-left: 20px !important;
	}
}

.rates_header_end {
	display: flex;
	align-items: center;
}
.with-dropdown {
	border-bottom-right-radius: 0px;
	border-top-right-radius: 0px;
}

.with-apply-button {
	border-bottom-left-radius: 0px;
	border-top-left-radius: 0px;
	margin-left: -2px !important;
}
