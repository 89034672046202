.login {
	position: relative;
	height: 100vh;
	.main-container {
		display: flex;
		align-items: center;
		height: 100%;
	}
	.login-container {
		position: relative;
		flex-basis: 35%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
        flex-direction: column;
	}
	.marketing-container {
		position: relative;
		flex-basis: 65%;
		height: 100%;
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
		iframe{
			height: 100%;
			width: 100%;
		}
	}
	.background {
		background: linear-gradient(157.38deg, #10b0e0 0%, #76d02d 100%);
		filter: blur(4px);
		height: 100vh;
	}
    .login-error-text{
        font-size: 13px;
        font-weight: 500;
        color:$az_maroon;
        text-align: left;
        padding: 0px 18px;
    }
    .email-sent{
        display:flex;
        margin-top: 20px;
        margin-bottom: 16px;
        background: $az_gray_1;
        padding: 16px 8px;
        border-radius: 9px;
        border: 1px solid $az_gray_5;
        font-size: 11px;
        color: $az_rpt_table_text;
        svg{
            width: 14px;
            height: 14px;
            margin-right: 8px;
        }
        p{
            width: 100%;
            text-align: left;
            font-weight: 400;
            line-height: 1.3;
        }
        a{
            color: $az_cyan;
            font-weight: 600;

        }
    }
	.login-form-container {
        position: absolute;
        top: 10%;
        position: absolute;
        top: 10%;
        width: 30%;
        min-width: 400px;
	}
	.rotate-icon {
		transform: rotate(225deg);
	}
	.leading-icon {
		margin-left: 0px;
		margin-right: 16px;
		cursor: pointer;
	}
	.optional-action-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.checkbox-remember-me {
		.input_label {
			align-items: center;
		}
		.small-checkbox {
			margin-right: 4px !important;
			&::before {
				height: 15px !important;
				width: 15px !important;
			}
		}
		#input-title {
			font-size: 11px;
			font-weight: 600;
			color: $az_gray_4;
			margin-top: 0px;
		}
	}
	.forgot-password {
		font-size: 11px;
		font-weight: 600;
		color: $az_gray_4;
		margin-top: 0px;
		text-decoration: underline;
		margin: 0px;
		cursor: pointer;
	}
	.login-button {
		width: 100%;
		border-radius: 25px;
		color: $az_white;
		font-size: 14px;
		font-weight: 700;
		height: 30px;
		line-height: 30px;
		margin-top: 24px;
		margin-bottom: 24px;
		background: linear-gradient(360deg, #7ab14f 0, #89c459 100%);
	}
    .login-input{
        height: 28px;
    }
	.password-reset {
		background: linear-gradient(360deg, #156199 0, #1776b8 100%);
		font-weight: 500;
        width: 100%;
		border-radius: 25px;
		height: 30px;
		line-height: 30px;
		margin-bottom: 24px;
	}
    .login-footer{
        position: absolute;
        bottom: 24px;
        text-align: center;
        .footer-text{
            margin-top: 16px;
            color: $az_cyan;
            font-weight: 600;
        }
    }
    .logo{
        margin-top: 24px;
    }
    .go_back{
        color: $az_gray_5;
        text-decoration: underline;
        background: none;
        display: flex;
        align-items: center;
        position: absolute;
        top: 0px;
        left: 0px;
        svg{
            margin-right: 8px;
        }
    }
    .flipped {
        .front {
            transform: rotateY(180deg);
        }
        .back {
            transform: rotateY(0deg);
        }
    }
    .front,
    .back {
        box-sizing: border-box;
        display: block;
        position: absolute;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        transition: transform ease 500ms;
        box-shadow: 0 2.8px 46.2px rgb(0 0 0 / 10%), 0 0 0 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);

        background: $az_white;
		z-index: 2;
		width: 30%;
		min-width: 400px;
		padding: 20px 32px;
		border-radius: 8px;
		text-align: center;
    }
    .front {
        z-index: 2;
        transform: rotateY(0deg);
    }
    .back {
        transform: rotateY(-180deg);
    }
}
