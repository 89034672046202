.schedule-by-worker {
  color: $az_rpt_black;
  .schedule-dispatch-worker-container {
    // overflow: hidden;
    .sub-heading-switch-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: $az_white;
      padding: 0px 25px 4px 25px;
      .unscheduled-jobs-switch-container {
        display: flex;
        align-items: center;
        transition: 0.5s all ease-in-out;
      }
    }
  }
  #schedule-worker-job-list {
    transition: 0.5s all ease-in-out;
    background: $az_white;
    margin-left: auto;
    min-width: unset;
    .schedule-job-list {
      height: 44vh;
    }
  }
  .worker-grid-container {
    position: relative;
    width: 100%;
    background: $az_white;
    height: 62vh;
    .worker-grid {
      transition: 0.5s all ease-in-out;
      position: absolute;
      top: 0;
      width: 100%;
      background: $az_white;
      height: 70vh;
      padding-left: 25px;
    }
    .worker-grid-divider {
      background: $az_gray_2;
      width: 3px;
      border-radius: 3px;
      height: 70vh;
      position: absolute;
      right: -8px;
      top: -38px;
    }
  }
}
