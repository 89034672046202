#sort-filter {
	margin: 10px 0px;
	width: 100%;
	.flex-container {
		display: flex;
		flex-wrap: nowrap;
	}

	.flex-container > .flex {
		text-align: left;
	}
	.title {
		label {
			font-weight: 500;
			margin-right: 3%;
			cursor: pointer;
		}
		.sort-icon {
			color: #0667b7;
			cursor: pointer;
			svg {
				position: absolute;
				margin-left: 3px;
				margin-top: -2px;
			}
		}
	}
	.sort {
		width: 180px;
		.bottom {
			min-width: 180px !important;
		}
	}
	.filter {
		width: 150px;
		.title {
			cursor: pointer;
			label {
				color: #0667b7;
			}
		}
		.filtered {
			background: #0667b7;
			color: white;
			border-radius: 50%;
			padding: 1px 4px;
		}
	}
	.tooltip {
		display: block;
		position: relative;
		.filter-title {
			font-size: 14px;
			font-weight: 500;
		}
		.formRow {
			margin-top: 12px;
			margin-bottom: 15px;
		}
		.formRow_val {
			position: absolute !important;
			width: 100% !important;
		}
		.css-2b097c-container {
			width: 90% !important;
		}
		.brand-filter {
			background: #ffffff;
			border: 1px solid #c4c4c4;
			border-radius: 3px;
			padding: 6px;
			margin-bottom: 20px;
			max-height: 118px;
			overflow: auto;
			.brand {
				margin: 6px 0px;
			}
			label {
				color: #2e2e2e;
				font-weight: 400;
			}
		}
		.range {
			margin-top: 4px;
			display: flex;
			margin-bottom: 10px;
		}
		.small-box {
			width: 100px !important;
			border-radius: 3px;
			margin: 0px 8px;
			height: 30px;
			padding: 5px 10px;
			border: solid 1px rgba(4, 0, 0, 0.3);
		}
		.footer {
			padding: 10px;
			button {
				width: 100%;
			}
		}
	}

	.tooltip .bottom {
		min-width: 250px;
		top: 25px;
		left: 50%;
		transform: translate(-50%, 0);
		padding: 12px 10px;
		color: #444444;
		background-color: #ffffff;
		font-weight: normal;
		font-size: 13px;
		border-radius: 8px;
		position: absolute;
		z-index: 9999;
		box-sizing: border-box;
		box-shadow: 0 1px 8px #eeeeee;
		// visibility: hidden;
		opacity: 1;
		transition: opacity 0.8s;
	}

	.tooltip:hover .bottom {
		visibility: visible;
		opacity: 1;
	}

	.tooltip .bottom i {
		position: absolute;
		bottom: 100%;
		left: 50%;
		margin-left: -12px;
		width: 24px;
		height: 12px;
		overflow: hidden;
	}

	.tooltip .bottom i::after {
		content: "";
		position: absolute;
		width: 12px;
		height: 12px;
		left: 50%;
		transform: translate(-50%, 50%) rotate(45deg);
		background-color: #ffffff;
		box-shadow: 0 1px 8px #eeeeee;
	}
	.sort-options {
		padding: 0px 6px;
		svg {
			margin-right: 10px;
			color: #00cd39;
			font-weight: 300;
		}
		.option {
			margin-top: -4px;
			color: #747474;
			cursor: pointer;
		}
		.without-check {
			left: 23% !important;
			position: relative;
		}
		img {
			margin-left: 5%;
			margin-right: 5%;
		}
	}
}
