.dialogOverlay_customerDetails {
	.dialogContent_customerDetails {
		padding: 0px 25px 25px !important;
		.customer_details {
			.azTitle,
			.title {
				margin: 0 !important;
			}
		}
		.customer_details_view {
			margin-top: 16px;
		}

		.formSection {
			padding: 20px !important;
			.cardList {
				margin-top: 10px !important;
				.cardItem {
					margin-bottom: 0 !important;
					&.customer_payment__card {
						margin-bottom: 10px !important;
					}
				}
			}
		}
		.customer_details_view {
			.azTitle {
				width: 100%;
			}
			.formRow {
				width: 50%;
				padding-right: 30px;
				.formRow_key,
				.formRow_val_label {
					width: 50%;
				}
			}
			.divideView {
				.formRow.divideView_items {
					width: 50%;
				}
			}
			&.group {
				flex-direction: column;
				position: relative;
				> * {
					width: 100%;
				}
			}
			.status {
				width: 50%;
				position: absolute;
				right: 0;
				bottom: 0;
				.formRow {
					width: 100%;
					justify-content: flex-end;
					margin-bottom: 10px;
				}
			}
		}

		.dialogHeader {
			.azTitle {
				margin: 20px 0;
			}
			.actions {
				margin-top: 24px;
			}
		}
	}
	.customer_details_view {
		.groupItem {
			.formRow {
				margin-bottom: 15px;
			}
		}
		.status {
			@include respondToBelow(s) {
				margin-left: 0px !important;
			}
		}
	}
}
.dialogOverlay_customerDetails
	.dialogContent_customerDetails
	.customer_details {
	.add_location_section,
	.add_contact_section {
		.title {
			margin-bottom: 15px !important;
		}
	}
}

.location_type {
	margin-left: 24px;
	margin-top: 3px;
	font-size: 15px;
	font-weight: 500;
	border: 1px solid;
	border-radius: 3px;
	.button {
		padding: 0px 17px;
		border-radius: 0px !important;
	}
	.not-active {
		background-color: transparent;
		color: black;
	}
}

.dialogOverlay_EditCustomer,
.dialogOverlay_addCustomers {
	form {
		> .azTitle {
			margin-bottom: 0 !important;
		}
	}
}
