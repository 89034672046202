.invoice-list {
	&__switchs {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;

		> label ~ label {
			margin-left: 5px;
		}

		@media only screen and (max-width: $screen-mid-size) {
			width: 100%;
			justify-content: space-between;

			> label {
				flex-basis: auto !important;
			}
		}
	}

	&__tooltip-list .invoice-detail__numbers {
		flex-direction: column;

		> a {
			margin: 0 auto;
		}
	}

	&__table .jobNumber .trigger,
	&__table .estimateNumber .trigger {
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.invoice-detail {
	@include estimate-detail;

	.flex-wrap {
		flex-wrap: wrap;
	}

	&__column--small {
		display: flex;
		flex-direction: column;
	}

	&__customer {
		flex: 1;
	}

	&__recurring {
		display: flex;
		flex-direction: column;
	}

	&__recurring-container {
		overflow: auto;
		height: 250px;
	}

	&__title--mb {
		margin-bottom: 5px;
	}

	&__description {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__numbers {
		display: flex;
		flex-wrap: wrap;
		line-height: 1.5;
	}

	&__numbers-link {
		color: $az_cyan;
		cursor: pointer;
		margin-right: 15px;

		&:hover {
			text-decoration: underline;
		}
	}

	&__status-block {
		margin-right: 25px;
	}

	&__status {
		min-width: 80px;
		text-align: center;

		&--paid,
		&--active {
			background: $az_green;
		}

		&--not-paid {
			background: $az_yellow;
		}

		&--pending {
			background: $az_gold;
		}

		&--failed {
			background: $az_red;
		}

		&--processing {
			background: $az_gold;
		}

		&--select {
			margin-left: 7px;
			.react-select-container {
				min-width: 100px;
				max-width: 100px;
				color: $az_black;
				font-size: 14px;
				font-weight: 400;
			}
		}
	}

	&__status-color {
		@include transactions_status;
	}

	&__btn {
		margin-left: 10px;

		&--refund {
			margin-left: 0;
		}
	}

	&__times-icon {
		margin-right: 4px;
	}

	&__undo-icon {
		width: 16px;
		height: 16px;
		margin-right: 6px;
	}

	&__cash-icon {
		width: 20px;
		height: 20px;
		margin-right: 6px;
	}

	&__payment {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__table {
		width: 100%;
		margin-top: 0 !important;

		thead {
			color: $az_gray_6;
			border-bottom: 1px solid $az_gray_2;
		}

		th {
			text-align: left;
			font-weight: 600;

			&.status {
				min-width: 120px;
			}
		}

		td {
			vertical-align: middle;
		}

		tr:hover .actions {
			opacity: 1;
			transform: translateX(0px);
		}

		.first {
			width: 65%;
			min-width: 120px;
		}

		.btn {
			cursor: pointer;
			color: $az_cyan;

			&--undo {
				width: 16px;
				height: 16px;
			}
		}

		.actions {
			display: flex;
			min-width: 50px;
			justify-content: center;
			opacity: 0;
			color: $az_cyan;
			transition: all 0.25s;
			transform: translateX(10px);
			margin-right: 10px;
		}
	}

	&__tooltip {
		font-size: 13px;
		font-weight: 500;
		background: $az_white;
	}

	@media only screen and (max-width: $screen-mid-size) {
		&__payment {
			width: 100%;
		}

		&__btn {
			margin-left: 0;
			margin-top: 15px;
		}
	}

	@media only screen and (max-width: $screen-xs-min) {
		&__btn {
			margin-left: 0;
		}
	}
}

.invoice-activity {
	@include estimate-activity;
}
