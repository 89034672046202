.team {
    display: flex;
    margin-left: 0;
    width: auto;
    @include respondToBelow(sm) {
      flex-wrap: wrap;
    }
    &.team_fullWidth {
      width: 100%;
    }
    &.team_hcenter {
      justify-content: center;
    }
    &.team_hstart {
      justify-content: flex-start;
    }
    &.team_hsplit {
      justify-content: space-between;
      .teamItem {
        margin: 0;
      }
    }
    &.team_hend {
      justify-content: flex-end;
    }
    &.team_center {
      align-items: center;
    }
    &.team_top {
      align-items: flex-start;
    }
    &.team_bottom {
      align-items: flex-end;
    }
    .teamItem {
      .formRow {
        margin-bottom: 0;
      }
      & ~ .teamItem {
        margin-left: 7px;
        &.teamItem_gap {
          margin-left: 25px;
        }
      }
      &.teamItem_accWidth {
        flex: 1 0 0;
      }
      &.teamItem_icon {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          height: 15px;
        }
      }
    }
  }
  
  .search-team {
    width: 300px;
    margin: 0;
    position: relative;
    .search_icon {
      color: rgba($az_black, 0.5);
      position: absolute;
      height: 15px;
      width: 15px;
      top: 0;
      bottom: 0;
      left: 10px;
      margin: auto;
    }
    input[type="text"] {
      transition: all 0.25s;
      @include input(
        $bg_color: $az_gray_2,
        $border_color: rgba($az_black, 0.5)
      );
      @include input-placeholder-font(rgba($az_black, 0.4));
      color: $az_black;
      padding-left: 40px;
      border: 0;
      &:focus {
        padding-left: 15px;
        & + .search_icon {
          @include opacity(0);
        }
      }
    }
  }