#jobs {
	.jobs_table.table_wrap {
		tbody {
			tr {
				td {
					&:hover {
						z-index: 9;
					}
					.astric {
						font-size: 18px;
					}
					.cell_in {
						.solid {
							cursor: pointer;
						}
						.flexIt {
							> span {
								white-space: nowrap;
								max-width: calc(100% - 25px);
								overflow: hidden;
								text-overflow: ellipsis;
								line-height: 1.5;
							}
							.lister {
								white-space: nowrap;
								display: block;
								max-width: 250px;
								overflow: hidden;
								text-overflow: ellipsis;
							}
							p {
								line-height: 1.5;
							}
						}
						&.serviceLocations,
						&.workerNames {
							overflow: visible;
							text-overflow: unset;
							.labelMore .mainLabel > span {
								max-width: 100%;
								display: block;
								overflow: hidden;
								text-overflow: ellipsis;
							}
							.mainLabel,
							.popOver {
								width: 100%;
							}
							.popOverTrigger {
								z-index: 9999;
								display: block;
								line-height: 1.25;
								display: block;
								max-width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
						}
						&.low {
							color: $az_low_quantity;
						}
						&.medium {
							color: $az_normal_quantity;
						}
						&.zeroQuantity {
							color: red;
						}
					}
				}
			}
		}
	}
}
.job_split_wrapper {
	.job_details {
		.textarea {
			min-height: 50px;
		}

		.formRow {
			.file {
				z-index: 0;
			}
		}
		.jobs_details_view.addJob {
			> div {
				order: 10;
				&.groupField {
					order: 1;
				}
				&.estimateField {
					order: 2;
				}
				&.jobTypeField {
					order: 3;
				}
				&.serviceDateField {
					order: 4;
				}
				&.serviceTimeField {
					order: 5;
				}
				&.estDurationField {
					order: 6;
				}
				// &.serviceTypeField {
				//   order: 7;
				// }
				// &.assignedToField {
				//   order: 6;
				// }
				@include respondToBelow(jobs) {
					order: 10;
					&:nth-child(1) {
						order: 1;
					}
					&:nth-child(2) {
						order: 2;
					}
					&:nth-child(3) {
						order: 3;
					}
					&:nth-child(4) {
						order: 4;
					}
					&:nth-child(5) {
						order: 5;
					}
					&:nth-child(6) {
						order: 6;
					}
				}
			}
			&.nogroups {
				> div {
					&.serviceTypeField {
						order: 7;
					}
					&.assignedToField {
						order: 6;
					}
					&.half_width {
						order: 6;
					}
					&.full_width {
						order: 6;
					}
				}
			}
			&.jobType_1 {
				> div {
					&.serviceTypeField {
						order: 6;
					}
					&.half_width {
						order: 7;
					}
					&.full_width {
						order: 7;
					}
					&.assignedToField {
						order: 7;
					}
				}
				&.nogroups {
					> div {
						// &.serviceTypeField {
						//   order: 7;
						// }
						// &.assignedToField {
						//   order: 6;
						// }
						&.full_width {
							order: 6;
						}
						&.serviceTimeField {
							order: 5;
						}
						&.estDurationField {
							order: 4;
						}
					}
				}
			}
		}
		.jobs_details_view.editJob {
			.serviceTypeField .divideView_items {
				min-height: 35px;
				align-items: center;
			}
			> div {
				order: 10;
				&.groupField {
					order: 1;
				}
				&.estimateField {
					order: 2;
				}
				&.jobTypeField {
					order: 3;
				}
				&.serviceDateField {
					order: 4;
				}
				&.serviceTimeField {
					order: 5;
				}
				&.estDurationField {
					order: 6;
				}
				&.serviceTypeField {
					order: 7;
				}
				// &.assignedToField {
				//   order: 6;
				// }
				@include respondToBelow(jobs) {
					order: 10;
					&:nth-child(1) {
						order: 1;
					}
					&:nth-child(2) {
						order: 2;
					}
					&:nth-child(3) {
						order: 3;
					}
					&:nth-child(4) {
						order: 4;
					}
					&:nth-child(5) {
						order: 5;
					}
					&:nth-child(6) {
						order: 6;
					}
				}
			}
			&.nogroups {
				> div {
					&.serviceTypeField {
						order: 7;
					}
					&.assignedToField {
						order: 6;
					}
					&.half_width {
						order: 6;
					}
					&.full_width {
						order: 6;
					}
				}
			}
			&.jobType_1 {
				> div {
					&.serviceTypeField {
						order: 7;
					}
					&.half_width {
						order: 7;
					}
					&.full_width {
						order: 7;
					}
					&.assignedToField {
						order: 7;
					}
				}
				&.nogroups {
					> div {
						// &.serviceTypeField {
						//   order: 7;
						// }
						// &.assignedToField {
						//   order: 6;
						// }
						&.full_width {
							order: 6;
						}
						&.serviceTimeField {
							order: 5;
						}
						&.estDurationField {
							order: 4;
						}
					}
				}
			}
			&.jobType_1,
			&.jobType_2 {
				> div {
					&.serviceTypeField {
						order: 7;
					}
					&.half_width {
						order: 7;
					}
					&.assignedToField {
						order: 7;
					}
				}
				&.nogroups {
					> div {
						// &.serviceTypeField {
						//   order: 7;
						// }
						// &.assignedToField {
						//   order: 6;
						// }
						&.full_width {
							order: 6;
						}
						&.serviceTimeField {
							order: 5;
						}
						&.estDurationField {
							order: 4;
						}
					}
				}
			}
		}
		.addJob,
		.editJob {
			.serviceTypeField .divideView_items {
				min-height: 35px;
				align-items: center;
			}
		}
	}
	.customer_details {
		.flex-grow-half {
			svg {
				color: $az_gray_6;
				height: 25px;
				width: 25px;
			}
		}
	}

	.customer_details_view_job_container {
		width: 41.66667%;
	}
	.workersGroup {
		.divideView_items {
			position: relative;
			.actions {
				position: absolute;
				right: 2px;
				top: 2px;
				color: red;
				background: #fff;
				height: 26px;
				display: flex;
				width: 26px;
				align-items: center;
				justify-content: center;
			}
		}
	}
	.col ~ .col:not(.no-border) {
		border-left: solid 2px $az_gray_2;
	}
	.azMap {
		margin: 0 -15px -15px;
		overflow: hidden;
		width: calc(100% + 30px);
		display: flex;
		height: 200px;
		position: relative;
		transform: translateY(18px);
		button {
			min-width: 0;
		}
	}
	.activityLog {
		padding-top: 0;
		border-top: 0;
		width: 100%;

		.logList {
			position: relative;
			max-height: 250px;
			overflow: auto;
			padding-right: 25px;
			padding-bottom: 15px;
			.logItem {
				display: flex;
				margin-top: 15px;
				position: relative;
				&:after {
					content: "";
					position: absolute;
					left: calc(15% + 15px);
					top: 0;
					bottom: -12px;
					border-left: dashed 1px $az_gray_3;
				}
				.logLeft {
					width: 16%;
					span {
						display: block;
						text-align: right;
						&.logDate {
							font-size: 13px;
						}
						&.logTime {
							font-size: 11px;
							margin-top: 7px;
							position: relative;
							&:before {
								content: "";
								position: absolute;
								z-index: 9;
								right: -21px;
								top: 0;
								bottom: 0;
								margin: auto;
								height: 10px;
								width: 10px;
								background: $az_yellow;
								border-radius: 5px;
								border-left: dashed 1px $az_gray_3;
							}
							&:after {
								content: "";
								position: absolute;
								z-index: 9;
								right: -42px;
								top: 0;
								bottom: 0;
								margin: auto;
								height: 10px;
								width: 10px;
								background: #fff;
								transform: rotate(45deg);
								box-shadow: -2px 2px 3px 0 rgba(0, 0, 0, 0.07);
							}
							color: $az_gray_5;
						}
					}
				}
				.logRight {
					width: 90%;
					padding-left: 30px;
					.logMain {
						padding: 10px 15px;
						@include box-shadow(#000, 0.15, 0, 1px, 5px);
						border-radius: 5px;
						margin-left: 5px;
						color: $az_gray_5;
						font-size: 11px;
						line-height: 1.5;
						.logSlug {
							font-size: 13px;
							color: $az_black;
							> * {
								display: inline-block;
							}
							.logTitle {
								font-weight: 600;
							}
						}
						.logDesc {
							margin-top: 7px;
							p {
								margin: 0;
							}
						}
					}
				}
			}
		}
	}
}
.workerStatusLog {
	.dontshow {
		display: none !important;
	}
	h4 {
		font-size: 18px;
		font-weight: 900;
		color: #fff;
		margin: 0 0 10px;
		~ h4 {
			margin: 10px 0;
		}
	}
	.card {
		padding: 5px 10px;
		> div {
			padding: 0;
		}
		.formRow {
			flex-wrap: nowrap;
			align-items: center;
			margin: 0;
			& ~ .formRow {
				margin-top: 15px;
			}
		}
	}
	.changes {
		font-size: 13px;
		.logList {
			display: flex;
			margin: 10px;
			&.init {
				.right {
					padding-top: 18px;
				}
			}
			.left {
				min-width: 75px;
				text-align: right;
				position: relative;
				&:before {
					content: "";
					width: 0;
					border-right: dashed 1px $az_gray_3;
					border-radius: 10px;
					position: absolute;
					right: -15px;
					top: 0;
					bottom: -10px;
				}
				.date {
					font-weight: 500;
				}
				.time {
					color: $az_gray_5;
					font-size: 90%;
					line-height: 1.7;
				}
			}
			.right {
				width: calc(100% - 50px);
				padding-left: 30px;

				> .status {
					position: relative;
					&:after {
						content: "";
						height: 10px;
						width: 10px;
						background: $az_yellow;
						border-radius: 10px;
						position: absolute;
						left: -20px;
						top: 4px;
					}
					& ~ span {
						margin-top: 10px;
					}
					display: block;
					> span {
						font-weight: 500;
						&.deleted {
							color: $az_gray_5;
							text-decoration: line-through;
							font-weight: 500;
						}
					}
				}
			}
		}
	}
}
.actLog.tooltip-container {
	background: $az_gray_6;
	padding: 10px 15px;
	// margin-right: 50px;
	border-radius: 5px;
	max-width: 70vw;
	.tooltip-arrow {
		&:after,
		&:before {
			border-color: #343741 transparent transparent transparent;
		}
	}
	// transition: all 0.01s;
	@include box-shadow(#000, 0.25, 0, 0, 15px, 5px);
	.divideView {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		&.divideView_inv {
			flex-direction: row-reverse;
		}
	}
	.divideView_items {
		// @include column-width(2, 30px);
		width: calc(50% - 30px / 2);
		margin-right: 0 !important;
		&.divideView_items_full {
			width: 100%;
		}

		@media all and (max-width: 850px) {
			flex-basis: 100%;
		}
	}

	.activityDifference {
		.customerDetails {
			.whiteBg {
				padding: 10px;
			}
		}
		> .row {
			> .l12 {
				min-width: 500px;
			}
		}
		.formRow_key {
			font-size: 11px;
			min-width: 40%;
		}
		.formRow_key {
			font-size: 11px;
		}
		* {
			font-size: 11px;
			line-height: 1.25;
		}
		.divideView {
			&.len-1 {
				> div {
					width: 100%;
				}
			}
		}

		.formRow {
			margin: 10px 0 0;
			flex-wrap: nowrap;
			&:last-child {
				margin-bottom: 10px;
			}
			order: 11;
			&.groupName {
				order: 2;
			}
			&.serviceDate {
				order: 3;
			}
			&.serviceTypeName {
				order: 4;
			}
			&.serviceTime {
				order: 5;
			}
			&.assignedTo {
				order: 6;
			}
			&.estimatedDuration {
				order: 7;
			}
			&.jobStatusName {
				order: 8;
			}
			&.actualDuration {
				order: 9;
			}
			&.jobSubStatusName {
				order: 10;
			}

			&.divideView_items {
				&.tags {
					order: unset;
					width: 100%;
				}
				&.description {
					order: 1;
					width: 100%;
					flex-wrap: wrap;
					.formRow {
						font-size: 14px;
						font-weight: 700;
						width: 100%;
					}
					.formRow_val_label {
						margin-top: 7px;
						padding: 7px;
						border-radius: 5px;
						color: $az_black;
						background: $az_gray_1;
						border: solid 1px $az_gray_2;
						width: 100%;
						min-height: 42px;
						max-height: 75px;
						overflow: auto;
					}
				}
			}
		}
		.description .formRow_key,
		.customerDetails .azTitle {
			font-size: 14px;
			color: $az_black;
			margin: 0;
			font-weight: 600;
		}

		.azTitle {
			font-size: 16px;
			color: #fff;
			&.formRow_key {
				font-size: 14px;
				font-weight: 600;
				color: $az_black;
			}
		}
		.whiteBg {
			// height: 100%;
			max-height: 300px;
			overflow: auto;
			padding: 0 10px;
			border-radius: 5px;
			background: #fff;
			height: 100%;
		}
		.row {
			&:not(.len-1) {
				min-width: 500px;
			}
			margin: 0 -7.5px;
			.col {
				padding: 7.5px;
			}
		}
		.customerDetails {
			.azTitle {
				margin-bottom: 10px;
			}
			.grp {
				margin-top: 15px;
				padding: 10px;
				border: solid 1px $az_gray_2;
				border-radius: 3px;
				.formRow {
					margin: 0;
					& ~ .formRow {
						margin-top: 10px;
					}
				}
			}
		}
	}
	.slick-slider {
		.slick-dots {
			display: none !important;
		}
		.slick-arrow {
			bottom: auto;
			top: 10px;
			transform: none;
			z-index: 9;
			&.slick-disabled {
				opacity: 0.3;
			}
			&.slick-next {
				right: 5px;
			}
			&.slick-prev {
				left: 5px;
			}
			svg {
				color: #fff;
				height: 10px;
				width: 10px;
			}
		}
		.slick-list .slick-slide {
			padding: 0;
			> div {
				box-shadow: none;
				padding: 7px;
				.multiDayDates {
					width: calc(100% - 30px);
					@include box-shadow();
					table {
						margin: 0;
						position: relative;
						z-index: 1;
						width: 100%;
						th,
						td {
							padding: 10px 7px;
							letter-spacing: 0;
							text-align: left;
							&:first-child {
								padding-left: 30px;
							}

							&:last-child {
								padding-right: 30px;
							}
						}
						.highlightTd {
							b {
								font-weight: bold;
								font-size: 14px;
								color: $az_gray_5;
							}
						}
						th {
							background: $az_gray_6;
							white-space: nowrap;
							color: #fff;
						}
					}
				}
			}
		}
	}
}

.dialogOverlay_viewJobs {
	@include respondToAbove(xlg) {
		.divideView_items {
			width: calc(45% - 30px / 2) !important;
		}
		.formRow_key {
			width: 30%;
		}
		.formRow_val {
			width: 70% !important;
		}
	}
	.solid:not(.gray) {
		cursor: pointer;
	}
	.back-icon {
		margin-top: 15px;
	}
	.row {
		position: relative;
	}

	.activity_log {
		margin-top: 15px;
		padding-right: 0px !important;
		border-left: 0 !important;
		display: flex;
		flex-wrap: wrap;
	}

	.largeSpacing {
		@include respondToBelow(sm) {
			max-width: 100%;
			margin-left: 0px;
			margin-right: 0px;
		}

		@include respondToBelow(sm) {
			.activity_log {
				order: 3;
				padding-left: 0px !important;
			}
			.customer_details_view_job_container {
				margin-top: 15px;
				position: relative;
				order: 2;
				align-self: flex-start;
				width: 100%;
				padding-left: 0px !important;
				padding-right: 0px !important;
				margin-bottom: 0px;
			}
			.job_description {
				padding-left: 0px !important;
			}
		}
	}
}

.dialogOverlay_viewJobs,
.darkLayout {
	.dialogContent_viewJobs,
	.dialogContent {
		background: $az_gray_6;
		padding: 15px;
		.customer_details {
			background: transparent !important;
		}
		.dialogHeader {
			color: #fff;
			.copyDrop {
				display: flex;
				align-items: center;
				svg {
					&:first-child {
						height: 25px;
						width: 25px;
					}
					&:last-child {
						margin: 0 25px 0 0;

						height: 15px;
						width: 15px;
					}
				}
			}

			.actions {
				padding: 0;
			}
			svg {
				height: 20px;
				width: 20px;
				color: #fff;
				&.close {
					height: 30px;
					width: 30px;
					color: #fff;
				}
				& ~ * {
					margin-left: 25px;
				}
			}
		}
		.formSection {
			background: #fff;
			padding: 15px;
			border-radius: 10px;
			& ~ .formSection {
				margin-top: 15px;
			}
		}
		.viewmode {
			align-self: self-start;
			.fileItem {
				margin-top: 10px;
				width: 100%;
				display: flex;
				.icon {
					margin-right: 10px;
					svg {
						width: 25px;
						height: 25px;
					}
				}
				.details {
					display: flex;
					flex-direction: column;
					width: calc(100% - 35px);
					.size {
						margin-top: 5px;
						font-size: 10px;
					}
				}
				a {
					color: $az_cyan;
					font-size: 13px;
					max-width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}

		.job_split_wrapper {
			.col {
				&:first-child {
					padding-right: 0;
				}
				&:last-child {
					border: 0;
					padding-left: 15px;
					.formSection {
						height: 100%;
					}
				}
			}
		}
	}
}
.locationCarousel {
	.slick_carousel {
		.location {
			// padding-bottom: 15px;
		}
	}
	// .react-select__menu {
	//   margin-top: -30px;
	//   > div {
	//     max-height: 160px;
	//   }
	// }
	// .formRow ~ .formRow {
	//   .react-select__menu {
	//     z-index: 99999;
	//     margin-top: -70px;
	//   }
	// }
}

.mediaActivity {
	display: flex;
	flex-wrap: wrap;

	.entry {
		width: 100%;
		&.half {
			width: 50%;
			.file {
				width: 100% !important;
			}
		}
		.title {
			width: 100%;
			margin: 15px 0 0;
			font-size: inherit;
		}
		.fileList {
			display: flex;
			flex-wrap: wrap;
			.file {
				width: calc(33.333% - 30px);
				margin-right: 15px;
				cursor: default;
				height: auto;
				margin-top: 10px;
				&:nth-child(3n) {
					margin-right: 0;
				}
				display: flex;
				align-items: center;
				.fileDetails {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					max-width: calc(100% - 30px);
					a {
						color: $az_cyan;
					}
					a,
					span {
						width: 100%;
						max-width: 100%;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						display: inline-block;
					}
					small {
						color: $az_gray_5;
					}
				}
				.fileIcon {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 10px;
					min-width: 30px;
					> span {
						position: relative;
						> span {
							position: absolute;
							font-size: 6px;
							text-transform: uppercase;
							color: #fff;
							font-weight: 900;
							left: 0;
							right: 0;
							justify-content: center;
							bottom: 0;
							top: 0;
							align-items: center;
							display: flex;
							transform: translateY(2px);
						}
					}
				}
				&.deleted {
					.fileIcon {
						opacity: 0.5;
					}
					.fileDetails {
						a,
						small,
						span {
							color: $az_gray_4;
						}
					}
				}
			}
		}
	}
}

.dialogContent_viewJobs {
	.job_split_wrapper {
		.job_details {
			.textarea {
				min-height: 50px;
			}
			.formRow {
				.file {
					z-index: 0;
				}
			}
			.jobs_details_view.viewJob {
				> div {
					order: 15;
					&.groupField {
						order: 1;
					}
					&.estimateField {
						order: 2;
					}
					&.serviceDateField {
						order: 3;
					}
					&.estDurationField {
						order: 4;
					}
					&.serviceTimeField {
						order: 5;
					}
					&.serviceTypeField {
						order: 6;
					}
					&.jobTypeField {
						order: 7;
					}
					&.assignedToField {
						order: 8;
					}
					&.repeatTypeField {
						order: 9;
					}
					@include respondToBelow(jobs) {
						order: 10;
						&:nth-child(1) {
							order: 1;
						}
						&:nth-child(2) {
							order: 2;
						}
						&:nth-child(3) {
							order: 3;
						}
						&:nth-child(4) {
							order: 4;
						}
						&:nth-child(5) {
							order: 5;
						}
						&:nth-child(6) {
							order: 6;
						}
					}
				}
			}
		}
	}
	.azTitle .trigger .solid {
		font-size: 14px;
		font-weight: 500;
	}
}

body {
	.tooltip-container {
		&.repeatType {
			margin: 0;
		}
		&.upcomingJobs_popOver,
		&.workerNames_popOver {
			padding: 0;
			margin: 0;
			.table_wrap {
				margin: 0;
				width: 100%;
				padding: 0 !important;
				.table {
					.tr {
						.td,
						.th {
							min-width: 110px;
							&:last-child {
								min-width: 125px;
							}
						}
					}
					.thead {
						background: $az_gray_6;
						border: 0;
						.tr {
							.th {
								padding-bottom: 10px;
								color: #fff;
							}
						}
					}
					.tbody {
						min-height: 0;
						.tr {
							cursor: default;
							&:before {
								content: none;
							}
							.low {
								color: $az_low_quantity;
							}
							.medium {
								color: $az_normal_quantity;
							}
							.zeroQuantity {
								color: red;
							}
						}
					}
					.tbody,
					.thead {
						padding-left: 0;
						padding-right: 0px;
					}
				}
			}
		}
		&.workerNames_popOver {
			max-width: 350px !important;
			overflow: unset;
			.td {
				.workerNames {
					display: flex;
					flex-wrap: wrap;
					flex-direction: column;
				}
			}
		}
	}
}
.dialogOverlay_alert {
	&.editSaveModal {
		.dialogHeader {
			font-size: 24px;
			font-weight: 700;
		}
		.dialogBody {
			.radio {
				.input_title {
					line-height: 1.5;
					font-weight: 400;
					margin-top: 2px;
				}
			}
		}
		.formRow .formRow_actions {
			justify-content: space-between;
			.button--plain {
				align-items: center;
				display: flex;
				background: $az_cyan;
				border: none;
				color: #fff;
			}
		}
	}
}
.dialogOverlay_addJobs {
	.azTitle {
		margin-bottom: 0;
	}
	&.hideModal {
		display: none;
	}

	.formRow_actions {
		.button.button_withicon.button_withicon--left {
			padding-right: 15px;
			svg {
				margin-right: 0;
			}
		}
	}
}

.customer_details,
.customer_details_view_job_container {
	@include respondToAbove(xlg) {
		.divideView_items,
		.half_width {
			width: calc(45% - 30px / 2) !important;
			&.divideView_items_full {
				width: 100% !important;
			}
			&.formRow {
				.formRow_key {
					width: 30%;
				}
				.formRow_val {
					width: 70% !important;
				}
			}
		}
	}
	.azTitle {
		margin-bottom: 15px !important;
	}
}

.full_width {
	width: 100% !important;
	.half_width_ST {
		display: flex;
		width: calc(50% - 30px / 2);
	}
}

.half_width {
	width: calc(50% - 30px / 2);
	.half_width_ST {
		display: flex;
		width: 100%;
	}
	.serviceTypeField {
		width: 100% !important;
	}
}

.multiDay_popOver {
	.table_wrap {
		border-radius: 0;
		.table {
			.tbody {
				padding: 0;
				.tr {
					.td {
						padding: 5px 15px;
						font-weight: 500;
						&:last-child {
							color: #666666;
						}
					}
				}
			}
			.thead {
				.tr {
					.th {
						padding: 5px 15px !important;
					}
				}
			}
		}
	}
}

.td.tags,
td.tags,
.tagToolTip {
	.tagSelector {
		.tagList {
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			.tagItem {
				margin: 0 !important;
				padding: 5px 10px;

				&.solid {
					color: $az_cyan;
				}
				& ~ .tagItem {
					margin-top: 8px !important;
				}
				.tagIcon {
					color: #43c087;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					transform: scale(0.9) rotateY(180deg);
					margin-right: 5px;
				}
			}
		}
	}
}
.tooltip-container.tagToolTip {
	max-width: none;
	min-width: 0;
}
.th.tags,
th.tags {
	padding-right: 0 !important;
	.cell_in {
		min-width: 150px !important;
		width: 150px !important;
	}
}
.td.tags,
td.tags {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	padding-right: 0 !important;
	.cell_in {
		overflow: visible !important;
		min-width: 150px !important;
		width: 150px !important;
	}
	.tagList {
		padding: 6px 0;
	}
}
.tooltip-container.multiDay_popOver {
	overflow: auto;
	border-radius: 0 0 5px 5px;
	max-height: 50vh;
}
.tooltip-container.mdayWorkers {
	max-height: 50vh;
	overflow: auto;
	border-radius: 0 0 5px 5px;
	.mdayList {
		.mdayItem {
			span {
				display: block;
				&.title {
					background: #343740;
					padding: 10px;
					color: #fff;
					margin: 5px -10px 10px;
				}
			}
			&:first-child {
				span.title {
					margin-top: -10px;
				}
			}
		}
	}
}

reach-portal
	[data-reach-dialog-overlay].dialogOverlay
	[data-reach-dialog-content].dialogContent
	.divideView {
	> .divideView_items_full.divideView_items.divideView.mediaBlock {
		/*box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);*/
		/*border-radius: 6px;*/
		padding: 10px 15px;
		margin-left: -15px;
		margin-right: -15px !important;
		width: calc(100% + 30px) !important;
		margin-bottom: 15px;
		.formRow {
			margin: 0 !important;
			.formRow_val {
				.file {
					height: auto;
				}
			}
		}
		.fileItem {
			margin-top: 0 !important;
			display: flex;
			& ~ .fileItem {
				margin-top: 10px !important;
			}
			.icon {
				margin-right: 10px;
				svg {
					width: 25px;
					height: 25px;
				}
			}
			.details {
				display: flex;
				flex-direction: column;
				width: calc(100% - 35px);
				.size {
					/*margin-top: 5px;*/
					font-size: 10px;
				}
			}
			a {
				color: $az_cyan;
				font-size: 13px;
				max-width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
		.fileItem {
			display: flex;
			align-items: center;
			margin-top: 10px;
			.icon {
				position: relative;
				width: 25px;
				margin-right: 10px;
				img {
					width: 25px;
				}
				> span {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					color: #fff;
					transform: translateY(3px);
					font-size: 7px;
					font-weight: 600;
					display: flex;
					align-items: center;
					text-transform: uppercase;
					justify-content: center;
				}
			}
			svg {
				height: 30px;
				width: 30px;
				cursor: pointer;
			}
			.details {
				width: calc(100% - 60px);
				display: flex;
				flex-direction: column;
				padding: 0;
				.name {
					color: $az_cyan;
					font-size: 13px;
					max-width: 100%;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					/*margin-bottom: 5px;*/
				}

				.size {
					display: flex;
					align-items: center;
					.size_num {
						width: 20%;
						font-size: 10px;
						color: $az_gray_5;
						margin-right: 10px;
					}
					.progress {
						position: relative;
						background: $az_gray_2;
						width: calc(80% - 10px);
						height: 5px;
						border-radius: 5px;
						overflow: hidden;
						.progress_status {
							background: $az_cyan;
							position: absolute;
							top: 0;
							bottom: 0;
							left: 0;
							z-index: 9;
							width: 0;
						}
					}
				}
			}
		}
		&.viewmode {
			margin: 0;
			width: 100% !important;
			justify-content: flex-end;
			.divideView_items {
				width: 42.5%;
				&.formRow--flex_row {
					width: 15%;
				}
				&.fileItem {
					margin-top: 0 !important;
					& + .fileItem {
						& ~ .fileItem {
							margin-top: 10px !important;
						}
					}
				}
			}
		}
	}
}

.copyDrop_popover {
	padding: 15px !important;
	min-width: 200px !important;
	.copyDrop {
		.formRow {
			button {
				width: 100%;
			}
			&:last-child {
				margin: 0;
			}
		}
	}
}

.mAuto {
	margin: auto;
}

.row.largeSpacing .col.p0 {
	padding: 0;
}

.tagsAddIcon.button.button_icon {
	width: 20px;
	height: 20px;
	margin-left: 20px;
}

.pt-20 {
	padding-top: 20px;
}

reach-portal [data-reach-dialog-overlay].dialogOverlay_addJobs.isJobsNewUI {
	top: 100px;
	padding-top: 0px;
	background: none;
	height: 86vh;
	overflow: scroll;
	padding-bottom: 0px;

	&.large [data-reach-dialog-content],
	&.small [data-reach-dialog-content] {
		width: 100vw;
		border-radius: 0;
		height: 100%;
		overflow: hidden;
	}
}

.assignHeader {
	width: 18%;
	margin-right: 7px !important;
}

.wt-29-5p {
	width: 29.5%;
}

.isJobsNewUI {
	.addWorkers {
		margin-left: 20px;
		padding-top: 5px;
		span {
			margin-right: 10px;
		}
	}
	.straight-tabs .tabPanels {
		height: 64vh;
		overflow: scroll;
		padding: 0 15px;
	}

	&.addNewJobScreen {
		.dialogHeader,
		.straight-tabs .tabList {
			width: 65%;
			margin: auto;
		}

		.straight-tabs.slot-tabs .tabs .tabList {
			width: 100%;

			&.scrollable {
				width: 92%;
			}
		}
	}
}

.t-Right {
	text-align: right;
}

.f-Right {
	float: right;
}

.dialogOverlay_addJobs {
	.job_split_wrapper .activityLog .logList {
		max-height: 800px;
	}
}

.viewJobsForPrint {
	.hideForScreen {
		display: none;
	}
}

@media print {
	html,
	body {
		height: initial !important;
		overflow: initial !important;
		-webkit-print-color-adjust: exact;
	}
	@page {
		size: auto;
		margin: 12mm;
	}
	.viewJobsForPrint {
		.hideForPrint {
			display: none;
		}
		.hideForScreen {
			display: block;
		}

		.formRow.formRow--flex_row .formRow_key {
			width: 30%;
		}

		.formRow.formRow--flex_row .formRow_key + .formRow_val,
		.formRow.formRow--flex_row .formRow_key + .formRow_val_label {
			width: 70%;
		}

		.azMap {
			display: none;
		}

		.slick-slide {
			display: block !important;
		}

		.slick-list {
			height: auto !important;
		}

		.slick-track {
		    width: auto !important;
		    height: auto !important;
		    transform: none !important;
		    .slick-slide {
		        width: auto !important;
		    }
		}
		.slick-arrow, .slick-slider .slick-dots {
			display: none !important;
		}

		.fileItem {
			img, .icon {
				display: none;
			}
			.details .size {
				display: block;
			}
		}

		.headerBar {
			padding: 10px;
			background: $az_black;
			float: right;
		}
	}

	body .viewJobsForPrint {
		.table_wrap {
			padding: 5px !important;
			margin: 0 -50px;
			width: calc(100% + 160px);
			max-width: calc(100% + 160px);

			.thead .tr .th:first-child {
				width: 100px !important;
			}

			.workerNames span {
				display: block;
			}
		}

		.multiDayDetail .table_wrap {
			width: calc(100% + 60px);
			max-width: calc(100% + 60px);
		}
	}
}
