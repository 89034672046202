.jobsConfiguration {
  .react-select-container {
    min-width: 200px;
  }
  .divideView {
    .formRow {
      flex-wrap: nowrap;
      .formRow_key {
        white-space: normal;
        width: auto;
        flex: unset;
        padding-right: 16px;
      }
      .formRow_val {
        width: auto;
      }
    }
  }
}
