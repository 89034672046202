.timePicker {
	position: relative;
	.clearIco {
		position: absolute;
		height: 16px;
		width: 16px;
		top: 8px;
		right: 8px;
		cursor: pointer;
		padding: 0;
		pointer-events: auto;
		cursor: pointer;
		&::after {
			cursor: pointer;
			border-radius: 50%;
			height: 16px;
			width: 16px;
			line-height: 1;
			text-align: center;
			display: table-cell;
			vertical-align: middle;
			content: "\D7";
			color: #bbbbbb;
			background: transparent;
			padding: 0;
			font-size: 20px;
			transform: translateY(-3px);
		}
	}
	.timeDrop {
		position: absolute;
		display: flex;
		left: 0;
		right: 0;
		top: 100%;
		z-index: 9;
		background: $az_white;
		border: solid 1px #ddd;
		animation: scalo 0.25s;
		transform-origin: 0 0;
		> * {
			width: 50px;
			height: 150px;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			&:hover {
				overflow: auto;
			}
		}
	}
	span {
		&:hover {
			// background: $az_gray_2;
		}
		&.active {
			background: $az_cyan;
			color: $az_white;
		}
	}
}

@keyframes scalo {
	from {
		transform: scale(0);
	}
	to {
		transform: scale(1);
	}
}
.rc-time-picker {
	width: 100%;
	&,
	* {
		font-family: inherit;
	}
	.rc-time-picker-input {
		text-transform: uppercase;
	}
	.close_icon {
		position: absolute;
		right: 5px;
		top: 0;
		bottom: 0;
		margin: auto;
		display: flex;
		color: #ccc;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		svg {
			height: 15px;
			width: 15px;
		}
	}
}
.rc-time-picker-panel-combobox {
	display: flex;
}
.rc-time-picker-panel {
	padding-top: 3px;
	z-index: 999999 !important;
	li {
		text-transform: uppercase;
		&.rc-time-picker-panel-select-option-selected {
			background: $az_cyan;
			color: #fff;
			&:hover {
				color: $az_cyan;
			}
		}
	}
	* {
		font-family: inherit;
	}
}

.rc-time-picker-panel {
	.rc-time-picker-panel-input-wrap {
		padding: 0;
		.rc-time-picker-panel-input {
			font-size: 14px;
			text-transform: uppercase;
			height: 30px;
			padding: 5px 10px;
		}
	}
}
.rc-time-picker-panel,
.rc-time-picker-panel * {
	font-family: inherit;
}
