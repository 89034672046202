.schedule-dispatch-split-screen{
  position: relative;
  height: 72vh;
  .vertical-right-handle{
    width: 12px !important;
    right:0px !important;
    background: #A4A5A9;
    display: flex;
    align-items: center;
    z-index: 100089;
  }
  .horizontal-botton-handle{
    height: 12px !important;
    bottom: 0px !important;
    background: #A4A5A9;
    display: flex;
    justify-content: center;
    z-index: 100089;
  }

  .custome-resize-handle{
    height: 100px;
    background: transparentize($az_gray_6, 0.3);
    display: flex;
    align-items: center;
    width: 12px;
    svg{
      color: $az_white;
      width: 12px;
      height: 12px;
    }
    &.horizontal-handle{
      height: 100%;
      width: 100px;
      justify-content: center;
      svg{
        transform: rotate(0deg) !important;
      }
    }
  }
  .drag-icon{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    cursor: grab;
    .overlay{
      position: absolute;
      width: 32px;
      height: 32px;
      background: transparent;
      z-index: 2
    }
    img{
      z-index: 1
    }
  }
  .dragged{
    opacity: 0.3;
  }

  .main-content-container{
    position: absolute;
    width: inherit;
    height: inherit;
    z-index: 4;
  }

  .background{
    position: absolute;
    width: inherit;
    height: inherit;
    z-index: 1;
  }
}
