.customer_details {
	.phone_number {
		margin-left: 5px !important;
	}
	.phone_number > .formRow_val {
		min-width: 50px !important;
		margin-left: 0px !important;
	}

	.phone_number > .formRow_key {
		width: 0px !important;
		flex-grow: 0 !important;
		padding-right: 10px;
		// @include respondToBelow(xs) {
		//     width: 0px !important;
		// }
	}

	.save_button {
		@include respondToBelow(s) {
			margin-left: auto;
		}
	}

	.formSection {
		// .formRow.formRow--flex_row {
		// 	&.divideView_items {
		// 		width: calc(48% - 30px / 2) !important;
		// 	}
		// 	@include respondToAbove(xlg) {
		// 		&.divideView_items {
		// 			width: calc(45% - 30px / 2) !important;
		// 		}
		// 		.formRow_key {
		// 			width: 30%;
		// 		}
		// 	}
		// }
		&.customer_details_view {
			.azTitle {
				width: 100%;
			}
		}
		.title {
			align-items: flex-start;
			.group {
				@include respondToBelow(mid-size) {
					width: 51vw;
				}
				@include respondToBelow(xxs) {
					width: 55vw;
				}
			}
		}

		.title {
			margin-bottom: 10px;
		}
	}
	form .azTitle {
		margin-bottom: 10px;
	}
}

.dialogHeader .azTitle {
	@include respondToBelow(s) {
		width: 80%;
	}
}

p.actions {
	margin-left: 0px;
	// flex-grow: 1;
	// justify-content: space-between;
}
.dialogOverlay_addCustomers {
	.formSection {
		.formRow.formRow--flex_row {
			&.divideView_items {
				width: calc(48% - 30px / 2) !important;
			}
			@include respondToAbove(xlg) {
				&.divideView_items {
					width: calc(45% - 30px / 2) !important;
				}
				.formRow_key {
					width: 30%;
				}
			}
		}
	}
}

.customer_payment {
	.text_nowrap {
		white-space: nowrap;
	}

	#Loader {
		border-radius: 6px;
	}

	&__card {
		margin-bottom: 10px;

		@media only screen and (max-width: $screen-xlg-min) {
			margin-right: 25px !important;
			width: calc((100% / 3) - 25px) !important;
		}

		@media only screen and (max-width: $screen-lg-min) {
			width: calc((100% / 2) - 25px) !important;
		}

		@media only screen and (max-width: $screen-mid-size) {
			width: 100% !important;
		}
	}

	&__card_title {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 10px;
	}

	&__card_icon {
		margin-right: 10px;
	}

	&__card_date {
		margin-left: auto;
	}

	&__error {
		color: $az_maroon;
		align-self: center;
	}

	&__transactions_content {
		margin-top: 10px;
	}

	&__tooltip {
		font-size: 13px;
		font-weight: 500;
		background: $az_white;
	}

	&__btn_disabled {
		cursor: initial !important;

		svg {
			color: $az_gray_4;
		}
	}
}
