.schedule-dispatch-worker-one-day {
  transition: 0.5s all ease-in-out;
  position: relative;
  width: 100%;
  padding-top: 4px;
  background: $az_white;
  height: 64vh;
  overflow: scroll;
  .worker-one-day-grid-container {
    display: flex;
    .time-container {
      width: 50px;
      padding-right: 8px;
      margin-top: -5px;
      min-width: 50px;
      position: sticky;
      left: 0;
      background: $az_white;
      z-index: 1;
    }
    .worker-grid-card-container {
      border-top: 2px solid $az_gray_2;
      width: 100%;
      height: 65px;
      max-height: 65px;
      .time-slot-container {
        display: flex;
        flex-direction: column;
        height: inherit;
        .timeslot {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .worker-one-day-present-time {
    position: absolute;
    width: 100%;
    border-bottom: 3px solid $az_present_time_color;
    .present-time-background {
      background: linear-gradient(
        0deg,
        rgba(168, 168, 168, 0.5) 0%,
        rgba(168, 168, 168, 0) 100%
      );
    }
  }
  .present-time-side-line {
    width: 50px;
    border-bottom: 3px solid $az_present_time_color;
    margin-top: -6px;
  }
}
