.listingsPage {
  padding: 10px 35px;
  margin-top: 15px;
  background: #fff;
  border-radius: 15px;
  @extend .card;

  .listing_header {
    color: $az_black;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0;
    border-bottom: 3px solid $az_gray_1;
    .azTitle {
      font-size: 28px;
    }

    @include respondToBelow(sm) {
      flex-wrap: wrap;
    }

    .stat_item,
    .title {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      .sub_title {
        display: block;
        font-size: 14px;
        white-space: nowrap;
        color: $az_gray_5;
      }
    }
    .description {
      margin: 0 75px;

    @include respondToBelow(sm) {
      margin: 20px 0px 0px 0px;
      flex-basis: 50%;
      justify-content: flex-start;
    }

    @include respondToBelow(mid-size) {
      margin: 20px 0px 0px 0px;
      flex-basis: 100%;
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: $az_cyan;
      margin-left: auto;
      li {
        cursor: pointer;
        line-height: normal;
        padding: 2px 5px;

        @include respondToBelow(mid-size) {
          padding-left: 0px;
        }
        & ~ li { 
          & ~ li { 
            @include respondToBelow(sm) {
              padding-right: 0px;
            }
          }
        }
      }
      &.normal {
        color: inherit;
      }

      @include respondToBelow(sm) {
        flex-basis: 50%;
        margin-top: 20px;
      }

      @include respondToBelow(mid-size) {
        flex-basis: 100%;
        margin: 20px 0px 0px 0px;
        order: 1;
        justify-content: space-between;
      }
      .button--borderLess {
        @include respondToBelow(mid-size) {
          padding-left: 0px;
          text-align: left;
        }
      }
    }
  }

  .listing_table_header {
    padding: 25px 0 0;
    display: flex;
    justify-content: space-between;

    @include respondToBelow(mid-size) {
      flex-wrap: wrap;
    }

    .search-group {
      width: 300px;
      margin: 0;
      position: relative;
      .search_icon {
        color: rgba($az_black, 0.5);
        position: absolute;
        height: 15px;
        width: 15px;
        top: 0;
        bottom: 0;
        left: 10px;
        margin: auto;
      }
      input[type="text"] {
        transition: all 0.25s;
        @include input(
          $bg_color: $az_gray_2,
          $border_color: rgba($az_black, 0.5)
        );
        @include input-placeholder-font(rgba($az_black, 0.4));
        color: $az_black;
        padding-left: 40px;
        border: 0;
        &:focus {
          padding-left: 15px;
          & + .search_icon {
            @include opacity(0);
          }
        }
      }
      @include respondToBelow(mid-size) {
        flex-basis: 100%;
      }
    }
    .switch {
      display: flex;
      align-items: center;
      margin-right: 10px;
      font-size: 14px;
      color: $az_gray_5;
      .input_title {
        margin-top: 5px;
      }
      @include respondToBelow(mid-size) {
        flex-basis: 100%;
        margin: 20px 0px 0px 10px;
      }
    }
  }
  .listing_table_stats {
    color: $az_black;
    list-style: none;
    display: flex;
    align-items: flex-start;
    padding: 10px 0px;
    margin: 10px 0;
  }
  .stats_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    li {
      display: flex;
      flex-direction: column;
      & ~ li {
        margin-left: 25px;
        @include respondToBelow(mid-size) {
          flex-basis: 100%;
          margin: 20px 0px 0px 0px;
          justify-content: flex-start;
        }

        @include respondTo(md) {
          margin-top: 15px;
          margin-left: 0px;
        }

        @include respondTo(sm) {
          margin-left: 0px;
        }
      }
      label {
        display: block;
        white-space: nowrap;
        font-weight: 500;
        &.stat_count {
          font-size: 45px;
          padding: 5px 0;
        }
        &.stat_label {
          margin-top: 10px;
          font-size: 25px;
        }
      }
      @include respondToBelow(mid-size) {
        flex-basis: 100%;
        justify-content: flex-start;
      }
    }
    @include respondToBelow(mid-size) {
      flex-wrap: wrap;
    }

    @include respondToBelow(sm) {
      flex-basis: 100%;
    }

    @include respondTo(md) {
      flex-wrap: wrap;
    }
  }

  // .table_wrap {
  //   padding: 25px 5px;
  //   margin: 0 -30px;
  //   width: calc(100% + 60px);
  //   max-width: calc(100% + 60px);
  //   table {
  //     thead,
  //     tbody {
  //       display: block;
  //       padding: 0 30px;
  //     }
  //     tbody {
  //       padding: 5px 30px;
  //     }
  //   }
  // }
}

// #customersListing {
//   height: calc(100vh - 110px);
//   #customers {
//     display: flex;
//     flex-direction: column;
//     height: 100%;
//     .table_wrap {
//       flex-grow: 1;
//       display: flex;
//       table {
//         display: flex;
//         flex-direction: column;
//         tbody {
//           flex-grow: 1;
//         }
//       }
//     }
//   }
// }
