@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap");

.permissionRestricted {
	@include respondToBelow(sm) {
		padding: 30px;
	}
	.topSection {
		max-width: 70%;
		@include respondToBelow(sm) {
			max-width: 100%;
		}
		h1 {
			font-family: "Rajdhani", sans-serif;
			line-height: 1.5;
			font-size: 70px;
			@include respondToBelow(sm) {
				font-size: 36px;
			}
		}
		p {
			font-family: "Rajdhani", sans-serif;
			line-height: 1.5;
			font-size: 36px;
			@include respondToBelow(sm) {
				font-size: 18px;
			}
		}
		.button--gradient {
			min-width: 150px;
			border-radius: 0;
			margin-top: 25px;
			font-size: 18px;
			font-weight: 500;
			@include respondToBelow(sm) {
				font-size: 14px;
			}
		}
	}
	.bottomSection {
		flex: 1;
		display: flex;
		justify-content: flex-end;
		img {
			height: 55vh;
			max-width: 100%;
			transform: translateY(-50px);
			@include respondToBelow(sm) {
				transform: translateY(0px);
				padding: 30px 0;
			}
		}
	}
}
