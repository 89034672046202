.group {
  display: flex;
  margin-left: 0;
  width: auto;
  @include respondToBelow(sm) {
    flex-wrap: wrap;
  }
  &.group_fullWidth {
    width: 100%;
  }
  &.group_hcenter {
    justify-content: center;
  }
  &.group_hstart {
    justify-content: flex-start;
  }
  &.group_hsplit {
    justify-content: space-between;
    .groupItem {
      margin: 0;
    }
  }
  &.group_hend {
    justify-content: flex-end;
  }
  &.group_center {
    align-items: center;
  }
  &.group_top {
    align-items: flex-start;
  }
  &.group_bottom {
    align-items: flex-end;
  }
  .groupItem {
    .formRow {
      margin-bottom: 0;
    }
    & ~ .groupItem {
      margin-left: 7px;
      &.groupItem_gap {
        margin-left: 25px;
      }
    }
    &.groupItem_accWidth {
      flex: 1 0 0;
    }
    &.groupItem_icon {
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        height: 15px;
      }
    }
  }
}
