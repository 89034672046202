.toasts {
  bottom: 50px;
  position: fixed;
  right: 25px;
  min-width: 250px;
  max-width: 350px;
  z-index: 999999999;
  display: flex;
  flex-direction: column-reverse;
  pointer-events: none;

  @include respondToBelow(sm) {
    top: 25px;
    left: 25px;
    flex-direction: column;
  }
  .toast {
    pointer-events: auto;
    align-items: flex-start;
    display: flex;
    animation: flyin 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    text-align: center;
    border-radius: 0;
    & ~ .toast {
      margin-bottom: 15px;
    }
    .toast__content {
      flex: 1 1 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      border-left: solid 7px;
      background: linear-gradient(to bottom, #f7f7f7, #ffffff);
      padding: 10px;
      padding-right: 50px !important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      @include box-shadow;
      line-height: 1.25;
      position: relative;
      .icon {
        height: 25px;
        margin-right: 10px;
      }
      svg {
        height: 25px;
        width: 25px;
        margin: 0 10px;
      }
      > * {
        position: relative;
        z-index: 9;
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 0;
        background: #fff;
      }
    }
    position: relative;
    .close-x {
      @include close-icon($az_gray_3);
      right: 0;
      bottom: 0;
      margin: auto;
      top: 0;
    }
    .toast__dismiss {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: transparent;
      border: 0;
      color: inherit;
      cursor: pointer;
      display: block;
      flex: 0 0 auto;
      font: inherit;
      padding: 0;
    }
    &.info {
      .toast__content {
        color: $az_blue;
        border-color: $az_blue;
        &:after {
          background: #fff;
        }
      }
      .close-x {
        &:after,
        &:before {
          background: $az_gray_3;
        }
      }
    }
    &.success {
      .toast__content {
        color: $az_green;
        border-color: $az_green;
        &:after {
          background: #fff;
        }
      }
      .close-x {
        &:after,
        &:before {
          background: $az_gray_3;
        }
      }
    }
    &.error {
      .toast__content {
        color: $az_red;
        border-color: $az_red;
        &:after {
          background: #fff;
        }
      }
      .close-x {
        &:after,
        &:before {
          background: $az_gray_3;
        }
      }
    }
    &.warning {
      .toast__content {
        color: $az_yellow;
        border-color: $az_yellow;
        &:after {
          background: #fff;
        }
      }
      .close-x {
        &:after,
        &:before {
          background: $az_gray_3;
        }
      }
    }
    &.primary {
      .toast__content {
        color: rgba(0, 0, 0, 0.85);
        border-color: rgba(0, 0, 0, 0.85);
        &:after {
          background: #fff;
        }
      }
      .close-x {
        &:after,
        &:before {
          background: $az_gray_3;
        }
      }
    }
  }
}

@keyframes flyin {
  from {
    @include opacity(0);
    pointer-events: none;
    transform: translateY(100%);
  }
  to {
    @include opacity(1);
    pointer-events: auto;
    transform: translateY(0);
  }
}
