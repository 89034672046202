#schedule-update-modal {
	width: 60vw;
	.update-job-modal-job-to-worker {
		.key-value-change-container {
			.key-value-row {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				&.second-row {
					align-items: center;
				}
				&.third-row {
					align-items: center;
					justify-content: unset;
					.key-value-container {
						width: 100%;
					}
				}
				.key-value-container {
					display: flex;
					align-items: flex-end;
					width: 100%;
					justify-content: space-between;
					margin-right: 16px;
					margin-bottom: 8px;
					.date-label {
						font-weight: 500;
						font-size: 16px;
						width: 150px;
						&.worker-label {
							width: 123px;
						}
					}
					.dateLabel {
						margin-left: 8px;
					}
					.value-container {
						width: 80%;
					}
				}

				.icon-arrow {
					display: flex;
					// width:10%;
					margin-top: 12px;
					margin-left: 8px;
					margin-right: 16px;
					.dots {
						margin-right: -5px;
					}
					.icon-arrow-schedule {
						margin-top: 1px;
					}
				}
				.new-value-container {
					display: flex;
					align-items: baseline;
					.date_picker {
						margin-right: 8px;
						width: 120px;
					}
					.time-picker {
						width: 100px;
						margin-bottom: 0px;
					}
					.time-duration {
						width: 228px;
						.groupItem {
							width: 85px;
						}
					}
				}
			}
		}
		.add-worker-container {
			padding: 0px 25px;
			margin: 0px 25px;
			.DND-container {
				display: flex;
				align-items: baseline;
			}
			.schedule-config-add-button {
				color: $az_cyan;
			}
		}
	}
	.update-job-modal-worker-to-worker {
        .key-value-change-container {
			.key-value-row {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				&.second-row {
					align-items: center;
				}
				&.third-row {
					align-items: center;
					justify-content: unset;
					.key-value-container {
						width: auto;
					}
				}
				.key-value-container {
					display: flex;
					align-items: flex-end;
					width: 100%;
					justify-content: space-between;
					margin-right: 16px;
					margin-bottom: 8px;
					.date-label {
						font-weight: 500;
						font-size: 16px;
						width: 150px;
						&.worker-label {
							width: 123px;
						}
					}
					.dateLabel {
						margin-left: 8px;
					}
					.value-container {
						width: 80%;
					}
				}

				.icon-arrow {
					display: flex;
					// width:10%;
					// margin-top: 12px;
					// margin-left: 8px;
					margin-right: 16px;
					.dots {
						margin-right: -5px;
					}
					.icon-arrow-schedule {
						margin-top: 1px;
					}
				}
				.new-value-container {
					display: flex;
                    // align-items: center;
                    color: $az_cyan;
					.date_picker {
						// margin-right: 8px;
						width: 225px;
					}
                    &.duration{
                        width:68%;
                    }
				}
			}
		}
		.add-worker-container {
			padding: 0px 25px;
			margin: 0px 25px;
			.DND-container {
				display: flex;
				align-items: baseline;
			}
			.schedule-config-add-button {
				color: $az_cyan;
			}
		}
	}
	#lead-worker{
		width: 64px;
		text-align: right;
		align-self: flex-end;
		margin-bottom: 4px;
	}
    
    #update-job-footer{
        #save-button{
            margin-left: 16px;
        }
	}
	.update-team-select{
		min-width: 228px;
        max-width: 228px;
        .react-select__control {
          min-width: 228px;
          max-width: 228px;
        }
	}
}

#schedule-update-modal-multiday{
	width:65vw;
	color: $az_rpt_black;
	.update-job-multi-day{
		position: relative;
		.grid-container{
			display: grid;
			grid-template-columns: 40% 40% 10%;
			margin-top: 8px;
			grid-gap: 8%;
			&.collapsed{
				display: flex;
				justify-content: space-between;
				grid-gap: unset;
				align-items: center;
			}
		}
		.grid-item{
			display: flex;
			align-items: center;
		}
		.date_picker{
			width: 100%;
			margin-left: 16px;
		}

	}
	.container-box{
		margin: 16px -16px;
		padding: 16px;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
		border-radius: 4px;
	}
	.team-worker-container{
		display: grid;
		grid-template-columns: max-content 45px 35%;
		align-items: center;
		.key-value-container{
			display: flex;
			max-width: 400px;
		}
	}
	.margin-left-worker{
		margin-left: 10px;
		font-weight: 400;
		margin-right: 45px;
	}
	.add-worker-container {
		padding: 0px 25px;
		margin: 0px 25px;
		.DND-container {
			display: flex;
			align-items: baseline;
		}
		.schedule-config-add-button {
			color: $az_cyan;
		}
	}
	.service-date-button{
		color: $az_cyan;
		font-weight:500;
		margin-left:16px;
		cursor: pointer;
	}
	.scroll-container{
		margin: 0px -16px;
		height:280px;
		max-height: 280px;
		overflow-y: scroll;
		scroll-behavior: smooth;
	}
	.service-date-time-container{
		display: flex;
		flex-direction: column;
		margin: 8px 2px;
		padding: 16px;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
		border-radius: 4px;
		position: relative;
		&.add-border{
			border: 1px solid $az_cyan;
		}

	}
	.service-time{
		margin-bottom: unset;
		display: flex;
		align-items: center;
		.formRow_key{
			margin: 0px 0px 0px 16px;
			font-weight: 600;
		}
	}
	.delete-icon{
		position: absolute;
		right: -20px;
		visibility: hidden;
		opacity:0;
		top: 22px;
		transition: 0.3s all ease-in-out;
	}
	#lead-worker{
		width: 64px;
		align-self: flex-end;
		margin-bottom: 4px;
	}
	.disable-date{
		background: $az_white;
		color: $az_gray_4;
		border-radius: 4px;
		.rc-time-picker-input{
			background: $az_white;
			color: $az_gray_4;
			border-radius: 4px;
		}
	}
	#update-job-footer{
        #save-button{
            margin-left: 16px;
        }
	}
	.worker-diabled{
		color: $az_gray_4;
    	background: white;
   		border-radius: 4px;
		padding: 0px 8px;
		margin-left: 11px;
		height: max-content;
		min-height: 30px;
		display: flex;
		align-items: center;
	}
	.expand-icon{
		position: absolute;
    	right: 8px;
    	align-self: center;
	}
	.chevron-container{
		display: flex;
		align-items: center;
		align-self: center;
		justify-content: center;
		border-radius: 25px;
		background: $az_cyan;
		margin-left: 8px;
		width: 18px;
		height: 18px;
		cursor: pointer;
		.arrow{
		  color:$az_white
		}
	}

	.time-duration-container{
		display: grid;
		grid-template-columns: 45% 45%;
		margin-bottom: 16px;
		grid-gap: 4%;
	}
	.service-time-common{
		display: flex;
		align-items: center;
		margin-bottom: unset;
		label{
			margin-bottom: unset;
			font-weight: bold;
			color: $az_rpt_black;
		}
	}
	.action-container{
		display: flex;
	}
}
