.service-types-Group {
	display: flex;
	.all-service-types-container {
		width: 100%;
		.heading-addButton {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-left: 25px;
			padding-right: 25px;
			.service-add-button {
				line-height: 0;
				margin: 0;
				.add_icon {
					.actions {
						color: white;
						background-color: $az_cyan;
						border-radius: 50%;
					}
				}
			}
		}
		#all-service-types-droppable {
			margin-top: 25px;
		}
	}
	.group-service-types-conatiner {
		width: 100%;
		.group-service-types-heading-actions-container {
			display: flex;
			justify-content: space-between;
			padding-left: 25px;
			padding-right: 25px;
			.group-service-type-heading-conatiner {
				width: 100%;
			}
			.group-service-type-heading-actions {
				width: 100%;
				margin-top: 20px;
				display: flex;
				justify-content: flex-end;
				#service-type-transfer-all-button {
					margin-left: 18px;
				}
			}
		}

		#group-service-type-subtitle {
			font-weight: 500;
		}
		.dnd-helper-text {
			position: absolute;
			top: 40%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	.list-container {
		width: 100%;
		height: 100%;
	}
}
