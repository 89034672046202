.hoo-group{
    padding: 0px 25px;
    width: 60%;
    .header-container{
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .action-container{
            display: flex;
            button{
                margin-right: 16px;
            }
            svg{
                margin-top: 4px;
                margin-right: 16px;
                color: $az_cyan;
                cursor: pointer;
            }
            .react-select__control{
                height: 30px;
            }
        }

    }

}