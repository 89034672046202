.workerStatus {
	#Loader.skell {
		width: calc(100% - 25px);
		margin: auto;
	}
	.group_worker_status {
		.split_view {
			&.editGroupWorkerStatus {
				.cell {
					.react-select-container {
						width: 100%;
						.react-select__control {
							min-height: 35px;
						}
					}
					&.workFlow {
						.react-select-container {
							width: calc(100% - 80px);
						}
					}
				}
			}
		}
	}
	.row {
		.azTitle.azTitle_secondary {
			font-size: 20px;
		}
		.allStatusContent,
		.status_list_wrapper {
			position: relative;
			&:after {
				// content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 15px;
				top: 100%;
				background: linear-gradient(
					0deg,
					rgba(128, 124, 124, 0.4) 0%,
					rgba(79, 79, 79, 0) 68.75%
				);
				opacity: 0;
				pointer-events: none;
				transition: all 0.25s;
			}
			&.trueShade {
				&:after {
					opacity: 1;
				}
			}
			.status_list > div > .status_item {
				color: $az_white;
				// background-color: $az_gray_6;
				&:after {
					content: "";
					background: $az_gray_6;
					position: absolute;
					left: 21%;
					right: 0;
					top: 0;
					bottom: 0;
					z-index: 1;
				}

				& > * {
					position: relative;
					z-index: 2;
				}

				&:before {
					border-right: solid 2px $az_gray_6 !important;
				}
				.cell {
					border: 0 !important;
				}
			}
		}
		.allStatusContent {
			.statusList {
				.statusItem {
					padding-left: 0;
				}
			}
		}
		.l3 {
			padding-right: 0 !important;
			margin-right: 0;
			.title {
				padding: 0 15px;
			}
		}
	}
}
.workerStatusWrapper {
	#settings {
		padding-left: 15px;
	}
	.workerStatus {
		.row {
			.azTitle.azTitle_secondary {
				font-size: 20px;
			}
			.react-select-container {
				max-width: 175px;
				.react-select__multi-value {
					white-space: normal;
				}
			}
			.jobStatusRelation {
				.seprators {
					span {
						word-break: break-all;
					}
				}
			}
			.allStatusContent {
				.statusList {
					.statusItem {
						padding-left: 0;
						padding-right: 0;
						.statusItemLabel {
							white-space: normal;
							text-transform: none;
							word-break: break-all;
						}
						.actions.solid {
							width: 50px;
							background: #fff;
							right: -10px;
						}
						&:hover {
							.actions.solid {
								right: 0px;
							}
						}
					}
				}
				.settingInputGroup {
					margin-bottom: 0 !important;
					input {
						padding: 5px !important;
						padding-right: 35px !important;
					}
				}
			}
			.l3 {
				padding-right: 0 !important;
				margin-right: 0;
				.title {
					padding: 0 15px;
					& + div {
						.settingInputGroup {
							padding: 0 15px;
							.groupItem_accWidth {
								input {
									padding: 5px 25px 5px 5px !important;
								}
								& + .groupItem {
									right: 10px !important;
								}
							}
						}
					}
				}
			}
			.col.l9 {
				padding-left: 10px !important;
				.group_worker_status {
					> .group {
						padding: 0 15px;
						position: relative;
						z-index: 9;
					}
				}
			}
		}
	}
	&#settingsWraper
		.group_worker_status
		.status_list_wrapper
		.status_list
		.status_item
		> label:not(.empty) {
		padding-left: 0;
		padding-right: 0;
		.drag_icon {
			left: -20px;
		}
		.name {
			white-space: normal;

			word-break: break-all;
		}
		.actions.solid {
			width: 50px;
			background: #fff;
			right: -10px;
		}

		&:hover {
			padding-left: 15px;
			.drag_icon {
				left: -10px;
			}
			.actions.solid {
				right: 0px;
			}
		}
	}
}

.jobStatus.settingsWrapper {
	.statusList {
		.statusItem.clickable {
			padding: 0;
			> div {
				padding: 12.5px 15px;
			}
		}
	}
}

.jobStatus.settingsWrapper {
	.statusList {
		.statusItem.clickable {
			padding: 0;
			> div {
				padding: 12.5px 15px;
			}
		}
	}
}
