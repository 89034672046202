.button,
button {
	position: relative;
	border: 0;
	line-height: 35px;
	height: 35px;
	padding: 0 10px;
	color: #fff;
	cursor: pointer;
	min-width: 75px;
	text-align: center;
	font-weight: 600;
	border-radius: 3px;
	font-size: 14px;
	white-space: nowrap;
	display: inline-block;
	background: $az_cyan;
	transition: all 0.25s ease;
	&.button--plain {
		border: solid 1px $az_cyan;
		background: transparent;
		line-height: 33px;
		color: $az_cyan;
		&.button--small {
			line-height: 28px;
			height: 30px;
			min-width: 0;
			font-size: 13px;
			font-weight: normal;
		}
	}
	&.button--gradient {
		background: linear-gradient(
			270deg,
			#64aa43 0%,
			rgba(17, 192, 161, 0.86) 100%
		);
	}
	&.button--white {
		background: $az_white;
		color: $az_gray_6;
		&.button--plain {
			border: solid 1px $az_white;
			background: transparent;
			line-height: 33px;
			color: $az_white;
			&.button--small {
				line-height: 28px;
				height: 30px;
				min-width: 0;
				font-size: 13px;
				font-weight: normal;
			}
		}
	}
	&.button--transparent {
		border: none;
		background: transparent;
		line-height: 33px;
		color: $az_cyan;
	}

	&.button_secondary {
		background: $az_cyan;
		&.button--plain {
			border: solid 1px $az_cyan;
			background: transparent;
			line-height: 33px;
			color: $az_cyan;
			&.button--small {
				line-height: 28px;
				height: 30px;
				min-width: 0;
				font-size: 13px;
				font-weight: normal;
			}
		}
	}
	&.button_danger {
		background: $az_red;
		&.button--plain {
			border: solid 1px $az_red;
			background: transparent;
			line-height: 33px;
			color: $az_red;
			&.button--small {
				line-height: 28px;
				height: 30px;
				min-width: 0;
				font-size: 13px;
				font-weight: normal;
			}
		}
	}
	&.button_withicon {
		display: inline-flex;
		min-width: 0;
		align-items: center;
		justify-content: center;
		&.button_withicon--left {
			svg {
				margin-right: 10px;
			}
		}
		&.button_withicon--right {
			svg {
				margin-left: 10px;
			}
		}
	}
	&.button_icon {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 35px;
		width: 35px;
		padding: 0;
		min-width: 0;
		&.button--small {
			width: 30px;
			height: 30px;
		}
		&.button--plain {
			border: 0;
			svg {
				height: 25px;
				width: 25px;
			}
		}
	}
	&.button--small {
		line-height: 30px;
		height: 32px;
		min-width: 0;
		font-size: 13px;
		font-weight: normal;
	}

	&.button--plain.button--borderLess {
		border: 0;
	}
	&[disabled] {
		background: $az_gray_3;
		color: $az_gray_4;
	}
}

button.no-fill-button {
	color: #0069b1;
	background: #fff;
	border: 1px solid #0069b1;
}

.button-group {
	display: flex;
	> * {
		&:not(:only-child) {
			border-radius: 0;
		}
		~ * {
			margin-left: 2px !important;
		}
		&:first-child:not(:only-child) {
			border-radius: 3px 0 0 3px;
		}
		&:last-child:not(:only-child) {
			border-radius: 0 3px 3px 0;
		}
	}
}
