.worker-card {
  border-radius: 4px;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

#schedule-worker-card {
  border-radius: 4px;
  padding: 9px;
  cursor: grab;
  display: flex;
  justify-content: space-between;
  &#schedule-worker-card:active {
    cursor: grabbing;
  }
  div {
    padding: 0px;
  }
  .worker-details {
    display: flex;
    // align-items: baseline;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
  }
  .job-time-details {
    display: flex;
    margin: 8px 0px;
    align-items: center;
    .job-time {
      width: 100%;
    }
    .job-day {
      width: 100%;
    }
    .job-estimated-time {
      width: 100%;
    }
  }
  .font-style {
    font-size: 15px;
  }
  .fly_toggle {
    // height: 30px;
    height: 18px;
    cursor: pointer;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    border: 1px solid $az_gray_3;
    border-radius: 25px;
    padding: 0 10px;
    position: relative;
    bottom: -1px;
    left: 4px;
    &.sq {
      padding: 0;
      width: 18px;
      // width: 30px;
    }
    svg {
      height: 25px;
      width: 25px;
    }
    .arrow {
      height: 20px;
    }
  }
}

#schedule-worker-job-card {
  border-radius: 4px;
  padding: 8px 8px;
  padding-left: 16px;
  border-left: 0px;
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 8px;
  div {
    padding: 0px;
  }
  .job-details {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
  .job-time-details {
    display: flex;
    margin: 8px 0px;
    align-items: center;
    .job-time {
      width: 100%;
    }
    .job-day {
      width: 100%;
    }
    .job-estimated-time {
      width: 100%;
    }
  }
  .font-style {
    font-size: 15px;
  }
  .worker-job-time-details {
    display: flex;
    margin: 8px 0px;
    align-items: center;
  }
  .jobNumber {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
