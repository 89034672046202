.reportsListingPage {
	.actions {
		flex-basis: 40%;
	}

	.default-right-margin {
		margin-right: 15px;
	}

	.reports_page {
		padding-left: 0px;
		padding-right: 0px;
	}

	.table_wrap {
		padding-top: 0px !important;
		padding-right: 0px !important;
		padding-bottom: 0px !important;
		padding-left: 0px !important;
	}

	.reports-table {
		table-layout: auto;
		width: 100%;
		transition: 1s all ease-in;
		color: $az_rpt_table_text;

		.with-margin {
			&:hover {
				margin-top: 2px;
			}
		}

		.reports-table-row {
			&:hover {
				cursor: pointer;
				background: transparent;
				position: relative;
				left: 2px;
				width: calc(100% - 4px);
				border-radius: 7px;
				box-shadow: 0 0 0 2px $az_blue, 0 4px 4px 0 rgba(0, 0, 0, 0.14),
					0 7px 10px -5px rgba(0, 0, 0, 0.25);
				.ellipses {
					vertical-align: middle;
					position: relative;
					color: $az_cyan;
					height: 19px;
					cursor: pointer;
					opacity: 1;
					margin-right: 14px;
				}

				.re-run {
					vertical-align: middle;
					position: relative;
					color: $az_cyan;
					height: 22px;
					cursor: pointer;
					margin-right: 14px;
					opacity: 1;
				}

				.share {
					vertical-align: middle;
					position: relative;
					color: $az_cyan;
					height: 22px;
					cursor: pointer;
					padding-right: 4px;
					padding-bottom: 2px;
					opacity: 1;
				}

				.email {
					vertical-align: middle;
					position: relative;
					color: $az_cyan;
					height: 22px;
					cursor: pointer;
					padding-right: 4px;
					opacity: 1;
				}

				.download {
					vertical-align: middle;
					position: relative;
					color: $az_cyan;
					height: 22px;
					cursor: pointer;
					padding-right: 6px;
					opacity: 1;
				}
			}
		}

		&.reports-table-listing {
			tbody {
				display: block;
				height: 200px;
				overflow-y: auto;
				&::-webkit-scrollbar {
					width: 4px;
				}

				&::-webkit-scrollbar-track {
					box-shadow: inset 0 0 5px grey;
					border-radius: 10px;
				}

				&::-webkit-scrollbar-thumb {
					background: gray;
					border-radius: 10px;
				}
			}
			thead,
			tbody tr {
				display: table;
				width: 100%;
				table-layout: fixed;
			}
			th {
				height: 35px;
			}
			td > div {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.reports-table-head {
			.header-row th {
				color: $az_white;
				background-color: $az_gray_6;
				vertical-align: middle;
			}
			.reports-table-head-cell {
				line-height: normal;
				text-align: left;
				padding-left: 20px;
				.caret-icon {
					vertical-align: middle;
					position: relative;
					margin-left: 3px;
					bottom: 1.5px;
					width: 13px;
					height: 13px;
					cursor: pointer;
				}
			}
		}

		.no-data-available {
			position: absolute;
			width: 90%;
			text-align: center;
			top: 40%;
		}
		&.table-no-margin {
			margin-top: unset;
		}
	}

	.report-listing-table-wrapper {
		border-left: 2px solid $az_gray_2;
		display: flex;
		//overflow-y: scroll;
		position: relative;
	}
	.report-listing-bottom-chevron-container {
		margin-top: -7px;
		background: linear-gradient(
			0deg,
			rgba(128, 124, 124, 0.4) 0%,
			rgba(79, 79, 79, 0) 68.75%
		);
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		text-align: center;
	}

	.with-bottom-border {
		border-bottom: 2px solid $az_gray_2;
	}

	.ellipses {
		opacity: 0;
	}

	.re-run {
		opacity: 0;
	}

	.share {
		opacity: 0;
	}

	.email {
		opacity: 0;
	}

	.download {
		opacity: 0;
	}

	.shared_icon {
		height: 22px;
		margin-left: 5px;
		position: relative;
		bottom: 2px;
	}

	.disabled {
		color: #999999 !important;
	}
}
