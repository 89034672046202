#settings {
  .accountsetting {
    #defaultTimeZone, #country {
      min-width: 250px;
      .react-select__menu {
        * {
          white-space: nowrap !important;
        }
        .react-select__option {
          white-space: nowrap !important;
        }
      }
    }
  }
  .intergrations{
    .sub-checkbox-container {
      position: relative;
      margin-left: 8px;
      height: 30px;
      .border-line {
        position: absolute;
        height: 20px;
        width: 50px;
        border-left: 1.5px dashed $az_cyan;
        border-bottom: 1.5px dashed $az_cyan;
        border-bottom-left-radius: 5px;
      }
      .sub-select {
        .formRow_key{
          margin-top: 0px !important;
        }
        position: absolute;
        left: 50px;
        top: 04px;
        display: flex;
        width: 214%;
        align-items: center;
      }
    }

  }
}
