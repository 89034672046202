.hoo-content{
    display: flex;
    .error-label{
        color: $az_maroon;
        font-weight: 600;
        display: flex;
        align-items: flex-end;
        margin-bottom: 16px;
        .fields-name{
            font-weight: 400;
        }
    }
}