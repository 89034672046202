.input-container {
    margin: 16px 0px;
    display: flex;
    flex-direction: column;
	.input-field-container {
		border: 1px solid $az_gray_4;
		border-radius: 25px;
        display: flex;
        align-items: center;
        transition: 0.3s all;
        svg{
            margin-left: 16px;
            color: $az_gray_4;
            transition: 0.3s all;
        }
		.input-field {
			border: none;
		}
        &.error{
            .input-field {
                &::placeholder{
                    color: $az_maroon;
                }
            }
            svg{
                color: $az_maroon;

            }
        }
        &:focus-within{
            border-color: #95a5a6;
            box-shadow: 0 0 8px 0 #95a5a6;
            svg{
                color: $az_cyan;
            }
        }

	}
    .error-label{
        text-align: left;
        margin-top: 4px;
        margin-left: 16px;
        color: $az_maroon;
    }

}
