.schedule-grid-view-three-day{
	background-color: $az_white;
	overflow: scroll;
	position: relative;
    max-height: 65vh;
	max-width: 100%;
	transition: 0.3 width ease;
	.grid-table {
		position: relative;
        border-collapse: collapse;
		margin-top:unset;
		table-layout: fixed;
		thead th {
			position: -webkit-sticky; /* for Safari */
			position: sticky;
			top: 0;
		}
		thead th:first-child {
			left: 0;
			z-index: 11009;
		}
		tbody th {
			position: -webkit-sticky; /* for Safari */
			position: sticky;
			left: 0;
		}
		.grid-header-sort {
			min-width: 165px;
			width: 165px;
			background-color: $az_white;

			align-items: flex-start;
			.grid-header-sort-container{
				border-right: 2px solid $az_gray_2;
				border-bottom: 2px solid $az_gray_2;
				padding: 0px 16px 8px 16px;
			}
		}
		.grid-header-cell {
			min-width: 300px;
			vertical-align: top;
			background-color: $az_white;
			z-index: 11007;
			.header-container{
				max-height: 35px;
				height: 35px;
				border-bottom: 2px solid $az_gray_2;
				border-right: 2px solid $az_gray_2;
			}
		}
		.grid-worker-column-conatiner {
			text-align: center;
			background-color: $az_white;
			vertical-align: top;
			z-index: 11007;
			.grid-worker-label-container {
				display: flex;
				align-items: baseline;
				padding: 0px 8px;
				min-height: 100px;
				border-right: 2px solid $az_gray_2;
				border-bottom: 2px solid $az_gray_2;
			}
		}
		.grid-body-row {
			min-height: 100px;
		}
		.grid-job-card-main-container {
			min-height: 100px;
			min-width: 325px;
			position: relative;
			.grid-data-container {
				border-bottom: 2px solid $az_gray_2;
				position: relative;
				min-height: 100px;
				.grid-job-card-conatiner {
					position: absolute;
					min-height: 100px;
					width:100%
				}
				.divider-container {
					display: flex;
					width: 100%;
					position: absolute;
					height: 100%;
					.divider {
						min-width: 33.30%;
						min-height: 100px;
						border-right: solid 2px $az_gray_2;
					}
				}
			}
		}
	}
}