#upload-image {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 400px;
	border: 1px solid #e0e0e0;
	border-radius: 5px;
}
.ReactCrop__image {
	width: 374px !important;
}

.image-dialog-footer {
	border-top: solid 0px #eeeeee !important;
	button {
		margin: 0px 8px;
	}
}

.add-image {
	height: 165px;
	width: 250px;
	border-radius: 4px;
	box-shadow: 0.5px 0.5px 2px 2px #888888;
	align-items: center;
	justify-content: center;
	display: flex;
	background-size: cover;
	cursor: pointer;
	img {
		position: absolute;
	}
	.App {
		height: 300px;
		width: 500px;
	}
	.image-uploaded {
		width: 100%;
		.image-main-input {
			position: absolute !important;
		}
		.mySlides {
			display: block;
		}
		img {
			vertical-align: middle;
			position: relative;
			width: 270px;
			height: auto;
		}
		.folder-img {
			width: 200px;
			height: auto;
			margin-top: 7%;
		}

		/* Slideshow container */
		.slideshow-container {
			max-width: 566px;
			position: relative;
			width: 100%;
			text-align: center;

			/* Next & previous buttons */
			.prev,
			.next {
				cursor: pointer;
				position: absolute;
				top: 50%;
				width: auto;
				padding: 10px;
				margin-top: -22px;
				color: black;
				font-weight: bold;
				font-size: 18px;
				transition: 0.6s ease;
				border-radius: 0 3px 3px 0;
				user-select: none;
				svg {
					height: 38px;
					color: white;
					opacity: 1;
				}
			}

			/* Position the "next button" to the right */
			.next {
				right: 0% !important;
				border-radius: 3px 0 0 3px;
			}
			.prev {
				left: 0%;
				border-radius: 3px 0 0 3px;
			}
			.view-prev {
				left: 0% !important;
				background: darkgrey;
				opacity: 0.7;
				border-radius: 0px 4px 4px 0px;
			}
			.view-next {
				right: 0% !important;
				background: darkgrey;
				opacity: 0.7;
				border-radius: 4px 0px 0px 4px;
			}

			.delete-icon {
				position: absolute;
				top: 4%;
				visibility: hidden;
				svg {
					color: $az_cyan;
				}
			}

			/* On hover, add a black background color with a little bit see-through */

			/* Caption text */
			.text {
				color: #f2f2f2;
				font-size: 15px;
				padding: 8px 12px;
				position: absolute;
				bottom: 8px;
				width: 100%;
				text-align: center;
			}

			/* Number text (1/3 etc) */
			.numbertext {
				font-size: 12px;
				padding: 8px 12px;
				position: absolute;
				top: 0;
			}

			/* Fading animation */
			.fade {
				-webkit-animation-name: fade;
				-webkit-animation-duration: 1.5s;
				animation-name: fade;
				animation-duration: 1.5s;
			}

			@-webkit-keyframes fade {
				from {
					opacity: 0.4;
				}
				to {
					opacity: 1;
				}
			}

			@keyframes fade {
				from {
					opacity: 0.4;
				}
				to {
					opacity: 1;
				}
			}

			/* On smaller screens, decrease text size */
			@media only screen and (max-width: 300px) {
				.prev,
				.next,
				.text {
					font-size: 11px;
				}
			}
		}
		.slideshow-container:hover {
			.delete-icon {
				visibility: visible;
				animation: iconDelete 0.3s;
				right: 4%;
			}
		}

		@keyframes iconDelete {
			from {
				right: 0%;
			}
			to {
				right: 4%;
			}
		}

		.folder-image-area {
			.slideshow-container {
				margin-top: 13px !important;
				img {
					width: 217px;
					height: auto;
					margin-top: 14%;
				}
			}
		}
		/* The dots/bullets/indicators */
		.dot {
			cursor: pointer;
			height: 18px;
			width: 18px;
			margin: 0 2px;
			background-color: #bbb;
			border-radius: 50%;
			display: inline-block;
			transition: background-color 0.6s ease;
			vertical-align: bottom;
			border: 2px solid #fff;
			.add {
				font-weight: 800;
				content: "+";
				cursor: pointer;
			}
		}

		.active,
		.dot:hover {
			background-color: black;
			color: white;
		}
	}
}
