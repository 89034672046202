.schedule-maps-marker {
	position: relative;
	display: flex;
	justify-content: center;
    cursor: pointer;
    transition: all 0.5s ;
	.main-circle {
        bottom: 10px;
		min-height: 46px;
		min-width: 46px;
        border-radius: 50% 50% 0 50%;
        transform: rotate(45deg);
		background: $az_cyan;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		z-index: 2;
		.icon-container {
			min-width: 42px;
			min-height: 42px;
			border-radius: 50%;
			background: $az_gray_6;
            border: 2px solid $az_white;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: rotate(-45deg);
            .multiday-number{
                color:$az_white;
                font-size:28px;
                line-height:unset;
                position: absolute;
                min-width: inherit;
                min-height: inherit;
                border-radius: inherit;
                text-align: center;
                margin-top: 20%;
            }

		}
	}
	.bottom-pulse {
        background:$az_gray_4;
        opacity: 0.5;
        border-radius: 50%;
        height: 14px;
        width: 14px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -7px 0px 0px -6px;
        transform: rotateX(55deg);
        z-index: -2;
        &:after {
            content: "";
            border-radius: 50%;
            height: 40px;
            width: 40px;
            position: absolute;
            margin: -13px 0 0 -13px;
            animation: pulsate 1s ease-out;
            animation-iteration-count: infinite;
            opacity: 0;
            box-shadow: inherit;
            animation-delay: 1.1s;
          }
    }
    .custname-card{
        visibility: hidden;
        opacity: 0;
        position: absolute;
        transition: all 0.5s ease-in;
        margin-top: 6px;
        padding:2px 4px;
        border-radius: 3px;
        min-width: max-content;
    }
    .marker-hover-card{
        visibility: hidden;
        opacity: 0;
        transition: all 0.5s ease-in-out;
        position: absolute;
        bottom: 66px;
        border-radius: 5px;
        border-left: 8px solid;
        min-width: 250px;
        padding:8px ;
        .job-service-container{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .job-number{
                color: $az_cyan;
                cursor: pointer;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        div{
            padding:unset;
        }
        .job-date-time-container{
            margin-top: 4px;
            display: flex;
            align-items: center;
            font-size: 13px;
            font-weight: 600;
            line-height: 15px;
        }
        .worker-container{
            display: flex;
            align-items: center;
            margin-top: 2px;
            .add-worker-button{
                background-color:$az_gray_2;
                color:$az_cyan;
                height: 17px;
                width: 17px;
                padding: unset;
                min-width: unset;
                display: flex;
                min-height: unset;
                align-items: center;
                justify-content: center;
                margin-right: 8px;
            }
        }
        .job-statuses-container{
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: normal;
            color:$az_gray_5;
            margin-top: 2px;
        }
        &.marker-hover-card::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -9px;
            border-width: 5px;
            border-top-width: 8px;
            border-style: solid;
            border-color: $az_white transparent transparent transparent;
        }
        .table-container{
            height: 130px;
            overflow-x: scroll;
        }
        .cluster-table{
            margin-top:unset;
            table-layout: fixed;
            width: 100%;
            thead th {
                position: -webkit-sticky; /* for Safari */
                position: sticky;
                background: $az_gray_6;
                color: $az_white;
                top: 0;
                text-align: left;
                height: 30px;
                vertical-align: middle;
            }
            tbody tr td{
                height: 30px;
                padding-top:8px;
            }
        }
        .down-arrow{
            display: flex;
            justify-content: center;
            .down-arrow-icon{
                height: 16px;
                width: 16px;
                margin-left: -8px;
            }
        }		
    }
    .place-marker{
        min-height: unset;
    }

    &:hover{
        z-index: 1000;
        .marker-hover-card{
            visibility: visible;
            opacity: 1;
        }
        .custname-card{
            visibility: visible;
            opacity: 1;
        }
    }
    
    &.bounce {
        animation-name: bounce;
        animation-fill-mode: both;
        animation-duration: 0.7s;
    }

    @keyframes pulsate {
        0% {
          transform: scale(0.1, 0.1);
          opacity: 0;
        }
      
        50% {
          opacity: 1;
        }
      
        100% {
          transform: scale(1.2, 1.2);
          opacity: 0;
        }
    }

    @keyframes bounce {
        0% {
          opacity: 0;
          transform: translateY(-2000px) ;
        }
      
        60% {
          opacity: 1;
          transform: translateY(30px);
        }
      
        80% {
          transform: translateY(-10px) ;
        }
      
        100% {
          transform: translateY(0) ;
        }
      }
    
}
