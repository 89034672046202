#outOfOffice {
	.listing_table_header {
		padding: 10px 0;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		> * {
			margin: 0;
		}
		.groupItem {
			margin-left: auto;
		}
		.react-datepicker__input-container {
			.solid.titleTrigger.actions {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 2px 10px;
				border: solid 1px #0069b1;
				border-radius: 3px;
				min-width: 100px;
			}
		}
		.formRow {
			margin: 0;
		}
		span.add_icon {
			background: #0069b1;
			height: 30px;
			width: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 30px;
			svg {
				color: #fff;
			}
		}
		.searchSelect {
			.formRow_val {
				width: 100%;
			}
		}
		.react-select-container .react-select__value-container {
			padding: 0;
			cursor: auto;
		}
		.react-select__control {
			margin-left: 15px;
			border-radius: 30px;
			border: none;
			background: $az_gray_2;
			padding-left: 40px;
			&:before {
				bottom: 0;
				margin: auto;
				left: 10px;
			}
			.react-select__clear-indicator {
				margin: 0px 4px 0 0;
				&:after {
					font-size: 22px;
				}
			}
			.react-select__dropdown-indicator {
				display: none;
			}
		}
	}
	.oofTable {
		.listing_table_wrapper {
			margin: 0 -15px;
			table {
				tr {
					.cell_in.startDate {
						min-width: 100% !important;
					}
					td,
					th {
						&.startDate {
							flex: 0 0 200px !important;
						}
						&.roles {
							flex: 0 0 150px !important;
						}
						&.status {
							flex: 0 0 200px !important;
						}
						// &.userId {
						// 	flex: 0 0 50px !important;
						// }
					}
					.cell_in.roles {
						overflow: visible;
						min-width: 100% !important;
						.tagLabel {
							white-space: nowrap;
						}
						.tagItem {
							box-shadow: none;
							margin-top: 0;
							margin-right: 0;
							padding: 0;
						}
					}
					.cell_in.startDate {
						> span {
							font-size: 12px;
						}
					}
					.cell_in.status {
						min-width: 0 !important;
						width: 160px !important;
						.formRow {
							margin: 0;
						}
						.cell_in.Approved {
							display: flex;
							align-items: center;
							&:before {
								content: "";
								height: 10px;
								width: 10px;
								border-radius: 10px;
								margin-right: 10px;
								background: $az_green;
							}
						}
						span.status {
							height: 32px;
							display: flex;
							align-items: center;
						}
						.Unapproved {
							.react-select__control:before {
								background: $az_maroon;
							}
						}
						.Pending {
							.react-select__control:before {
								background: $az_gray_4;
							}
						}
						.Approved {
							.react-select__control:before {
								background: $az_green;
							}
						}
					}
					&:not(:hover) {
						.react-select__control {
							&:not(.react-select__control--menu-is-open) {
								border: none;
								transition: all 0.25s;
								padding: 0;
								&:before {
									content: "";
									height: 10px;
									width: 10px;
									border-radius: 10px;
									margin-right: 10px;
								}
								.react-select__indicators {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
}
.tagSelector {
	&.roleTags {
		.tagList {
			.tagItem.tagItem_readOnly {
				box-shadow: none;
				padding: 0;
				& ~ .tagItem.tagItem_readOnly {
					margin-top: 10px;
				}
			}
		}
	}
}

.dialogOverlay_addEditOOO,
.dialogOverlay_oooAprovals {
	.formRow {
		.react-bootstrap-daterangepicker-container {
			width: 100%;
		}
	}
	thead {
		min-width: 100% !important;
	}
	.listing_table_wrapper {
		table {
			tr {
				.cell_in.startDate {
					min-width: 100% !important;
				}
				td,
				th {
					&.startDate {
						flex: 0 0 25% !important;
					}
					&.roles {
						flex: 0 0 25% !important;
					}
					&.usersList {
						flex: 0 0 25% !important;
					}
					&.userName {
						flex: 0 0 25% !important;
					}
				}
				.cell_in.roles {
					overflow: visible;
					min-width: 100% !important;
					.tagLabel {
						white-space: nowrap;
					}
					.tagItem {
						box-shadow: none;
						margin-top: 0;
						margin-right: 0;
						padding: 0;
					}
				}
				.cell_in.usersList {
					width: 100% !important;
					.formRow {
						width: 100%;
					}
					.no-margin {
						margin-bottom: 0px;
					}
				}
				.cell_in.startDate {
					> span {
						font-size: 12px;
					}
				}
				&:hover::before {
					box-shadow: none;
				}
				.usersList {
					padding-right: 0px;
					padding-bottom: 0px;
					.formRow_val {
						width: 100%;
					}
				}
			}
		}
	}
	.reassign_message {
		font-weight: bold;
		font-size: 14px;	
		color: #b78b20;
		margin-bottom: 15px;
	}
	.rotating_azuga_loader {
		height: 150px;
	}
	.schedule-dispatch {
		width: 100vw;
		border-radius: 0px;
		margin-top: 10vh !important;
	}
	.title {
		margin-bottom: 15px;
		display: flex;
		border-bottom: 3px solid #f6f6f6;
		align-items: center;
		width: 100%;
		justify-content: space-between;
	}
	.module_list {
		display: flex;
	}
	.tag_module {
		font-size: 24px;
		font-weight: 500;
		line-height: 16px;
		padding-bottom: 15px;
		margin-right: 30px;
		&:hover {
			cursor: pointer;
		}
		margin-left: 20px;
	}
	.list_item {
		display: flex;
	}
	.active {
		border-bottom: 3px solid #343741;
	}
	.disabled {
		color: $az_gray_4;
	}
	.schedule-dispatch-grid-new-header {
		margin-top: 0px;
	}
	.schedule-content-left-panel {
		background-color: $az_gray_2;
		height: auto !important;
		.schedule-job-list {
			height: 100% !important;
		}
	}
	.listing_table_wrapper {
		margin-top: 0px;
	}
	.listing_table_wrapper {
		table {
			height: auto;
		}
	}
	#reassign_roles {
		.listing_table_wrap {
			margin-bottom: 0px !important;
			table {
				border-bottom: 0px !important;
			}
		}
		.listing_table_wrapper {
			border: 0px;
		}
	}
} 
