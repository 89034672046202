.reports{
    // Temporary CSS until landing page is developed
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 80vh;


    //Imp for charts to work
    svg {
        width: unset;
        height: unset;
    }

    .no-bottom-margin{
        margin-bottom: unset;
    }
    .float-right {
        margin-left: auto;
    }
    .margin-top{
        margin-top: 4px;
    }
}