[data-reach-menu-button] {
	background: transparent;
	border: 0;
	line-height: normal;
	height: auto;
	padding: 0;
	min-width: 0;
}
[data-reach-menu] {
	// position: fixed !important;
}
reach-portal {
	bottom: 0;
	[data-reach-menu-list],
	[data-reach-menu-items] {
		font-size: 14px;
	}
	[data-reach-menu-popover="true"] {
		top: auto;
		// [data-reach-menu-list] {
		//   right: 0;
		//   transform: translateY(10px);
		//   position: absolute;
		// }
	}
	[data-reach-menu-list] {
		background: #fff;
		margin: 0px;
		padding: 0px;
		box-shadow: 0 6px 15px -4px rgba(0, 0, 0, 0.25);
		border-radius: 5px;
		border: solid 1px transparent;

		[data-reach-menu-item] {
			padding: 0 15px;
			line-height: 35px;
			white-space: nowrap;
			cursor: pointer;
			&:first-child {
				border-radius: 5px 5px 0 0;
			}
			&:last-child {
				border-radius: 0 0 5px 5px;
			}
			&:only-child {
				border-radius: 5px;
			}
		}
		*:first-child {
			border-radius: 5px 5px 0 0;
			[data-reach-menu-item] {
				border-radius: 5px 5px 0 0;
			}
		}
		*:last-child {
			border-radius: 0 0 5px 5px;
			[data-reach-menu-item] {
				border-radius: 0 0 5px 5px;
			}
		}
		*:only-child {
			border-radius: 5px;
			[data-reach-menu-item] {
				border-radius: 5px;
			}
		}
		[data-selected="true"] {
			color: #fff;
			background: #0069b1;
		}
	}
}

.dropList {
	position: relative;
	width: 100%;
	&.icon {
		height: 35px;
		width: 35px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.MenuButton {
		display: flex;
	}
	.MenuList {
		display: none;
		&.opened {
			display: block;
		}
		position: absolute;
		z-index: 99;
		left: 0;
		top: 100%;
		min-width: 150px;
		color: #333;
		font-size: 13px;
		border: none;
		font-weight: normal;
		text-align: left;
		background: #fff;
		margin: 0px;
		padding: 0px;
		box-shadow: 0 6px 15px -4px rgba(0, 0, 0, 0.25);
		border-radius: 5px;
		border: solid 1px transparent;
		.MenuItem {
			display: block;
			padding: 0 15px;
			line-height: 35px;
			white-space: nowrap;
			cursor: pointer;
			&:first-child {
				border-radius: 5px 5px 0 0;
			}
			&:last-child {
				border-radius: 0 0 5px 5px;
			}
			&:hover {
				background: $az_cyan;
				color: #fff;
			}
		}
	}
	&.bottom {
		.MenuList {
			bottom: 100%;
			top: auto;
		}
	}

	&.right {
		.MenuList {
			right: 0;
			left: auto;
		}
	}
}
