.downloadAction{
    color:$az_rpt_black;
    .dialogContent{
        width:30vw;
        .stronger {
            font-size: 22px;
        }
        .download{
            width:30px;
            height: 30px;
            color: $az_cyan;
            float: right;
            cursor: pointer;
        }
    }
}