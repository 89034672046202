reach-portal {
  [data-reach-dialog-overlay] {
    &.dialogOverlay {
      [data-reach-dialog-content] {
        &.dialogContent {
          .divideView {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            &.divideView_inv {
              flex-direction: row-reverse;
            }
          }
          .isJobsNewWorkerUI.divideView {
            justify-content: left;
            
            .divideView_items.teamBlock {
              min-height: 170px;
              padding: 15px;

              .actions.f-Right {
                color: #0069b1;
                right: 10px;
                top: 8px;
              }

              .formRow.formRow--flex_row {
                justify-content: center;
              }

              .formRow_val.small {
                margin-top: 10px;
              }

              .skillsBlock {
                margin-top: 30px;
              }

              .workerType {
                margin-bottom: 30px;
              }

              .workerRoleContainer {
                margin-bottom: 10px;
              }
              
              .workerRole {
                padding: 7px;
                font-size: 11px;
                border-radius: 3px;
                margin-bottom: 5px;
                
                &.green {
                  color: $az_white;
                  background: #52C4B0;
                }

                &.orange {
                  color: $az_white;
                  background: #FE5F5F;
                }

                &.skillBtn {
                  border: 1px solid #D3D3D3;
                  box-sizing: border-box;
                  border-radius: 3px;
                  margin-right: 10px;
                  cursor: pointer;

                  &.error {
                    color: #BE3131;
                    border: 1px solid #BE3131;
                  }

                  &.active {
                    color: $az_white;
                    background: #0069B1;
                    border: 1px solid #0069B1;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                  }
                }
              }
            }
          }
          .divideView_items {
            // @include column-width(2, 30px);
            width: calc(50% - 30px / 2);
            margin-right: 0 !important;
            &.divideView_items_full {
              width: 100%;
            }

            &.teamBlock {
                width: calc(30% - 30px / 2);
                padding: 10px;
                border-radius: 4px;
                margin-bottom: 20px;
                background: $az_white;
                margin-right: 5% !important;
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);

                .formRow_key {
                    text-align: left;
                    padding: 0 0 10px 2px;
                    border-radius: 4px;
                    margin-bottom: 20px;
                }
            }

            @media all and (max-width: 850px) {
              flex-basis: 100%;
              li {
                .sub_title {
                  font-size: 1.25;
                  letter-spacing: 1px;
                  font-size: 13px;
                }
              }
            }
          }
          .formSection {
            .cardList {
              margin-top: 20px;
              .cardItem {
                @include column-width(4, 25px);
                margin-bottom: 25px;

                @include respondToBelow(xs) {
                  flex-grow: 1;
                  width: auto;
                }
              }
            }
          }

          .customer_details {
            color: $az_black;
            background: $az_white;
          }

          .azTitle {
            .back_icon {
              display: flex;
              align-items: center;
              svg {
                height: 20px;
                width: 15px;
                margin-right: 10px;
                cursor: pointer;
                color: $az_cyan;
              }
            }
          }
        }
      }
    }
    &.dialogOverlay_configuration {
      .configWrap {
        & ~ .configWrap {
          margin-top: 15px;
        }
        .listLabel {
          min-width: 50%;
          align-self: center;
        }
        .formRow {
          margin: 0;
          input {
            width: 60px;
          }
          .tagListWrapper {
            .tagList {
              button[data-reach-menu-button] {
                color: $az_cyan;
                min-width: 0;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .nonDraggable {
                cursor: default !important;
              }
            }
          }
          .priceDisplay {
            margin-top: 4px;
            margin-left: 28px;
            display: flex;
            align-items: center;
            > span {
              margin-left: 74px;
              margin-top: 10px;
            }
            .toggleDisplay {
              margin-left: 10px;
              font-size: 24px;
              font-weight: 500;
              border: 1px solid;
              border-radius: 3px;
              height: 22px;
              display: flex;
              .button {
                padding: 0px 17px;
                border-radius: 0px !important;
              }
              .not-active {
                background-color: transparent;
                color: gray;
              }
              > span {
                height:20px;
                display: flex;
                align-items: center;
              }
            }
            &::before {
              content: "";
              border: dashed 1px $az_blue;
              border-width: 0 0 1px 1px;
              border-width: 0 0 1px 1px;
              position: relative;
              width: 50px;
              height: 24px;
              left: 65px;
              bottom: 6px;
            }
          }
        }
        .tagList {
          @include respondToBelow(s) {
            display: block;
          }
          .tagListItem {
            @include respondToBelow(s) {
              width: fit-content;
            }
          }
          
        }
        .nonDraggable {
          .tagListItem{
            cursor: default;
          }
        }
        .flexIt{
          &.twoColumns {
            align-items: 'center';
            flex: 1;
          }
        }
        
      }
      .group {
        @include respondToBelow(s) {
          flex-wrap: nowrap;
          .groupItem ~ .groupItem.groupItem_gap {
            flex-grow: 1;
          }
        }
      }
      .react-select-container {
        @include respondToBelow(s) {
          min-width: 60px;
        }
      }
    }
  }
}

.dialogContent {
  .cardItem {
    .type {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 5px;
      .primary ~ .billing {
        &:before {
          content: "&";
          margin: 0 5px;
        }
      }
    }
  }
  &:not(.dialogContent_addCustomers) {
    .cardItem {
      .cardHeader {
        svg {
          @include opacity(0);
          pointer-events: none;
          transform: translateX(10px);
          transition: all 0.25s;
        }
      }
      &:hover {
        .cardHeader {
          svg {
            transform: translateX(0);
            @include opacity(1);
            pointer-events: auto;
          }
        }
      }
    }
  }
}

.labelMore {
  display: flex;
  align-items: center;
  .hoverList {
    position: absolute;
    right: 0;
    top: calc(100% + 5px);
    transform: scale(0);
    transform-origin: top right;
    @include opacity(0);
    background: #fff;
    padding: 10px;
    @include box-shadow;
    transition: all 0.35s 0.25s;
    min-width: 150px;
    z-index: 999;
    span {
      display: block;
      line-height: 1.5;
      padding: 5px;
      & ~ span {
        margin-top: 5px;
      }
    }
  }
  .trigLabel {
    position: relative;
    margin-left: 10px;
    > small {
      color: $az_cyan;
      padding: 0 5px;
      border-radius: 5px;
      cursor: pointer;
      border: solid 1px $az_cyan;
    }
    &:hover .hoverList {
      transition: all 0.35s;
      transform: scaleY(1);
      @include opacity(1);
    }
  }
}
