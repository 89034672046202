#settingsWraper {
	.settingsHeader {
		.title {
			white-space: nowrap;
			// min-width: 25%;

			@media all and (max-width: 900px) {
				flex-basis: 100%;
			}
		}
	}
	.table_wrap {
		padding-top: 0 !important;
	}
	.settingsTableHeader {
		.search-group {
			width: 300px;
			margin: 0;
			position: relative;
			.search_icon {
				color: rgba($az_black, 0.5);
				position: absolute;
				height: 15px;
				width: 15px;
				top: 0;
				bottom: 0;
				left: 10px;
				margin: auto;
			}
			input[type="text"] {
				transition: all 0.25s;
				@include input(
					$bg_color: $az_gray_2,
					$border_color: rgba($az_black, 0.5)
				);
				@include input-placeholder-font(rgba($az_black, 0.4));
				color: $az_black;
				padding-left: 40px;
				border: 0;
				&:focus {
					padding-left: 15px;
					& + .search_icon {
						@include opacity(0);
					}
				}
			}
		}
	}
	.settingInputGroup {
		position: relative;
		margin: 0 0 15px;
		.groupItem_accWidth {
			input {
				width: 100%;
				padding-left: 30px;
			}
			& + .groupItem {
				position: absolute;
				top: 0;
				right: 0;
				bottom: auto;
				margin: auto;
				.button {
					background: transparent;
					color: red;
					height: 28px;
				}
			}
		}
	}
	.settingsWrapper {
		position: relative;
		padding: 20px 0;

		.title {
			.add_icon {
				display: flex;
				height: 30px;
				width: 30px;
				border-radius: 20px;
				background: $az_cyan;
				align-items: center;
				justify-content: center;
				svg {
					color: #fff;
				}
				&[disabled] {
					background: $az_gray_2;
					pointer-events: none;
					svg {
						color: $az_gray_4;
					}
				}
			}
		}
		&.jobStatus,
		&.workerStatus,
		&.jobsServiceTypes {
			.row {
				> div {
					&:first-child {
						padding-right: 15px;
					}
					& ~ div {
						padding-left: 30px;
						border-left: solid 2px $az_gray_2;
					}
				}
			}
		}
		&.jobStatus,
		&.workerStatus {
			> .row {
				margin-left: 0;
				margin-right: 0;
			}
		}
		.allStatusContent {
			flex-grow: 1;
		}
	}
	.group_worker_status {
		height: 100%;
		display: flex;
		flex-direction: column;
		.group_header {
			.groupItem {
				button {
					white-space: nowrap;
				}
				.formRow {
					margin: 0;
				}
			}
			.sub_title {
				margin-top: 5px;
				font-weight: 600;
				font-size: 14px;
				line-height: 1.35;
			}
		}
		.status_list_wrapper {
			margin-top: 25px;
			&.empty {
				.sub_status {
					display: flex;
					align-items: center;
					height: 200px;
					padding: 15px;
				}
			}
			.status_list {
				.status_item {
					display: flex;
					position: relative;
					.button_icon {
						position: absolute;
						right: 0;
						top: 0;
						bottom: 0;
						margin: auto;
						&.clear {
							right: 40px;
						}
					}
					&:before {
						content: "";
						left: calc(19% + 15px);
						position: absolute;
						top: 0;
						bottom: 0;
						border-right: solid 2px $az_gray_2;
					}
					> label {
						flex: 19% 0 0;
						padding: 15px;
						padding-right: 45px;
						position: relative;
						&:not(.empty) {
							@include az_hover;
						}
						display: flex;
						align-items: center;
						margin-right: 30px;
						max-width: 19%;
						min-height: 30px;
						transition: all 0.35s;
						.name {
							max-width: 100%;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							display: block;
							line-height: 1.5;
						}
						.drag_icon,
						.actions {
							position: absolute;
							top: 0;
							bottom: 0;
							height: 20px;
							width: 20px;
							right: 0;
							transition: all 0.35s;
							@include opacity(0);
							pointer-events: none;
							margin: auto;
							display: flex;
							align-items: center;
							justify-content: center;
							cursor: pointer;
							svg {
								height: 14px;
								width: 14px;
							}
							span ~ span {
								margin-left: 10px;
							}
						}
						.drag_icon {
							right: auto;
							left: -10px;
							cursor: grab;
						}

						&:hover {
							padding-left: 20px;
							.actions {
								right: 10px;
								@include opacity(1);
								pointer-events: auto;
							}
							.drag_icon {
								left: -5px;
								@include opacity(1);
								pointer-events: auto;
							}
						}
					}
					.cell {
						border-bottom: solid 2px $az_gray_2;
						.formRow {
							margin: 0;
							width: calc(100% - 10px);
						}
						&.workFlow {
							.formRow {
								margin: 0;
								width: calc(100% - 80px);
							}
						}
					}
					.split_view {
						flex: calc(81% - 30px) 0 0;
						display: flex;
						> .cell {
							flex: 1 0 0;
							display: flex;
							padding: 5px 5px 5px 0;
							align-items: center;
							&.workFlow {
								flex: 1.5 0 0;
							}
						}
					}
				}
			}
		}
		.action_wrapper {
			margin-top: 50px;
		}
	}
	.divideView {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.divideView_items {
			// width: calc(48% - 30px / 2) !important;
			margin-right: 0 !important;
			width: 45% !important;
			.formRow_key {
				max-width: 50%;
			}
		}
		@include respondToAbove(xlg) {
			.divideView_items {
				width: calc(45% - 30px / 2) !important;
			}
			.formRow_key {
				width: 30%;
			}
			.formRow_val {
				width: 50% !important;
			}
		}
		&.divideView_inv {
			flex-direction: row-reverse;
		}
		.divideView_items {
			@include column-width(2, 30px);
			&.divideView_items_full {
				width: 100% !important;
				margin-right: 0;
			}

			@media all and (max-width: 790px) {
				flex-basis: 100%;
				margin-right: 0;
			}
		}
		&.divideView--imLayout {
			.divideView_items {
				.formRow_val {
					width: auto;
				}
				.formRow_key {
					width: 30%;
				}
			}
		}
		&.divideView--gapped {
			.divideView_items {
				@media all and (min-width: 791px) {
					@include column-width(2, 100px);
				}

				.formRow_val {
					width: auto;
				}
				&.divideView_items_full {
					width: 100%;
					margin-right: 0;
				}
			}
		}
		&.divideView--sbsLayout {
			.divideView_items {
				.formRow_key,
				.formRow_val {
					// width: auto;
				}
			}
		}
	}
}
.workerStatusWrapper {
	#settings {
		padding-left: 20px;
	}
	.settingsHeader.listing_header {
		margin-left: 10px;
	}
}
.jobSettings {
	.statusList .statusItem {
		padding: 12.5px 15px;
		.statusItemLabel {
			white-space: normal;
			text-transform: none;
			word-break: break-all;
		}
		.actions {
			background: linear-gradient(to right, transparent 0%, #fff 20%);
			height: 80%;
			width: 60px;
		}
	}
	.listingsPage {
		padding: 10px 15px;
		&#groupGeneral .confirmationAlert {
			padding: 0;
		}
	}
	.listingsPage .listing_header.settingsHeader {
		padding-left: 20px;
	}
	.formSection .title {
		.back_icon {
			padding-left: 20px;
			font-size: 22px;
		}
		& + .allStatusContent {
			> .row {
				position: relative;
				&:before {
					content: "";
					left: 33.3333%;
					position: absolute;
					bottom: 0;
					top: -50px;
					width: 2px;
					background: #eee;
				}
				&.twin {
					&:before {
						top: 0;
					}
				}
				> div {
					&:first-child {
						padding-right: 0 !important;
					}
					&:last-child {
						border: 0 !important;
						padding-left: 5px !important;
						.statusList + * {
							margin: 0 !important;
							padding-left: 15px;
						}
						.settingInputGroup {
							position: relative;
							input {
								padding-right: 60px;
							}
							.charCounter {
								position: absolute;
								float: none !important;
								margin: 0 !important;
								display: flex;
								align-items: center;
								right: 35px;
								top: 0;
								bottom: 0;
								font-size: 10px;
								color: #777;
								letter-spacing: 0;
							}
						}
					}
				}
			}
		}
	}
	.row {
		// margin-top: 25px;
		.subStatuses {
			padding-left: 20px !important;
			.allStatusContent {
				> div {
					> .row {
						margin-right: 0;
						> div:first-child {
							padding-right: 0 !important;
						}
					}
				}
			}
			.addSubStatusAction {
				display: block;
				padding: 15px 0;
				margin: 0 -15px;
				padding: 15px;
			}
			.statusList .statusItem {
				padding-left: 0;
				.statusItemLabel {
					transition: all 0.35s;
				}
				&:hover {
					.statusItemLabel {
						padding-left: 0;
					}
					.drag_icon + .statusItemLabel {
						padding-left: 20px;
					}
				}
			}
			.settingInputGroup {
				margin-top: 15px !important;
				margin-right: 15px !important;
				&.half {
					max-width: 50%;
				}
			}
		}
	}
}

.statusList {
	margin-left: -15px;
	margin-top: 15px;
	.statusItem {
		display: block;
		padding: 12.5px 15px;
		padding-right: 45px;
		font-size: 13px;
		.settingInputGroup {
			margin: -7px;
		}
		.drag_icon,
		.actions {
			position: absolute;
			top: 0;
			bottom: 0;
			height: 20px;
			width: 20px;
			right: 0;
			transition: all 0.35s;
			@include opacity(0);
			pointer-events: none;
			margin: auto;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			svg {
				height: 14px;
				width: 14px;
			}
			span ~ span {
				margin-left: 10px;
			}
		}
		.drag_icon {
			right: auto;
			left: -10px;
			cursor: grab;
		}

		&.active:before {
			@include opacity(1);
		}

		&:hover {
			&.draggable {
				.statusItemLabel {
					padding-left: 5px;
				}
			}
			.actions {
				right: 10px;
				@include opacity(1);
				pointer-events: auto;
			}
			.drag_icon {
				left: -5px;
				@include opacity(1);
				pointer-events: auto;
			}
		}

		.statusItemLabel {
			text-transform: capitalize;
			max-width: 100%;
			display: block;
			white-space: nowrap;
			overflow: hidden;
			line-height: 1.5;
			transition: all 0.35s;
			text-overflow: ellipsis;
		}
		&:not(:last-child) {
			box-shadow: 0 1.5px 0 0 $az_gray_2;
		}
		@include az_hover;
		&[style*="fixed"] {
			&:before {
				@include opacity(1);
			}
		}
		.action {
			position: absolute;
			top: 0;
			bottom: 0;
			height: 20px;
			width: 20px;
			right: 0;
			transition: all 0.35s;
			@include opacity(0);
			pointer-events: none;
			margin: auto;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			svg {
				height: 20px;
			}
		}
		&:hover {
			.action {
				right: 15px;
				@include opacity(1);
				pointer-events: auto;
			}
		}
	}
}

.empty {
	.sub_status {
		display: flex;
		align-items: center;
		height: 200px;
		justify-content: center;
		padding: 15px;
	}
	.block {
		text-align: center;
		padding: 50px;
		p {
			margin-bottom: 10px;
		}
	}
}

// #settingsWraper {
// 	// #groupGeneral {
// 	.divideView {
// 		.divideView_items {
// 			width: 45% !important;
// 			.formRow_key {
// 				max-width: 45%;
// 			}
// 		}
// 	}
// 	// }
// }

#settingsWraper {
	#settings.jobsConfig {
		.divideView {
			.divideView_items {
				width: 45% !important;
				&.jobsLabelField {
					.formRow_key {
						width: 30% !important;
					}
					.formRow_val {
						max-width: 250px;
					}
				}
				&.shortJobsField {
					.formRow_key {
						width: 30% !important;
					}
					.formRow_val {
						max-width: 100px;
						min-width: 100px;
					}
				}
				&.autoGenPrefixField,
				&.autoGenStartField {
					.formRow_val {
						max-width: 100px;
						min-width: 100px;
					}
				}
				.jobsEstDuration .formRow_val {
					display: flex;
				    align-items: center;
				    margin-right: 15px;
				    max-width: 100px;
					min-width: 100px;
				}
				&.jobProgressField {
					.formRow_val {
						max-width: 250px;
					}
					.job-progress-info-icon {
						vertical-align: text-bottom;
    					margin-left: 5px;
					}
				}
				&.workerJobVisibility{
					.visibilityLabel {
						margin-top: 5px;
						font-size: 13px;
						line-height: 1.5;
					}
					.customDaysInput {
						margin-left: 10px;
						max-width: 70px;
					}
				}
			}
		}
	}
}

#roleSettings,
#userSettings {
	padding: 10px !important;
	.settingsHeader.listing_header {
		padding-left: 20px;
		.actions.normal {
			flex-direction: column;
			padding-right: 24px;
			.group ~ .group {
				margin-top: 8px;
			}
		}
	}
	.settingsWrapper {
		> .row {
			margin-left: 0;
			margin-right: 0;
		}
	}
	.formSection {
		.title {
			padding-left: 20px;
		}
	}
	.countryCode {
		input {
			min-width: 40px;
			padding: 5px;
		}
	}
	.actionsFooter {
		margin-top: 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.resend {
			display: flex;
			align-items: center;
			.lastSeen {
				margin-left: 10px;
				font-style: italic;
			}
		}

		.formRow_actions {
			margin: 0 0 0 auto;
		}
	}
	#userPermissions {
		.flexLay {
			.flexLay_row {
				.flexLay_cell {
					&:not(.flexLay_cell--twice) {
						text-align: center;
					}
					.selectionControl {
						justify-content: center;
					}
				}
			}
		}
	}
}
.listingsPage#groupGeneral .confirmationAlert {
	padding: 0;
}
