#product-page {
	height: auto;
	width: auto;
	display: flex;
	background: white;
	padding: 20px;
	animation: slide_in 0.35s ease-in-out;
	.product {
		width: 65%;
		margin: auto;
		.header {
			display: flex;
			.title {
				font-size: 32px;
				font-weight: 600;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.main-title {
				width: 75%;
			}
			.close {
				width: 30px;
				height: 30px;
				color: #0069b1;
				cursor: pointer;
				position: absolute;
				right: 150px;
			}
			.edit {
				width: 35px;
				height: 20px;
				color: #0069b1;
				cursor: pointer;
				margin-left: 13%;
				margin-top: 2.5px;
			}
		}
		.body {
			margin: 20px;
			.tag-title {
				font-size: 22px;
				font-size: 22px !important;
				font-weight: 500 !important;
			}
			.description {
				margin-top: 20px;
			}
			.general {
				.add-image {
					width: 365px !important;
					height: 255px !important;
				}
				.formRow_val {
					float: right;
					width: 18%;
					/* left: 57px; */
					right: 20%;
					position: absolute;
					.parent-selector {
						display: flex;
					}
				}
				.product-name {
					margin-bottom: 35px;
					.formRow_val {
						width: 18%;
						left: 25%;
						position: absolute;
					}
				}
				.add-item {
					display: flex;
					align-items: center;
					label {
						margin-top: 10px;
						color: #9b9b9b;
						width: 150px;
						max-width: 150px;
						font-weight: 500;
					}
					.small-box {
						width: 60% !important;
						float: right !important;
					}
					.small-select-box {
						.formRow_val {
							width: 10.6% !important;
						}
					}
					.formRow--required::after {
						content: "*";
						color: #982323;
						font-size: 16px;
						line-height: 1;
					}
					.multiple-input {
						display: flex;
						align-items: center;
						color: #9b9b9b;
						.small-box {
							width: 70px !important;
							border-radius: 3px;
							margin: 0px 8px;
							height: 30px;
							padding: 5px 10px;
							border: solid 1px rgba(4, 0, 0, 0.3);
							&:last-child {
								margin-right: 0px;
							}
						}
					}
				}
				.left-section {
					margin-top: 20px;
					.formRow_val {
						right: 55% !important;
					}
				}
				.folderOptionsContainer {
					display: flex;
					align-items: center;
					width: 100%;
					.folderField {
						padding: 5px;
						border: 1px solid $az_gray_4;
						width: 95%; 
						height: 29px;
						border-top-left-radius: 5px;
						border-bottom-left-radius: 5px;
						color: $az_gray_4;
					}
					.folderData {
						padding: 5px;
						border: 1px solid $az_gray_4;
						width: 100%;
						border-top-left-radius: 5px;
						border-bottom-left-radius: 5px;
					}
					.folderCaret {
						padding: 3px;
						border: 1px solid $az_gray_4;
						border-top-right-radius: 13%;
						border-bottom-right-radius: 17%;
						color: $az_cyan;
					}
				}

				.folderDropdownContainer {
					padding: 5px;
					position: absolute;
					margin-top:  4px;
					width: 100%;
					max-height: 253px;
					overflow-y: auto;
					background: $az_white;
					border: 1px solid rgba(0, 0, 0, 0.04);
					box-sizing: border-box;
					box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
					border-radius: 3px;
					z-index: 999;
					ul {
						border-left: transparent
					}
					.rootFolder {
						margin-left: 0;
						min-height: 30;
						display: flex;
						align-items: center;
					}
				}
			}
			.warehouse {
				margin-top: 50px;
				.button-disabled {
					color: lightgray;
					cursor: not-allowed;
				}
				.header {
					display: flex;
					align-items: center;
					font-weight: 600;
					font-size: 14px;
					.title {
						font-weight: 700;
						font-size: 22px;
					}
					.subHeading {
						margin-top: 4px;
						margin-left: 40px;
					}
					.info {
						display: inline-flex !important;
						.tooltip {
							border: 1px solid black;
							padding: 3px;
							border-radius: 50%;
							width: max-content;
						}
						svg {
							width: 14px;
							height: 11px;
						}
						.tooltip .tooltiptext {
							visibility: hidden;
							width: 18%;
							font-weight: 400;
							line-height: 1.4;
							background-color: #fff;
							color: #000000;
							text-align: center;
							border-radius: 6px;
							padding: 5px 0;
							position: absolute;
							z-index: 1;
							box-shadow: 0px 1px 5px #000000;
						}

						.tooltip:hover .tooltiptext {
							visibility: visible;
						}
					}
					.add_icon {
						position: absolute;
						right: 20%;
						button {
							border-radius: 50%;
							min-width: 0%;
							line-height: 0px;
						}
					}
					.small-box {
						width: 70px !important;
						border-radius: 3px;
						margin: 0px 8px;
						height: 30px;
						padding: 5px 10px;
						display: inline-flex;
						border: solid 1px rgba(4, 0, 0, 0.3);
						&:last-child {
							margin-right: 0px;
						}
					}
				}

				.table_wrap {
					position: relative;
					color: #040000;
					background: transparent;
					border-radius: 5px;
					padding: 25px 5px !important;
					margin: -30px;
					width: calc(100% + 60px);
					max-width: calc(100% + 60px);
				}
				.table {
					border: 1px solid #eeeeee;
					border-radius: 3px;
					min-height: 200px;
					margin-top: 10px;
					input {
						border-radius: 3px;
						margin: 0px 8px;
						height: 30px;
						padding: 5px 10px;
						border: solid 1px rgba(4, 0, 0, 0.3);
						width: 65%;
					}
					.single {
						margin: 10px;
						border-bottom: 2px solid #f6f6f6;
					}
					.view {
						padding: 10px;
					}
				}

				.table-header {
					background-color: #343741;
					color: white;
					padding: 12px 10px;
				}
				.table-body {
					.action {
						color: #0069b1;
						cursor: pointer;
						float: right;
						.close {
							color: #be3131;
						}
						svg {
							margin: 0px 6px;
						}
					}
					.edit-mode {
						margin-top: 7px;
					}
				}
			}
		}
		.footer {
			button {
				float: right;
				margin-top: 10px;
			}
		}
	}

	#view-product {
		hr {
			margin-bottom: 20px;
		}
		.general {
			.title {
				display: flex;
				.right {
					right: 20%;
					position: absolute;
					margin: 20px 0;
				}
				.groupItem {
					margin: 0px 14px;
					position: relative;
				}
				.switch {
					display: inline-grid;
				}
			}
			.form-row {
				margin-bottom: 40px;
				label {
					font-size: 14px;
					color: #9b9b9b;
					font-weight: 600;
					margin-right: 20px;
				}
				.data {
					position: absolute;
					text-align: left;
					right: 19%;
					width: 23%;
				}
				
			}
			.flex-center {
				display: flex;
				align-items: center;
			}
			.left-column {
				.data {
					right: 50% !important;
				}
			}
		}
	}
}
