.preference-modal {
	color: $az_rpt_black;
	.dialogContent {
		width: 35vw;
	}
	.content-container {
		display: flex;
	}
	.data-setting-container {
		display: flex;
		flex-direction: column;
		width: 60%;
	}
	.sync-type-container {
		display: flex;
		flex-direction: column;
	}
	.sync-checkbox{
		position: relative;
		margin-top: 16px;
	}
	.sub-checkbox-container {
		position: relative;
		margin-left: 8px;
		height: 30px;
		.border-line {
			position: absolute;
			height: 20px;
			width: 20px;
			border-left: 0.5px dashed $az_gray_4;
			border-bottom: 0.5px dashed $az_gray_4;
			border-bottom-left-radius: 5px;
		}
		.sub-checkbox {
			position: absolute;
			left: 20px;
			top: 10px;
		}
	}
	#sync-preference-footer{
		border-top: none;
		display: flex;
		justify-content: flex-end;
		#save-button{
			margin-left: 16px;
		}
	}
}
