.dnd-list-item-container {
	display: flex;
	padding: 8px 0px;
	align-items: center;
	transition: all 0.35 ease;
	position: relative;
	margin: 0 10px;
	&.dnd-list-item-container:hover {
		padding-right: 15px;
	}
	> * {
		position: relative;
		z-index: 2;
	}
	&:before {
		content: "";
		z-index: 9;
		pointer-events: none;
		position: absolute;
		left: -10px;
		right: -10px;
		top: 0;
		bottom: 0;
		border-radius: 8px;
		box-shadow: 0 0 0 1.5px $az_blue, 0 4px 4px 0 rgba(0, 0, 0, 0.14),
			0 7px 10px -5px rgba(0, 0, 0, 0.25);
		transform: scale(1) translateZ(0);
		@include opacity(0);
	}
	&:hover {
		&:before {
			@include opacity(1);
		}
		.drag {
			.drag_icon {
				transform: translateX(-100%) scale(1) !important;
			}
		}
	}
	.list-item-name {
		padding: 8px;
		font-size: 12px;
		width: 100%;
	}
	.drag_icon {
		visibility: hidden;
		transition: all 0.35s ease;
		margin-left: -10px;
		@include opacity(0);
	}
	&.dnd-list-item-container:hover .drag_icon {
		display: block;
		cursor: pointer;
		padding: 0px;
		font-size: small;
		visibility: visible;
		@include opacity(1);
		margin-left: 15px;
	}
	&.dnd-list-item-container:active .drag_icon {
		display: block;
		cursor: pointer;
		padding: 0px;
		font-size: small;
		visibility: visible;
		@include opacity(1);
		margin-left: 15px;
	}
	.list-item-actions {
		visibility: hidden;
		transition: all 0.35s ease;
		@include opacity(0);
	}
	&.dnd-list-item-container:hover .list-item-actions {
		display: block;
		cursor: pointer;
		padding: 0px 8px;
		font-size: small;
		visibility: visible;
		@include opacity(1);
	}
	&.dnd-list-item-container:active .list-item-actions {
		display: block;
		cursor: pointer;
		padding: 0px 8px;
		font-size: small;
		visibility: visible;
		@include opacity(1);
	}
}
