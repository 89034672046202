#schedule-configuration-modal{
    width: 60vw;
    background: $az_gray_6;
    .dialogHeader {
        color: #fff;
    }
    .close {
        color: #fff;
    }

    .schedule-dispatch-config-component{
        .formSection {
            background: #fff;
            padding: 15px;
            border-radius: 10px;
        }
        .schedule-config-input-container{
            display: flex;
            align-items: baseline;
            margin-bottom: 8px;
            width: 50%;
        }
        .schedule-config-key{
            color: $az_black;
            font-size: 14px;
            width: 40%;
        }
        .default-config-grid{
            padding: 16px 16px;
            .available-worker-filter{
                display: flex;
                align-items: center;
            }
            .schedule-config-day-spred{
                padding-bottom: 8px;
                .day-spread{
                    width: 40%
                }
                .day-spread-input-container{
                    padding: 16px 0px 0px 0px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    #day-spread-select-input{
                        width: 50%
                    }
                }
            }
        }
        .schedule-config-subtitle{
            font-size: 22px;
            padding-bottom: 10px;
        }

        .schedule-config-checkbox-container{
            padding-bottom: 8px;
        }
        .schedule-config-job-list-select-container{
            padding: 8px 0px;
            display: flex;
            flex-wrap: wrap;
            #job-list-select-input{ 
                width: 50%;
                .job-list{
                   width: 20%;
                }
            }
        }

        #schedule-config-footer{
            display: block;
            margin-top: 8px;
            border-top: none;
            #save-button{
                margin-left: 16px;
            }
        }

        .schedule-config-select-input{
            .react-select__control {
                padding-left: 10px;
                padding-right: 5px;
                background: $az_white;
                min-height: 25px;
                width: 250px;
                height: 25px;
                .small & {
                    min-height: 25px;
                    input {
                        height: 25px;
                    }
                }
                .react-select__value-container{
                    margin-top: -3px;
                }
            }
        }
    
    }

    .config-time-picker{
        height: 25px;
        .formRow_key{
            display: none !important;
        }
        .day-start-picker{
            input{
                height: 25px;
            }
        }
    }
    .schedule-config-add-button{
        color:$az_cyan
    }

}