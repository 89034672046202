.grid-view-header {
    display: flex;
    transition: all 1s ease;
    justify-content: center;
    // overflow:hidden;
    position: relative;
    .grid-view-header-date {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        color:$az_cyan;
    }
    .grid-view-header-legend {
        width: fit-content;
        width: -moz-max-content;    /* Firefox/Gecko */
        width: -webkit-max-content;
        z-index: 15000;
        transition: all 1s ease;
        position: absolute;
        border-radius: 5px;
        display: flex;
        .legend-header{
            color:$az_white;
            background: $az_gray_6;
            padding:4px 15px; 
        }
        .legend-wrapper{
            padding:3px 8px;
            border: 2px solid $az_gray_3;
            border-bottom-left-radius: 5px;
            border-top-left-radius:5px;
            height:100px;
            background: $az_white;
        }
        div{
            padding: 0px;
        }
        &.grid-view-header-legend:hover {
            cursor: pointer;
        }
        .legend-grid{
            display: grid;
            grid-template-columns: 50% 50%;
            padding-left: 8px;
            transition: all 1s ease;
            .legend-grid-item{
                padding: 8px;
                display: flex;
                align-items: center;
                .legend-color-box{
                    width: 8px;
                    height: 20px;
                    margin-right: 16px;
                }
            }
            .black-background{
                min-width: 42px;
                min-height: 42px;
                border-radius: 50%;
                background: $az_gray_6;
                border: 2px solid $az_white;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .legend-label {
  
            color:$az_cyan;
            font-weight: 500;
            width: 15px;
            display: inline-block;
            word-break: break-all;
            padding: 0px 5px;
            &.legend-label:hover{
                cursor: pointer;
            }
        }
    }
}