.holidaysPage {
	.holidaysHeader {
		padding: 20px 0px !important;
		.azTitle_secondary {
			font-size: 22px;
		}
		position: relative;
		z-index: 2;
		& + div {
			position: relative;
			z-index: 1;
		}
	}

	.table_wrap {
		margin: 0 -15px;
		width: calc(100% + 30px);
		max-width: calc(100% + 30px);
		.table {
			.thead,
			.tbody {
				overflow: scroll;
				.th,
				.td {
					.cell_in {
						max-width: calc(100% + 15px);
						.react-bootstrap-daterangepicker-container,
						.react-bootstrap-daterangepicker-container .input {
							width: 100%;
						}
					}
					&:nth-child(1) {
						flex: 0 0 30% !important;
					}
					&:nth-child(2) {
						flex: 0 0 30% !important;
					}
					&:nth-child(3) {
						flex: 0 0 20% !important;
					}
					&:nth-child(4) {
						flex: 0 0 10% !important;
					}
					&:last-child {
						flex: 0 0 10% !important;
					}
				}
			}
		}
	}
}
.yearPicker {
	.react-datepicker {
		width: 200px;
		.react-datepicker-year-header {
			padding: 5px 0;
		}
		.react-datepicker__year-wrapper {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 10px;
			.react-datepicker__year-text {
				width: calc(33.333% - 20px / 3);
				padding: 5px;
				text-align: center;
				cursor: pointer;
				transition: all 0.25s;
				border-radius: 3px;
				font-size: 12px;
				font-family: "franklin-gothic-urw", sans-serif;
				font-weight: 500;
				&:hover {
					background: $az_gray_2;
				}
				&.react-datepicker__year-text--selected {
					background: $az_cyan;
					color: #ffffff;
				}
			}
		}
	}
}
