.list {
  margin-left: -15px;
  margin-right: -15px;
  font-size: 14px;

  &.list {
    margin-top: 10px;
  }
  &.list--verticleList {
    > .listItem {
      .listLabel {
        display: block;
        padding: 10px 15px;
        transition: all 0.35s;
        position: relative;
        cursor: pointer;
        font-size: 13px;
        svg {
          transition: all 0.35s;
        }
        &:before {
          content: "";
          left: 0;
          top: 0;
          bottom: 0;
          width: 0;
          background: $az_cyan;
          position: absolute;
        }
      }
      &.active {
        .listLabel {
          background: $az_gray_2;
          &::before {
            width: 3px;
          }
        }
      }
      > .list {
        margin: 0;
        .listLabel {
          padding-left: 30px;
        }
      }
      &.listItem--DropListItem {
        .listLabel {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &.listLabel--Opened {
            box-shadow: 0 1px 0 1px $az_gray_2;
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
      > .listLabel {
        & + ul {
          display: none;
        }
        &.listLabel--Opened + ul {
          display: block;
        }
      }
    }
    .listLabel {
      svg {
        height: 20px;
      }
    }
  }
}
