.azAlert {
	margin-top: 7px;
	padding: 7px 15px;
	font-size: 13px;
	border-radius: 5px;
	color: $az_black;
	background: $az_gray_1;
	border: solid 1px $az_gray_2;
	&.azAlert--error {
		background: #ffe7e7;
		color: red;
		border-color: red;
	}
	&.azAlert--info {
		color: #1e7bdc;
		background: #aed6ff;
		border-color: #1e7bdc;
	}
	&.azAlert--float:not(.azAlert--plain) {
		box-shadow: 0 4px 10px -4px rgba(0, 0, 0, 0.25);
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
	}
	&.azAlert--plain {
		padding: 0 5px;
		background: transparent;
		border: none;
	}
}

.alertBlock {
	max-width: 75%;
	margin: auto;
	text-align: center;
	display: block;
	align-items: center;
	justify-content: center;
	line-height: 1.5;
	margin-bottom: 0;
	&:last-child:not(:only-child) {
		margin-bottom: 25px;
	}
	color: $az_gold;
	&.alertBlock--error {
		color: $az_maroon;
	}
	.inBlock {
		position: relative;
		svg {
			position: absolute;
			left: -30px;
			top: 0;
		}
		strong {
			font-weight: 600;
		}
		span {
			display: inline-block;
			margin-bottom: 10px;
			text-transform: capitalize;

			&:not(:last-of-type) {
				&:after {
					content: ",";
					margin-right: 5px;
				}
			}
		}
	}
}

.dialogContent_alert {
	.dialogHeader {
		font-size: 18px;
		align-items: center !important;
	}
	.azTitle {
		font-size: 13px;
	}
}
