#dataMigration {
	.migrate_selection {
		padding: 15px 0;
		border-bottom: 3px solid #f6f6f6;
	}
	.dataMigration {
		padding: 25px 0;
	}

	.herderLine {
		color: #040000;
		list-style: none;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 5px 0;
		border-bottom: 3px solid #f6f6f6;
		margin-left: -23px;
		margin-right: -23px;
	}
	.migrateDropDown {
		display: flex;
		label {
			margin: 8px;
		}
	}
	.downloadSample {
		color: #0069b1;
		font-size: 13px;
		font-weight: 500;
		text-decoration: underline;
		cursor: pointer;
	}
	#upload-file-area {
		.inputLabel {
			text-align: center;
			img {
				height: 60px;
			}
			.dndLabel {
				display: block;
				margin-top: 10px;
				color: #a2aab9;
			}
		}
		.large-text {
			font-size: 14px !important;
			font-weight: 500;
			padding-bottom: 15px;
		}
		.custom-file-input {
			color: transparent;
		}
		.custom-file-input::-webkit-file-upload-button {
			visibility: hidden;
		}
		.custom-file-input::before {
			content: "Choose File";
			color: black;
			display: inline-block;
			background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
			border: 1px solid #999;
			border-radius: 3px;
			padding: 5px 8px;
			outline: none;
			white-space: nowrap;
			-webkit-user-select: none;
			cursor: pointer;
			text-shadow: 1px 1px #fff;
			font-weight: 700;
			font-size: 10pt;
		}
		.custom-file-input:hover::before {
			border-color: black;
		}
		.custom-file-input:active {
			outline: 0;
		}
		.custom-file-input:active::before {
			background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
		}
		svg {
			width: 38px;
			height: 38px;
			stroke-width: 1;
		}
		.disabled-area {
			align-items: center;
			border-style: solid;
			border-width: 2px;
			border-radius: 0px;
			border-color: rgb(204, 204, 204);
			display: flex;
			flex-direction: column;
			height: 200px;
			justify-content: center;
			padding: 20px;
			background: rgb(249, 249, 251);
			cursor: default;
		}
	}

	#uploaded-file-area {
		width: 482px;
		.header-container {
			height: 35px;
			left: 283px;
			top: 313px;
			background: #343741;
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: "white";
			.title {
				font-size: 14px;
				margin-left: 15px;
				font-weight: 600;
				color: #ffffff;
			}
			.count {
				font-size: 14px;
				margin-right: 15px;
				font-weight: 600;
				color: #ffffff;
			}
		}
		.uploadedFile-container {
			margin: 10px;
			display: grid;
			.noFile {
				margin-left: 7px;
			}
		}
		.buttons {
			display: flex;
			justify-content: flex-end;
			margin-top: 20px;
			button {
				margin-left: 5px;
				height: 30px !important;
			}
		}
		svg {
			width: 28px;
			height: 28px;
		}
		.filename {
			margin: 10px;
			color: #0069b1;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	.formatValidation {
		height: 600px;
		.tabHeader {
			border-bottom: 2px solid #eeeeee;
			padding: 4px;
			font-size: 14px;
			font-weight: 500;
			color: #2e2e2e;
			margin: 10px;
			.tab-title {
				margin: 10px;
				padding: 3px;
				cursor: pointer;
			}
			.active {
				border-bottom: 2px solid;
			}
		}
		.tabBody {
			// display: inline-flex;
			.roleUserTable {
				margin-top: 15px;
			}
			.left {
				margin-left: 10px;
			}
			.right {
			}
			.table-header {
				font-weight: 500;
				font-size: 16px;
				display: flex;
				justify-content: space-between;
				align-content: center;
			}
		}
		.button-group {
			display: inline;
			margin: 10px;
		}
		.start {
			border-radius: 3px;
		}
		.end {
			float: right;
			margin-right: 15px;
			border-radius: 3px;
		}
	}
	.formRow {
		margin-bottom: 0px;
	}
	.groupItem {
		display: flex !important;
		flex-wrap: wrap !important;
	}
	.react-select-container {
		min-width: 100px;
	}
	.small .react-select-container .react-select__control {
		min-height: 35px;
	}
	.groups .react-select-container .react-select__control {
		max-height: 90px;
		overflow-y: auto;
	}
	.roles .react-select-container .react-select__control {
		max-height: 90px;
		overflow-y: auto;
	}
	.button {
		.inner {
			height: 20px;
			width: 20px;
		}
	}
}
