.loginPage {
	display: flex;
	height: 100vh;
	overflow: auto;
	@include respondToBelow(sm) {
		padding-top: 10vh;
		background: linear-gradient(148.36deg, $az_blue 0%, $az_green 100%);
	}
	.left {
		display: flex;
		flex-direction: column;
		padding: 50px 0;
		width: 45%;
		background: linear-gradient(148.36deg, $az_blue 0%, $az_green 100%);
		@include respondToBelow(sm) {
			width: 100%;
			background: transparent;
			position: relative;
			z-index: 2;
		}
		.azAlert {
			display: inline-block;
			margin: 0 0 15px;
		}
		.card {
			text-align: center;
			padding: 50px;
			background: #ffffff;
			box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
			border-radius: 8px;
			width: 80%;
			margin: auto;
			flex-grow: 1;
			> div {
				padding: 0;
			}
			form {
				text-align: left;
				ul {
					margin-top: 25px;
					.closeIcon {
						width: 15px;
						height: 15px;
						position: relative;
						&:after {
							transform: rotate(-45deg);
						}
					}
					li {
						display: flex;
						align-items: center;
						margin-bottom: 7px;
						font-size: 12px;
						color: #8a8a8a;
					}
					.red {
						margin-left: 5px;
					}
				}
				.submit {
					width: 100%;
					background: #343741;
					border-radius: 3px;
					color: #fff;
					border: none;
					height: 30px;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-top: 25px;
					font-size: 14px;
				}
			}
			.red {
				color: $az_red;
			}
			ul {
				list-style-type: none;
				margin: 0;
				padding: 0;
			}

			.formRow {
				display: flex;
				flex-direction: column;
				margin-top: 25px;
			}
			input {
				border: none;
				border-bottom: solid 1px #858585;
				height: 25px;
				font-size: 13px;
				padding: 0;
				margin-top: 10px;
				border-radius: 0;
			}
			input:focus {
				outline: none;
				border-color: $az_blue;
			}
		}
	}
	.strength {
		color: #8a8a8a;
		.text {
			font-weight: 500;
		}
		&.Weak {
			.text {
				color: $az_red;
			}
		}
		&.Good {
			.text {
				color: #0f88bb;
			}
		}
		&.Strong {
			.text {
				color: #50bf0f;
			}
		}
	}

	.closeIcon:before,
	.closeIcon:after {
		content: "";
		height: 1px;
		background: $az_red;
		width: 10px;
		transform: rotate(45deg);
		position: absolute;
		left: 0;
		bottom: 0;
		top: 0;
		margin: auto;
	}
	.infoIcon {
		height: 16px;
		width: 16px;
		margin-left: 10px;
		border: solid 1px #222;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border-radius: 15px;
		font-size: 12px;
		&:before {
			content: "i";
		}
	}
	&.expired {
		.left > .card {
			display: flex;
			flex-direction: column;
			.logo img {
				width: 150px;
				margin-top: 25px;
				@include respondToBelow(sm) {
					width: 100px;
				}
			}
			.title {
				flex: 1 0 0;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $az_gray_6;
				font-size: 24px;
				padding: 0 50px;
				@include respondToBelow(sm) {
					font-size: 18px;
					padding: 0;
				}
			}
		}
	}
}

.loginPage .left .card .logo img {
	width: 100px;
}
.loginPage .left .card h4 {
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	margin: 30px 0;
	line-height: 27px;

	color: #858585;
}

.loginPage .image img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.loginPage .left .appInfo {
	margin-top: 15px;
}
.loginPage .left .appInfo p {
	color: #fff;
	display: flex;
	font-size: 18px;
	justify-content: center;
	margin: 10px 0;
	font-weight: bold;
}

.loginPage .left .appInfo p {
	display: flex;
	font-size: 18px;
	justify-content: center;
}
.loginPage .left .appInfo .capitalize {
	display: block;
	margin: 0 0 15px;
}

.loginPage .left .formRow {
	display: flex;
	font-size: 18px;
	justify-content: center;
}
.loginPage .left .appInfo .images a ~ a {
	margin-left: 15px;
}

.loginPage .image {
	width: 60%;
	@include respondToBelow(sm) {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 60vh;
		z-index: 1;
		width: 100%;
		&:before {
			// content: "";
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			z-index: 1;
			background: rgba(0, 0, 0, 0.75);
			position: absolute;
		}
	}
}

.tooltip-container ul {
	padding: 15px;
}

.tooltip-container ul li {
	margin-bottom: 5px;
}
