.azfilter {
  display: flex;
  align-items: center;
  justify-content: center;
  .inWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    .azsortItem {
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.active {
        background: $az_cyan;
        color: #fff;
        border-radius: 5px;
        font-weight: 600;
      }
    }
  }
}
