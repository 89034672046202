.report-header-container {
    display: flex;
    width: 100%;
    .report-heading{
        .grid{
            display: grid;
            grid-template-columns: auto auto;
            max-width: max-content;
            .grid-element{
                padding: 0px 8px;
                align-self: center;
            }
        }
    }
    .no-bottom-margin{
        margin-bottom: unset;
    }
    .float-right {
        margin-left: auto;
    }
    .margin-top{
        margin-top: 4px;
    }
}