#workForce {
	margin: 50px;

	.cancel-button {
		background: #be3131;
	}
	.formRow {
		.react-select-container {
			min-width: 200px;
		}
		.formRow_key {
			color: $az_black;
			width: auto;
			flex: unset;
		}
		&.select {
			.formRow_key {
				width: 10%;
			}
		}
		&.strong {
			.formRow_key {
				font-weight: 500;
			}
		}
		.formRow_val {
			width: auto;
			flex: unset;
		}
	}
	.advancedActivity {
		width: 115%;
		& + .listVals {
			width: 115%;
			padding-right: 35px !important;
			.formRow:last-child {
				width: 100% !important;
			}
		}
	}
	.settingsHeader {
		.actions {
			margin-right: 15px;
			.group .groupItem ~ .groupItem.groupItem_gap {
				margin-left: 15px;
			}
		}
		.formRow {
			margin: 0;
			width: 44%;
			display: flex;
			.formRow_key {
				white-space: nowrap;
			}
		}
	}
	.listing_header {
		.left_header_content {
			width: 30%;
		}
		.formRow_key {
			width: auto;
		}
	}
	.content {
		border: 0;
		padding: 15px;

		.selectWorkerGroup {
			margin: 10px 0;
			.formRow {
				align-items: center;
			}
			.formRow_val {
				flex: 1 0 0;
			}
		}
		.header_content {
			padding: 0 10px 7px;
			.formRow_key {
				white-space: nowrap;
			}
			.groupItem {
				button:not(.react-datepicker__navigation) {
					height: 30px;
					line-height: 30px;
				}
			}
			.formRow {
				flex-wrap: nowrap;
				.formRow_key {
					width: auto;
					white-space: nowrap;
				}
				.formRow_val {
					flex: unset;
					width: auto;
				}
			}
		}
		.threeGrid {
			display: flex;
			margin: 0 -2.5px;
			.listVals {
				> .formRow,
				> form > .formRow {
					flex-wrap: nowrap;
					width: calc(100% + 10px);

					> .formRow_key {
						flex: 0 0 calc(40% - 40px) !important;
						& ~ .formRow_key {
							flex: 0 0 calc(60% - 40px) !important;
							padding: 0;
							.formRow_val {
								width: 100%;
							}
						}
					}
					> .formRow_icon {
						width: 80px;
						margin-left: auto;
						opacity: 0;
						pointer-events: none;
						display: flex;
						justify-content: flex-end;
						button {
							height: auto;
							width: auto;
							background: transparent;
							~ button {
								margin-left: 10px;
							}
							svg {
								color: $az_cyan;
								height: 25px;
								width: 25px;
							}
							&.close {
								border-color: $az_red !important;
								svg {
									color: $az_red;
								}
							}
						}
					}
					&.addMode {
						.react-select-container {
							min-width: 100%;
						}
						> div {
							&.formRow_icon {
								width: 80px;
								button {
									& ~ button {
										margin-left: 0;
									}
								}
							}
						}
					}
					&.editMode {
						.react-select-container {
							min-width: 100%;
						}
						> div {
							&.formRow_key {
								flex: 0 0 calc(40% - 40px) !important;
								& ~ .formRow_key {
									flex: 0 0 calc(60% + 10px) !important;
									padding: 0;
								}
							}
							&.formRow_icon {
								width: 30px;
								button {
									& ~ button {
										margin-left: 0;
									}
								}
							}
						}
					}
					&:hover {
						> .formRow_icon {
							opacity: 1;
							pointer-events: auto;
						}
					}
				}
			}
			.total {
				border-top: solid 1px $az_gray_3;
				margin: -7px;
				padding: 7px;
			}
			.formRow_val_label {
				line-height: 1.5;
				width: 55%;
				font-weight: normal;
			}
			.azTitle_tertiary {
				font-size: 16px;
			}
			.button_icon {
				border-radius: 30px;
				transform: scale(0.7);
				svg {
					height: 30px;
					width: 30px;
				}
			}

			.listGroup {
				flex: 1 0 0;
				line-height: 1.3;
				max-width: calc(50% - 30px + 15px / 2);
				& ~ .listGroup {
					margin-left: 15px;
				}
				.listVals {
					padding: 15px;
					.formRow_key_less_width {
						width: 27% !important;
					}
				}
				.listGroupHeader {
					display: flex;
					.azTitle {
						flex: 1 0 0;
						background: $az_black;
						color: #fff;
						padding: 0 15px;
						line-height: 35px;
					}
				}
			}
		}
	}
	@include respondToAbove(xlg) {
		.settingsHeader.listing_header {
			.left_header_content.title {
				width: auto;
				margin-right: 100px;
			}
			.description {
				margin: 0;
				width: 50%;
			}
			.formRow_key {
				width: auto;
				flex: unset;
			}
		}
	}
}
