.tax-list {
	.disabled {
		background: $az_gray_4 !important;
	}

	&__subheader {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__subtitle {
		font-size: 22px;
		font-weight: bold;
		color: $az_gray_6;
	}

	&__block {
		overflow: auto;
		margin: 10px 0 0;
		max-height: 60vh;
		border: 1px solid $az_gray_3;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
	}

	&__table {
		width: 100%;
		min-width: fit-content;
	}

	&__rows {
		min-height: 50vh;
	}

	&__row {
		display: flex;

		gap: 25px;
		margin: 3px 0;

		font-size: 13px;
		padding: 15px 30px;

		&--header {
			margin: 0;
			color: $az_white;
			font-weight: bold;
			padding: 15px 40px;
			background: $az_gray_6;
		}

		&--form {
			padding: 9px 30px;
		}

		&--hover {
			margin: 3px 10px;
			border-bottom: 2px solid $az_gray_1;

			&:hover {
				background: transparent;
				position: relative;
				border-radius: 8px;
				box-shadow: 0 0 0 1.5px #00a3e0, 0 4px 4px 0 rgba(0, 0, 0, 0.14),
					0 7px 10px -5px rgba(0, 0, 0, 0.25);
			}
		}

		.formRow {
			margin-bottom: 0;
		}

		> div {
			flex: 1;
			min-width: 250px;
		}
	}

	&__actions {
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 55px !important;

		&--animated {
			opacity: 0;
			color: $az_cyan;
			transition: all 0.25s;
			transform: translateX(10px);
		}
	}

	&__row--hover:hover &__actions--animated {
		opacity: 1;
		transform: translateX(0px);
	}

	&__loader {
		flex: 1;
	}

	&__icon {
		cursor: pointer;

		& ~ & {
			margin-left: 15px;
		}
	}

	&__field {
		max-width: 250px;
	}

	&__alert {
		display: flex;
		padding-top: 20vh;
	}

	&__scroll {
		&::-webkit-scrollbar {
			-webkit-appearance: none;
		}

		&::-webkit-scrollbar:vertical {
			width: 11px;
		}

		&::-webkit-scrollbar:horizontal {
			height: 11px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 8px;
			border: 2px solid $az_white;
			background-color: rgba(0, 0, 0, 0.5);
		}

		&::-webkit-scrollbar-track {
			border-radius: 8px;
			background-color: $az_white;
		}
	}
}
