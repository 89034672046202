reach-portal {
  [data-reach-dialog-overlay] {
    &.dialogOverlay_addFilters {
      [data-reach-dialog-content] {
        .tagWrap {
          .react-select-container {
            width: 200px;
          }
          & ~ .tagWrap {
            margin-top: 25px;
          }
          .accordionLabel,
          .accordionSection {
            padding: 0;
            border: 0;
            background: transparent;
          }
          .accordionLabel {
            margin-bottom: 5px;
            display: inline-flex;
            width: auto;
            label {
              margin: 0;
              padding: 10px;
            }
          }

          .addFilter {
            cursor: pointer;
            display: flex;
            align-items: center;
            svg {
              height: 25px;
              width: 25px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
