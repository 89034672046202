#navbar {
	left: 0;
	right: 0;
	top: 0;
	z-index: 999;
	background: $az_gray_6;
	display: flex;

	.navigation {
		@include respondToBelow(xs) {
			display: none !important;
		}
	}

	.list {
		margin: 0;
		color: $az_white;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
		> li {
			text-transform: uppercase;

			> a,
			.nav-menu-title {
				cursor: pointer;
				transition: all 0.25s;
				padding: 12px 15px;
				font-size: 15px;
				font-weight: 500;
			}
			&.active {
				a {
					background: transparent;
					transform-style: preserve-3d;
					&:before {
						width: 100%;
						background: rgba(#fff, 0.15);
						transform: translateZ(-1px);
					}
				}
			}
			// &:last-child {

			//   width: 300px;
			//   position: relative;
			//   .search_icon {
			//     color: rgba($az_white, 0.5);
			//     position: absolute;
			//     height: 15px;
			//     width: 15px;
			//     top: 0;
			//     bottom: 0;
			//     left: 30px;
			//     margin: auto;
			//   }
			//   input {
			//     @include input($bg_color: $az_white_transparent);
			//     @include input-placeholder-font(rgba($az_white, 0.5));
			//     color: $az_white;
			//     padding-left: 40px;
			//     &:focus {
			//       padding-left: 15px;
			//       & + .search_icon {
			//         opacity: 0;
			//       }
			//     }
			//   }
			// }
		}
	}
	.hamburger-menu {
		background: transparent;
		height: 44px;

		@include respondToAbove(sm) {
			display: none;
		}
	}
}

.navigation-menu-list {
	margin: 0;
	//color: #ffffff;
	list-style: none;
	justify-content: flex-start;
	align-items: center;
	border: 0px !important;
}

.navigation-menu-item {
	text-transform: uppercase;
	background: #343741;
	color: #ffffff;
	// &:hover {
	//   cursor: pointer;
	//   background: $az_light_blue;
	// }
}

.navigation-menu-item[data-selected] {
	background: #343741 !important;
}

.navigation-menu-item.active {
	background: $az_cyan !important;
}

.nav-menu {
	position: relative;
	display: block;

	&.active {
		background: #52555d !important;
	}

	&:hover {
		// background: $az_gray_5;

		.nav-menu-list {
			display: block;
		}
	}

	.nav-menu-list {
		left: 0;
		z-index: 5;
		display: none;
		min-width: 100%;
		color: $az_black;
		font-weight: 400;
		overflow: hidden;
		position: absolute;
		background: $az_white;
		border-radius: 0 0 2px 2px;
		animation: menu-show 0.2s ease-out;
		box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.2);

		> li a {
			display: block;
			cursor: pointer;
			padding: 10px 15px;
			text-align: left;
			white-space: nowrap;
		}

		> li:hover,
		> li.active {
			color: $az_white;
			background: #52555d; //#9e9e9e
		}

		@keyframes menu-show {
			from {
				opacity: 0;
			}
			to {
				opacity: 1;
			}
		}
	}
}
