.pill {
    body {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
    }

    .wrapper {
        background-color: $az_white;
        width: 400px;
        padding: 2rem;
        box-shadow: 0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.1);
        border-radius: 0.3rem;
    }

    .input {
        width: 100%;
        height: 35px;
        padding: 0 1rem;
        box-sizing: border-box;
        font: inherit;
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0;
    }

    .itemList {
        border: 2px solid $az_pill_container_border;
        border-radius: 5px;
        display: flex;
        flex-wrap: wrap;
        max-height: 100px;
        overflow: auto;
    }

    .required {
        border-left: 6px solid $az_red;
    }

    .input:focus {
        border-color: $az_focus_blue;
        outline: none;
    }

    .error {
        margin: 0;
        font-size: 95%;
        color: $az_maroon;
    }

    .tag-item {
        font-size: 14px;
        height: 27px;
        margin-top: 2px;
        margin-left: 2px;
        .close {
            margin-left: 2px;
            height: 13px;
        }
        padding-top: 7px;
        padding-bottom: 7px;
        padding-left: 6px;
        padding-right: 6px;
        background: $az_gray_2;
        display: flex;
        align-items: center;
        margin-right: 2px;
        border-radius: 3px;
    }

    .invalid {
        color: $az_yellow;
    }

    .tag-item > .button {
        background-color: $az_white;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        font: inherit;
        margin-left: 10px;
        font-weight: bold;
        padding: 0;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .tagListItems {
        display: flex;
        flex-wrap: wrap;
    }

    .itemInput {
        display: flex;
        align-items: flex-start;
        flex-grow: 1;
    }

    .tagDrag {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        transform: translateY(-4px);
        line-height: 0.3;
        letter-spacing: 1px;
        margin-right: 7px;
        font-size: 12px;
    }
}
