.four-o-four {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;

  height: 100vh;
  .errorContainer {
    height: 80vh;
    width: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px;
    background: #fff;
  }
  h1 {
    line-height: 1.5;
    font-size: 20px;
  }
  a {
    text-align: center;
    margin-bottom: 75px;
  }
  h2 {
    line-height: 1.5;
    font-size: 75px;
    font-weight: bold;
    margin: 0;
    background: -webkit-linear-gradient(45deg, $az_green, $az_blue);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
  }
  img {
    width: 100%;
  }
}
