@mixin estimate-form {
	&__container {
		display: flex;
	}

	&__description {
		width: 60%;
		padding-top: 10px;
		margin-right: 16px;
	}

	&__customer-information {
		width: 40%;

		display: flex;
		flex-direction: column;
		justify-content: space-between;

		padding-left: 16px;
		border-left: 2px solid $az_gray_3;
	}

	&__group {
		margin-bottom: 22px !important;
	}

	&__group-label {
		margin-top: 5px;
	}

	&__title {
		margin-bottom: 8px;
	}

	&__add-item {
		display: inline-flex;
		margin-top: 15px;
		cursor: pointer;
		align-items: center;
	}

	&__plus-icon {
		height: 23px;
		width: 23px;
		margin-right: 8px;
	}

	&__switch-title {
		color: $az_gray_4;
		margin-top: 3px;
		margin-left: 12px;
	}

	&__text {
		margin-top: 5px;
		font-size: 13px;
		color: $az_gray_4;
	}

	&__money {
		color: $az_black;
		font-weight: 600;
		margin-left: 5px;
	}

	&__actions {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	&__btn {
		margin-left: 10px;
	}

	.formRow_val--flex {
		display: flex;
		align-items: center;
	}

	.tagSelector#{&}__tags {
		.groupItem,
		.react-select-container {
			width: 100%;
		}

		.formRow.formRow--flex_row {
			flex-wrap: wrap;

			.formRow_key {
				width: 40%;
				flex-grow: 1;
				margin-right: 0;
			}

			@media screen and (max-width: $screen-sm-max) {
				.formRow_key,
				.formRow_val {
					width: 100%;
				}
			}
		}
	}

	.formRow#{&}__number {
		width: 70%;
		display: flex;
		flex-wrap: wrap;
		margin: 5px 0 20px;
		align-items: center;

		.formRow_key {
			width: auto;
			flex: unset;
			display: block;
			margin-top: 0px;
			font-size: 28px;
			line-height: 1.5;
			color: #040000;
			font-weight: bold;
		}

		.formRow_val {
			width: auto !important;

			input {
				height: 40px;
				width: 250px;
				display: block;
				font-size: 22px;
				line-height: 1.5;
				@include input-placeholder-font(rgba($az_black, 0.3), $fontSize: 18px);
			}
		}
	}

	@media screen and (max-width: $screen-sm-max) {
		&__container {
			display: block;
		}

		&__description,
		&__customer-information {
			width: 100%;

			padding: 0;
			border: none;
			margin: 10px 0;
		}
	}
}

@mixin estimate-items {
	overflow: auto;
	max-height: 40vh;
	scroll-behavior: smooth;

	&--sm {
		max-height: 240px;
	}

	&__item {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		position: relative;

		padding: 12px;
		margin-top: 12px;
		border-radius: 6px;
		width: calc(100% - 7px);

		background: $az_gray_1;

		&--detail {
			margin: 0;
			width: 100%;

			& ~ & {
				margin-top: 12px;
			}
		}
	}

	&__field {
		width: calc(50% - 30px);
		margin: 0 15px 15px !important;

		&--end {
			text-align: right;
			align-self: flex-end;
		}

		@media screen and (max-width: $screen-mid-size) {
			width: 100%;
		}
	}

	&__ml-15 {
		margin-left: 15px;
		margin-bottom: 5px !important;
	}

	&__close {
		position: absolute;
		display: flex;

		top: -7px;
		right: -7px;
		padding: 3px;

		border-radius: 50%;
		background-color: $az_red;
		color: $az_white;

		opacity: 0.6;
		cursor: pointer;
	}

	&__icon {
		height: 10px;
		width: 10px;
	}
}

@mixin estimate-total {
	color: $az_gray_4;
	padding-top: 10px;

	&--border {
		// border-width: 1px 0;
		border-style: solid;
		border-top-width: 1px;
		border-color: $az_gray_3;
	}

	&__table {
		margin: 0;
		margin-left: auto;
	}

	&__td {
		line-height: 1.5;
		font-weight: 500;
		text-align: right;

		& ~ & {
			padding-left: 10px;
		}

		&--amount {
			font-size: 16px;
			color: $az_black;
			font-weight: 600;
			margin-left: 5px;
		}
	}
}

.estimate-form {
	@include estimate-form;
}

.estimate-items {
	@include estimate-items;
}

.estimate-total {
	@include estimate-total;
}
