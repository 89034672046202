.hoo-common {
	width: 40%;
	.header-container {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		svg {
			cursor: pointer;
			color: $az_cyan;
		}
	}
	.footer-action-container {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin: 16px 0px;
		button {
			height: 30px;
		}
		button:first-child {
			margin-right: 16px;
		}
	}
}
