#skillSets {
	.add_icon {
		display: flex;
		height: 30px;
		width: 30px;
		border-radius: 20px;
		background: #0069b1;
		align-items: center;
		justify-content: center;
		color: aliceblue;
		svg {
			color: #fff;
		}
	}
	#addSkills {
		.addSkills {
			width: 849px;
			height: 443px;
			left: 296px;
			top: 43px;
			background: #ffffff;
			border-radius: 8px;
		}
		.heading {
			width: 266px;
			height: 38px;
			left: 328px;
			top: 62px;
			font-weight: 800;
			font-size: 28px;
			line-height: 38px;
			letter-spacing: 0.1px;
			float: left;
		}
	}
	.table {
		.tr {
			.td {
				.cell_in {
					input {
						border-radius: "4%" !important;
						width: "30%" !important;
						text-align: "right" !important;
					}
				}
			}
		}
	}
}

.dialogOverlay_addSkill,
#rateType {
	.table_wrap {
		padding: 25px 20px !important;
		.table {
			.thead {
				overflow: scroll;
				padding: 0;
				background: $az_gray_6;
				.tr {
					border: none;
				}
				.th {
					color: #fff;
					padding: 15px !important;
				}
			}
			.tbody {
				overflow: scroll;
				padding-left: 0;
				padding-right: 0;
				.tr {
					border-bottom: 2px solid #f6f6f6;
					&:before {
						left: 2px;
						right: 2px;
					}
				}
			}
			.tr {
				.td,
				.th {
					flex: 1 0 0;
				}
			}
			&.editRates {
				.tr {
					.td,
					.th {
						&:nth-child(1) {
							flex: 0 0 25%;
						}
						&:nth-child(2) {
							flex: 0 0 45%;
						}
						&:nth-child(3) {
							flex: 0 0 30%;
						}
					}
				}
			}
		}
	}
	.threeCol {
		small.spaceX {
			font-size: 100%;
			width: 50px;
			display: inline-block;
			text-align: center;
		}
	}
	.fixInputContainer {
		position: relative;
		width: 90px;
		color: $az_black;
		.sign {
			position: absolute;
			font-size: 14px;
			left: 5px;
			top: 0;
			bottom: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		input {
			padding-left: 14px;
		}
		&.right {
			width: 60px;
			.percSign {
				left: auto;
				right: 5px;
			}
			input {
				padding-left: 5px;
				text-align: right;
				padding-right: 15px;
				margin-right: 0;
			}
		}
	}
}

.listingsPage {
	#rateType {
		padding-top: 25px;
		padding-left: 10px;
		padding-right: 10px;
		padding-bottom: 0px;
	}
}

.rateAndSkillsTable {
	.listing_table_wrapper {
		border: 0;
		width: 100%;
		table {
			tbody {
				tr {
					border-bottom: 2px solid $az_gray_1;
				}
				.tr {
					border-bottom: 2px solid $az_gray_1;
				}
			}
			tr {
				td,
				th {
					&.skillName {
						flex: 0 0 65% !important;
					}
					&.ratesListing {
						flex: 0 0 25% !important;
					}
					&.userIds {
						flex: 0 0 25% !important;
						.cell_in {
							text-align: right;
							justify-content: flex-end;
							width: 100% !important;
						}
						.labelMore {
							justify-content: flex-end;
							text-align: right;
						}
					}
					&.user {
						flex: 0 0 10% !important;
						padding: 5px;
					}
				}
			}
			.viewRatesStyle {
				flex: 0 0 25% !important;
				padding-left: 60px !important;
				border-bottom: 2px solid $az_gray_1;
			}
		}
		.caret {
			color: $az_cyan;
		}
	}
	z-index: 0;
}

.group_select {
	margin-bottom: 0px;
	margin-right: 20px;
}

#rateType {
	.table_wrap {
		z-index: 0;
		padding-top: 0px !important;
	}
}

.rateAndSkillsTable.skillsOnly {
	.listing_table_wrapper {
		position: relative;
		&:before,
		&:after {
			content: "";
			width: 50px;
			background: #fff;
			left: 50%;
			height: 100%;
			top: 0;
			z-index: 10;
			position: absolute;
			transform: translateX(-50%);
		}
		&:after {
			background: #eeeeee;
			width: 3px;
		}
		table {
			tbody,
			thead {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				tr,
				.tr {
					flex: 0 0 calc(50% - 25px) !important;
				}
			}
			tr {
				td,
				th {
					&.skillName {
						flex: 0 0 42.5% !important;
					}

					&.userIds {
						flex: 0 0 42.5% !important;
					}
					&.user {
						flex: 0 0 15% !important;
					}
				}
			}
			th {
				&.skillName {
					position: static;

					> .skillName {
						position: static;
						&:before {
							content: "Assigned To";
							padding: 10px 15px;
							padding-right: 8.5%;
							color: #fff;
							text-align: right;
							width: calc(50% - 25px);
							background: #343741;
							right: 0;
							height: 100%;
							position: absolute;
							z-index: 1;
							pointer-events: none;
						}
						&:after {
							content: "Skill Set";
							z-index: 2;
							color: #fff;
							display: flex;
							align-items: center;
							justify-content: flex-start;
							left: calc(50% + 25px);
							height: 100%;
							position: absolute;
							padding: 10px 15px;
							padding-right: 25px;
							background: url(/icons/sort-no.svg) right center no-repeat;
							background-size: 7px;
						}
					}
					&.ascending {
						> .skillName {
							&:after {
								background-image: url(/icons/sort-asc.svg);
							}
						}
					}
					&.descending {
						> .skillName {
							&:after {
								background-image: url(/icons/sort-dsc.svg);
							}
						}
					}
				}
			}
		}
	}
}
