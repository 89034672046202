.key-value-block{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8px;
    margin-bottom: 25px; 
    color: $az_rpt_black;
    &.underLine-container{
        .underline{
            width: 100%;
            height: 3px;
            background-color: $az_gray_3;
        }
        .primary-value{
            font-size: 52px;
        }
    }

    .secondary-value{
        font-weight: 500;
        font-size: 36px;
    }

    .value-line-height{
        line-height: 1;
    }

    .key-font-weight{
        font-weight: 500;
    }
    .secondary-key{
        font-size: 21px;
    }
}