#custom-setting-layout {
	.settings-page-header {
		display: flex;
		align-items: center;
		// border-bottom: solid 2px #EEE;
		color: $az_rpt_black;
	}
	.setting-page-footer {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}

	.setting-page-content {
		padding: 16px 0px;
		color: $az_rpt_black;
		.actions {
			color: white;
			background-color: $az_cyan;
			border-radius: 50%;
		}
		#select_group_service_type {
			width: 200px;
			margin-left: auto;
			padding: 0px 25px;
			position: relative;
			z-index: 9;
		}
	}

	#setting-page-subtitle {
		margin-left: 50px;
	}

	#save-button {
		margin-left: 16px;
	}

	.search-group {
		width: 300px;
		position: relative;
		.search_icon {
			color: rgba($az_black, 0.5);
			position: absolute;
			height: 15px;
			width: 15px;
			top: 0;
			bottom: 0;
			left: 10px;
			margin: auto;
		}
	}

	.gpd-setting-container {
		padding: 0px 25px;
		.error-label {
			color: $az_maroon;
			font-weight: 600;
			margin-left: 16px;
			display: flex;
			align-items: flex-end;
			.fields-name {
				font-weight: 400;
			}
		}
		.toggle-checkbox-container {
			display: flex;
			justify-content: space-between;
			width: 65%;
			.checkbox-wfm {
				margin-bottom: 8px;
			}
		}
		.gps-check-box-error {
			&::before {
				border-left: 4px solid $az_maroon;
			}
		}
	}
}
#service-type-group-selection-modal {
	width: 70vw;
	.search-group {
		width: 300px;
		position: relative;
		.search_icon {
			color: rgba($az_black, 0.5);
			position: absolute;
			height: 15px;
			width: 15px;
			top: 0;
			bottom: 0;
			left: 10px;
			margin: auto;
		}
	}
	input[type="text"] {
		transition: all 0.25s;
		@include input($bg_color: $az_gray_2, $border_color: rgba($az_black, 0.5));
		@include input-placeholder-font(rgba($az_black, 0.4));
		color: $az_black;
		padding-left: 40px;
		&:focus {
			padding-left: 15px;
			& + .search_icon {
				@include opacity(0);
			}
		}
	}
	.service-type-body-header {
		p {
			margin-left: 0;
		}
		display: flex;
		align-items: center;
	}
	.checkbox-container {
		display: grid;
		grid-template-columns: auto auto auto auto;
		padding: 8px;
	}
	.checkbox-item {
		padding: 8px;
	}
}
