.service-types-account {
	.heading-addButton {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 25px;
		padding-right: 25px;
		.service-add-button {
			line-height: 0;
			margin: 0;
			.add_icon {
				.actions {
					color: white;
					background-color: $az_cyan;
					border-radius: 50%;
				}
			}
		}
	}
	.list-container {
		width: 100%;
		height: 100%;
		> div {
			.dnd-list-item-container {
				~ .dnd-list-item-container {
					border-top: solid 1px #eee;
				}
			}
		}
	}
}
