#Loader {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.75);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9;
	&.inline {
		position: absolute;
		svg {
			height: 15px;
			width: 15px;
		}
		.inner {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	svg {
		height: 40px;
		width: 40px;
		animation: 1.5s linear infinite svg-animation;
		max-width: 100px;
	}

	circle {
		animation: 2s ease-in-out infinite both circle-animation;
		display: block;
		fill: transparent;
		stroke-linecap: round;
		stroke-dasharray: 283;
		stroke-dashoffset: 280;
		transform-origin: 50% 50%;
	}
	&.static {
		top: auto;
		svg {
			height: 50px;
			width: 50px;
			animation: 0.5s linear infinite svg-animation;
		}

		circle {
			animation: 1s ease-in-out infinite both circle-animation;
		}
	}
	&.skell {
		position: relative;
		top: auto;
		bottom: auto;
		left: auto;
		right: auto;
		min-height: 20px;
		width: 100%;
		border-radius: 10px;
		background-color: #f4f4f4;
		overflow: hidden;
		&.dark {
			background-color: rgba(255, 255, 255, 0.1);
		}
		&:before {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			height: 200%;
			width: 100%;
			background-image: linear-gradient(
				to bottom,
				#f2f2f2 0,
				$az_gray_1 45%,
				#f2f2f2 75%
			);
			transform: rotate(-45deg);
			background-size: 100%;
			background-position: center;
			animation: shine-lines 1.5s infinite linear;
		}
		svg,
		circle {
			display: none;
		}
	}
}

@keyframes svg-animation {
	0% {
		transform: rotateZ(0deg);
	}
	100% {
		transform: rotateZ(360deg);
	}
}

@keyframes circle-animation {
	0%,
	25% {
		stroke-dashoffset: 280;
		transform: rotate(0);
	}

	50%,
	75% {
		stroke-dashoffset: 75;
		transform: rotate(45deg);
	}

	100% {
		stroke-dashoffset: 280;
		transform: rotate(360deg);
	}
}

@keyframes shine-lines {
	0% {
		left: -100%;
	}
	100% {
		left: 100%;
	}
}

.logoLoader {
	display: flex;
	justify-content: center;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	align-items: center;
	position: fixed;
	z-index: 9;
	opacity: 0;
	&.backdrop {
		background: rgba(#000, 0.75);
	}
	&.visible {
		opacity: 1;
	}
	&.container {
		position: absolute;
	}
	.loader {
		height: 300px;
		width: 200px;
		transform: scale(0.5);
		.top {
			height: 20%;
			width: 60%;
			margin: 0 auto 10%;
			border-radius: 10px 10px 0 0;
			animation: bounceAnim 2s infinite linear;
			background: url(/logo_top.svg) center no-repeat;
			background-size: 100%;
		}
		.rot {
			margin: auto;
			height: 70%;
			width: 90%;
			border-radius: 5px 5px 15px 15px;
			background: url(/logo_rot.svg) center no-repeat;
			background-size: 100%;
			position: relative;
			animation: rot 2s infinite linear;
		}
	}
	@keyframes bounceAnim {
		0% {
			transform: translateY(0);
		}
		80% {
			transform: translateY(0);
		}
		85% {
			transform: translateY(30px);
		}
		90% {
			transform: translateY(0);
		}
		95% {
			transform: translateY(30px);
		}
		100% {
			transform: translateY(0);
		}
	}
	@keyframes rot {
		0% {
			transform: rotate(0);
		}
		80% {
			transform: rotate(360deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}
