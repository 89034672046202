.card {
  background: #fff;
  @include box-shadow;
  > div {
    padding: 25px;
  }
  border-radius: 10px;
  &.card--small {
    border-radius: 5px;
    > div {
      padding: 10px;
    }
  }
  &.card--large {
    border-radius: 15px;
    > div {
      padding: 50px;
    }
  }

  .cardHeader {
    border-bottom: solid 2px $az_border_color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    span {
      display: block;
      height: 12px;
    }
  }

  .cardBody {
    font-size: 13px;
    line-height: 1.5;
    word-break: break-all;
  }

  .cardFooter {
    padding-top: 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      color: $az_faded_blue;
    }
    .mail {
      text-transform: none;
    }
  }
}

.cardList {
  display: flex;
  flex-wrap: wrap;
}
