.hoo-user{
    .header-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .action-container{
            display: flex;
            svg{
                margin-top: 4px;
                margin-right: 16px;
                color: $az_cyan;
                cursor: pointer;
            }
            .react-select__control{
                height: 30px;
            }
        }

    }
    .footer-action-container{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin:16px 0px ;
        button{
            height: 30px;
        }
        button:first-child{
            margin-right: 16px;
        }
    }
}