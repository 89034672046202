.schedule-dispatch-grid-new-header{
    margin-top: 8px;
    background-color: $az_white;
    
}

.schedule-grid-view-day-new{
    background-color: $az_white;
    overflow: scroll;
    position: relative;
    max-height: 65vh;
	max-width: 100%;
	transition: 0.3 width ease;
	.present-time{
		border-right: 3px solid $az_present_time_color;
		margin-left:165px;
		position: absolute;
		z-index: 1004;
		margin-top:29px ;
		.present-time-dark{
			background: linear-gradient(270deg, rgba(168, 168, 168, 0.5) 0%, rgba(168, 168, 168, 0) 100%);
			margin-left: auto;
		}
	}
	.present-time-top-line{
		height: 16px;
		border-right: 3px solid $az_present_time_color;
		position: absolute;
		margin-top: -5px;
	}
    .grid-table {
		position: relative;
        border-collapse: collapse;
		margin-top:unset;
		table-layout: fixed;
		width: 100%;
		thead th {
			position: -webkit-sticky; /* for Safari */
			position: sticky;
			top: 0;
		}
		thead th:first-child {
			left: 0;
			z-index: 11009;
		}
		tbody th {
			position: -webkit-sticky; /* for Safari */
			position: sticky;
			left: 0;
		}
		.grid-header-sort {
			width: 165px;
			background-color: $az_white;
			align-items: flex-start;
			.grid-header-sort-container{
				border-right: 2px solid $az_gray_2;
				border-bottom: 2px solid $az_gray_2;
				padding: 0px 16px 8px 16px;
			}
		}
		.grid-header-cell {
			width: 100px;
			// border-right: 2px solid $az_gray_2;
			vertical-align: top;
			background-color: $az_white;
			z-index: 11007;
			.header-container{
				position: relative;
				max-height: 35px;
				height: 35px;
				border-bottom: 2px solid $az_gray_2;
				border-right: 2px solid $az_gray_2;
				.header{
					position: absolute;
					top:0;
					max-height: 27px;
					margin-top: -10px;
				}
			}

		}
		.grid-worker-column-conatiner {
			text-align: center;
			background-color: $az_white;
			// border-top: 2px solid $az_gray_2;
			vertical-align: top;
			z-index: 11007;
			.grid-worker-label-container {
				display: flex;
				align-items: baseline;
				padding: 0px 8px;
				min-height: 100px;
				border-bottom: 2px solid $az_gray_2;
				border-right: 2px solid $az_gray_2;
				&.sticky-column{
					position: absolute;
					width: 126px;
					background: white;
					border-right: 2px solid $az_gray_2;
					z-index: 19000;
				}
			}
        }
        .grid-job-card-main-container {
			// .grid-timeline-container {
			// 	.grid-bounds-job-card {
					// margin: 3px 3px 3px 3px;
					.grid-data-container {
						border-bottom: 2px solid $az_gray_2;
						position: relative;
						min-height: 100px;
						.grid-job-card-conatiner {
							position: absolute;
							min-height: 100px;
							// z-index: 2;
							// margin: 3px 3px 3px 3px;
							display: flex;
							width: 100%;
						}
						.divider-container {
							display: flex;
							width: 100%;
							position: absolute;
							// z-index: 1;
							.divider {
								width: 100px;
								min-height: 100px;
								border-right: solid 2px $az_gray_2;
								display: flex;
							}
						}
					}
			// 	}
			// }
		}
    }

}