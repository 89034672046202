@mixin transactions_status {
	font-weight: 500;
	color: $az_gray_4;

	&--not-paid {
		color: $az_yellow;
	}

	&--paid,
	&--active {
		color: $az_green;
	}

	&--pending,
	&--processing {
		color: $az_gold;
	}

	&--failed {
		color: $az_red;
	}
}

.transactions {
	&__content {
		overflow: auto;
		max-height: 200px;
	}

	&__block {
		margin-top: 10px;
	}

	&__table {
		width: 100%;
		margin-top: 0 !important;

		.first-column {
			width: 35%;
		}

		.center {
			text-align: center;
		}

		th {
			text-align: left;
			font-weight: 600;
			color: $az_gray_6;
			min-width: 120px;
			border-bottom: 1px solid $az_gray_2;
		}

		td {
			vertical-align: middle;
		}
	}

	&__status {
		@include transactions_status;
	}

	&__refund {
		display: inline-flex;
		align-items: center;
		font-size: 14px;
		margin: 2 auto;
		padding: 3px 10px;
		border-radius: 4px;
		cursor: pointer;
		color: $az_cyan;
		border: 1px solid $az_cyan;

		&:hover {
			color: $az_white;
			background-color: $az_cyan;
		}

		svg {
			width: 11px;
			margin-right: 8px;
		}

		span {
			line-height: 1.5;
		}
	}

	&__loader {
		margin: 10px 5px 0;
	}
}
