.schedule-grid-view-day {
	margin-top: 8px;
	background-color: $az_white;
	overflow-x: scroll;
	overflow-y: hidden;
	padding-bottom: 8px;
	.grid-table {
		overflow-x: scroll;
		width: max-content;
		table-layout: fixed;
		.grid-header-sort {
			width: 125px;
			border-right: 2px solid $az_gray_2;
			padding: 0px 16px 8px 16px;
			align-items: flex-start;
		}
		.grid-header-cell {
			width: 100px;
			border-right: 2px solid $az_gray_2;
			vertical-align: top;
		}
		.grid-worker-column-conatiner {
			text-align: center;
			border-right: 2px solid $az_gray_2;
			// border-top: 2px solid $az_gray_2;
			vertical-align: top;
			.grid-worker-label-container {
				display: flex;
				align-items: baseline;
				padding: 0px 8px;
				min-height: 100px;
				border-top: 2px solid $az_gray_2;
				&.sticky-column{
					position: absolute;
					width: 126px;
					background: white;
					border-right: 2px solid #eee;
					z-index: 19000;
				}
			}
		}
		.grid-job-card-main-container {
			// .grid-timeline-container {
			// 	.grid-bounds-job-card {
					// margin: 3px 3px 3px 3px;
					.grid-data-container {
						border-top: 2px solid $az_gray_2;
						position: relative;
						min-height: 100px;
						.grid-job-card-conatiner {
							position: absolute;
							min-height: 100px;
							// z-index: 2;
							// margin: 3px 3px 3px 3px;
							display: flex;
							width: 100%;
						}
						.divider-container {
							display: flex;
							width: 100%;
							position: absolute;
							// z-index: 1;
							.divider {
								width: 100px;
								min-height: 100px;
								border-right: solid 2px $az_gray_2;
								display: flex;
							}
						}
					}
			// 	}
			// }
		}
	}
}
