#autocomplete {
	.panel {
		border-radius: 6px;
		box-shadow: 1px 1px 1px 1px #ccc;
		max-width: 500px;
	}
	.panel-block:not(:last-child),
	.panel-block:not(:last-child) {
		border-bottom: 1px solid #ededed;
	}

	.panel-block {
		padding-right: 3rem;
		text-align: inherit;
		white-space: nowrap;
		width: 100%;
		color: #4a4a4a;
		display: block;
		line-height: 1.5;
		padding: 0.375rem 1rem;
		position: relative;
	}
	.divider {
		background-color: #ededed;
		border: none;
		display: block;
		height: 1px;
		margin: 0.5rem 0;
	}

	.text-primary {
		color: #0069b1;
	}
	.text-black {
		color: #040000;
	}
}

.location-textBox > div:first-of-type {
	width: 61% !important;
}
.location-textBox-old-job > div:first-of-type {
	width: 50% !important;
}
