#web-report-modal-footer{
    display: flex;
    margin-top: 16px;
    #save-button{
        margin-left: 16px;
    }

    .exportLoader {
        min-width: 147px;
    }
}