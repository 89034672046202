.schedule-dispatch-worker-list {
  .header {
    background: $az_gray_6;
  }
  .schedule-worker-list {
    height: 71vh;
    padding: 8px;
    overflow-x: auto;
  }
  .team-header{
    position: sticky;
    top:0;
    padding:5px 25px;
    background: $az_gray_6;
  }
  #switch-title-white{
    color:$az_white;
  }
  .bool{
    display:none;
  }
}
