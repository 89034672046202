.qbo-content-page{
    height: 87vh;
    overflow: scroll;
}
.qbo{
    display: flex;
    justify-content: center;
    .content-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 3;
        padding: 0px 25px;
    }
    .connect-to-qbo-container{
        margin: 16px 0px;
    }
    .previous-container{
        padding: 8px 36px;
        margin: 24px 0px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        width: 60%;
        .key-value-container{
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            .key{
                font-size: 14px;
                font-weight: 700;
                width: 45%;
            }
            .value{
                font-size: 14px;
                font-weight: 400;
            }
        }

    }
    .disabled{
        color: $az_gray_4 !important;
        border-color:$az_gray_4 !important;
    }
}